import { Injectable } from '@angular/core';

type CacheItem = {
  due_date: Date;
  value: any;
};

/**
 * In-memory cache. Uses localStorage if available.
 * 
 * _Be careful with the TTL of the keys._
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private readonly DEFAULT_KEY = 'DEFAULT';
  private readonly SERVICE_PREFIX = 'AGREE_CS_';

  /**
   * Map used for caching. `Map` is preferred over a JSON object for performance reasons:
   * - `map.has(key)` is faster than `obj.hasOwnProperty(key)` or `obj[key] !== undefined`.
   * - `Map` allows keys of any type.
   */
  private cache: Map<string | object, CacheItem> = new Map();

  constructor() { }

  /**
   * Retrieves a cached item based on the provided object or the default key.
   * 
   * @param {any} [object] - The object used as a key to retrieve the cached value.
   * @returns {any | null} - The cached value, or `null` if the item is expired or not found.
   */
  public get(object?: any): any | null {
    const key: string | object = object ? object : this.DEFAULT_KEY;

    // Intentamos recuperar del cache en memoria
    const cachedValue: CacheItem = this.cache.get(key);
    if (cachedValue) {
      // Verificamos si el valor aún es válido
      if (cachedValue.due_date > new Date()) {
        return cachedValue.value;
      } else {
        // Eliminamos del cache en memoria si expiró
        this.cache.delete(key);
      }
    }

    // Intentamos recuperar del localStorage
    if (typeof localStorage !== "undefined") {
      try {
        const storageKey = this.storagekey(key);
        const storedData = localStorage.getItem(storageKey);
        if (storedData) {
          const { due_date, value } = JSON.parse(storedData);

          // Verificamos si el dato es válido
          // if (now.getTime() < new Date(due_date).getTime()) {
          if (new Date(due_date) > new Date()) {
            // Si es válido, lo restauramos en el cache en memoria
            this.cache.set(key, { due_date: new Date(due_date), value });
            return value;
          } else {
            // Si expiró, lo eliminamos del localStorage
            localStorage.removeItem(storageKey);
          }
        }
      } catch (e) {
        // console.warn("No se pudo recuperar el cache desde localStorage:", e);
      }
    }

    return null; // Si no se encuentra el valor o ha expirado
  }

  /**
   * Caches a value with an optional expiration time and key.
   * 
   * @param {any} [object] - The object used as a key for caching.
   * @param {any} value - The value to cache.
   * @param {number} [cache_in_minutes=5] - The time in minutes before the cache expires.
   */
  public set(object: any, value: any, cache_in_minutes: number = 5): void {
    const key = object ? object : this.DEFAULT_KEY;
    const due_date = new Date();

    // Calculamos la fecha de vencimiento (TTL)
    due_date.setSeconds(due_date.getSeconds() + (cache_in_minutes * 60));

    // Guardamos en el caché en memoria
    this.cache.set(key, { due_date, value });

    // Guardamos en el localStorage si está disponible
    if (typeof localStorage !== "undefined") {
      try {
        const cacheData = {
          due_date: due_date.toISOString(), // Serializamos la fecha como ISO para su recuperación
          value,
        };
        localStorage.setItem(this.storagekey(key), JSON.stringify(cacheData));
      } catch (e) {
        // console.warn("No se pudo guardar el cache en localStorage:", e);
      }
    }
  }

  private storagekey(key: any): string {
    const stringKey = typeof key == 'string' ? key : JSON.stringify(key);
    return this.SERVICE_PREFIX + stringKey;
  }

  /**
   * Clears all cached items, either in memory or localStorage.
   * 
   * If the optional `contains` parameter is provided, only keys containing that string are removed.
   */
  public clear(contains?: string): void {
    if (contains) {
      const keysToDelete: any[] = [];

      // Iteramos por las claves del mapa
      this.cache.forEach((_, key) => {
        // Solo eliminamos las claves que incluyen contains
        if (JSON.stringify(key).includes(contains)) {
          keysToDelete.push(key);
        }
      });

      // Eliminamos las claves seleccionadas
      keysToDelete.forEach((key) => this.cache.delete(key));
    } else this.cache.clear();

    this.clearStorage(contains);
  }

  private clearStorage(contains?: string): void {
    const prefix = this.SERVICE_PREFIX; // El prefijo específico para las keys del servicio
    if (typeof localStorage !== "undefined") {
      try {
        // Recorremos todas las keys del localStorage
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key && key.startsWith(prefix)) {
            // Si no se especifica `contains`, eliminamos todas las keys con el prefijo
            if (!contains || key.includes(contains)) {
              localStorage.removeItem(key);
            }
          }
        }
      } catch (e) {
        // console.warn("No se pudieron limpiar las keys con el prefijo y filtro:", prefix, contains, e);
      }
    }
  }
}
