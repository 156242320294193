<div id="map"></div>

<ng-container *ngFor="let productGroup of ordersByProduct; let gindex = index">
  <ng-container *ngFor="let order of productGroup.values; let oindex = index">
    <ng-container *ngFor="let marker of order.business_detail.delivery.geolocations">
      <div id="{{ uniqueIds[gindex+'_'+oindex] }}_{{ marker.place_id }}" class="hidden">
        <!-- Popups -->
        <div class="gallery-item" (click)="gotoOrder(order.id)">
          <div class="gallery-card">
            <div class="thumbs">
              <media-browser [order-media]="order.media"></media-browser>
              <!-- <img src="assets/img/sample/prod01.jpg"> -->
              <div class="icons">
                <!-- Scope -->
                <scope-icon [entity]="order"></scope-icon>
                <!-- Auction -->
                <div class="material-symbols-rounded" tooltip="{{ 'GLOBAL.AUCTION'|translate }}" placement="right" *ngIf="order.auction">gavel</div>
              </div>
              <!-- Price -->
              <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                <div class="value">
                  <price-display [price]="order.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                </div>
              </div>
              <!-- Select -->
              <label (click)="$event.stopImmediatePropagation()" class="select-card" *ngIf="company?.market.configuration.order.massive_book?.enabled && order.operation_type === 'venta' && order.company?.id && companyId !== order.company?.id">
                <input [AgCheckbox]="order" type="checkbox" id="order_id_{{ order.id }}_check">
              </label>
            </div>
            <!-- Quantity -->
            <quantity-control *ngIf="!company?.market.configuration.order.has_minimum_order" class="pull-right" [quantity]="order.business_detail.quantity"></quantity-control>
            <div>
              <b>{{ order.product.name }}</b>
            </div>
            <!-- Quality -->
            <p class="small text-muted subtitle">
              <span class="truncate">{{ order.quality_string }}</span>
              <!-- Comments -->
              <i class="material-symbols-rounded" *ngIf="order.general_observations"
              [tooltip]="order.general_observations|stripHtml">comment</i>
            </p>
            <div class="company-data flex-center-distributed">
              <div><!-- Company logo -->
                <company-logo [company]="order.company"></company-logo>
              </div>
              <div class="grow truncate"><!-- Company Name -->
                <span *ngIf="!order.id" class="not-available" title="{{ 'WORKING_ORDERS.UNAUTHORIZED'|translate }}">***</span>
                <span *ngIf="order.id" title="{{ order.companyName }}">
                  <samp class="hidden-xs hidden-sm small">#{{ order.id }}:</samp>
                  <b [class.highlighted-text]="companyId === order.company.id">{{ order.companyName }}</b>
                </span>                        
              </div>
              <div>
                <flag [country]="order.company?.address.country"></flag>
              </div>
            </div>
            <div class="delivery-data flex-center-distributed">
              <!-- No need to display locations names, this is a map -->
              <!-- <div class="small grow"*ngIf="order.business_detail.delivery.locations?.length" title="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}">{{ order.business_detail.delivery.locations |locationString }}</div>
              <div class="small grow truncate"*ngIf="order.business_detail.delivery.geolocations" title="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}">
                <ng-container *ngFor="let location of order.business_detail.delivery.geolocations; let last = last">
                  {{ location.name }}<span *ngIf="!last">, </span>
                </ng-container>
              </div>
              <div class="small grow" *ngIf="company?.market.configuration.order.business_detail.port.enabled" title="{{ 'GLOBAL.COUNTRY_PORT'|translate }}">{{ countryAndPort(order) }}</div> -->
              <div class="grow" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" title="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">
                <b>{{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}</b>
              </div>
              <div title="{{ 'GLOBAL.PERIOD'|translate }}">
                <!-- Delivery period -->
                <date-range
                [showSpot]="true"
                [from]="order.business_detail.delivery.date_from"
                [to]="order.business_detail.delivery.date_to"></date-range>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>