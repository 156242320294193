import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';

import { CountryCode } from '../models/country-code.model';
import { Location } from '../models/location.model';
import { Zone } from '../models/zone.model';
import { CacheService } from './cache.service';

@Injectable()
export class LocationService {

  private locationsUrl = '/:apiBase/markets/:marketId/locations';
  private locationsByIdUrl = '/:apiBase/markets/:marketId/locations/:locationId';
  private zonesUrl = '/:apiBase/zones';
  // private provincesUrl = '/:apiBase/countries/:countryId/provinces';
  // private citiesUrl = '/:apiBase/countries/:countryId/provinces/:provinceId/cities';
  // private partidosUrl = '/:apiBase/countries/:countryId/provinces/:provinceId/partidos';
  private countryCodesUrl = '/:apiBase/country-codes';

  private _zones: Zone[];
  private readonly CACHE_MINUTES = 60 * 24 * 7;

  constructor(
    private cacheService: CacheService,
    private http: HttpClient
  ) { }

  public getCountryCodes(): Observable<CountryCode[]> {
    const cached = this.cacheService.get(this.countryCodesUrl);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<CountryCode[]>(this.countryCodesUrl).pipe(map(response => {
      this.cacheService.set(this.countryCodesUrl, response, this.CACHE_MINUTES * 4);
      return response;
    }));
  }

  public getCountryCode(countryId: number): Observable<CountryCode> {
    const url = this.countryCodesUrl + '/' + countryId;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<CountryCode>(url).pipe(map(response => {
      this.cacheService.set(url, response, this.CACHE_MINUTES * 4);
      return response;
    }));
  }

  /** @deprecated */
  // getProvinces(countryId: number) {
  //   let url = this.provincesUrl.replace(':countryId', countryId.toString());
  //   return this.http.get<Location[]>(url);
  // }

  /** @deprecated */
  // getCities(countryId: number, provinceId: number) {
  //   let url = this.citiesUrl
  //     .replace(':countryId', countryId.toString())
  //     .replace(':provinceId', provinceId.toString());

  //   return this.http.get<Location[]>(url);
  // }

  /** @deprecated */
  // getPartidos(countryId: number, provinceId: number) {
  //   let url = this.partidosUrl
  //     .replace(':countryId', countryId.toString())
  //     .replace(':provinceId', provinceId.toString());

  //   return this.http.get<Location[]>(url);
  // }

  /** Return Market Locations. */
  private getLocations(marketId: number, query: string): Observable<Location[]> {
    const url = this.locationsUrl.replace(':marketId', marketId.toString()) + '?text-search=' + query;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Location[]>(url).pipe(map(response => {
      this.cacheService.set(url, response, this.CACHE_MINUTES);
      return response;
    }));
  }

  private getLocationsByZone(marketId: number, query: string, zones: Zone[]): Observable<Location[]> {
    let url = this.locationsUrl.replace(':marketId', marketId.toString()) + '?text-search=' + query;

    zones.forEach(zone => {
      url += '&filter[zone][]=' + zone.id;
    });

    return this.http.get<Location[]>(url);
  }

  public getLocationsById(marketId: number, locationId: number): Observable<Location[]> {
    let url = this.locationsByIdUrl.replace(':locationId', locationId.toString());

    url = url.replace(':marketId', marketId.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Location[]>(url).pipe(map(response => {
      this.cacheService.set(url, response, this.CACHE_MINUTES);
      return response;
    }));
  }

  /**
    * Retrieves and caches the zones.
    *
    * @returns {Observable<StopStatus[]>} - An observable of the zones.
    */
  public getZones(): Observable<Zone[]> {
    if (this._zones) {
      return of(this._zones);
    }
    const url = this.zonesUrl;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Zone[]>(url).pipe(map(zones => {
      this.cacheService.set(url, zones, this.CACHE_MINUTES);
      this._zones = zones;
      return this._zones;
    }));
  }

  public locationSolver(marketId: number): Function {
    return (query: string) => {
      return this.getLocations(marketId, query);
    }
  }

  /** @see getLocationsByZone */
  public locationSolverByZone(marketId: number, zones: Zone[]): Function {
    return (query: string) => {
      return this.getLocationsByZone(marketId, query, zones);
    }
  }
}
