import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

import { validateCUIT, validateCURP, validateRFC, validateRUC, validateRUT } from '../../models/fiscal-id.model';

/**
 * Validates that the field value is a correct fiscal identifier.
 * 
 * ## Usage
 * ``` html
 * <input type="text" name="fiscalid"
 * validFiscalId="cuit">
 * ```
 * 
 * ### Related UI components:
 * - [[RegisterCompanyComponent]]
 * - [[NetworkComponent]]
 */
@Directive({
  selector: '[validFiscalId]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidFiscalIdDirective, multi: true }]
})
export class ValidFiscalIdDirective implements OnInit, Validator {

  @Input('validFiscalId') private fiscalType: string;

  private validatorFn: ValidatorFn;
  // Mapping fiscal type slugs to their corresponding validator functions.
  private validators: { [key: string]: (id: string) => boolean } = {
    cuit: validateCUIT,
    curp: validateCURP,
    rfc: validateRFC,
    ruc: validateRUC,
    rut: validateRUT
  };

  /** @ignore */
  constructor() { }

  /** @ignore */
  ngOnInit(): void {
    this.validatorFn = this.validator(this.fiscalType);
  }

  /** @ignore */
  validate(control: AbstractControl): {} {
    this.validatorFn = this.validator(this.fiscalType);
    return this.validatorFn(control);
  }

  /**
   * Creates a validator function that validates a fiscal identification number
   * based on the provided fiscal type.
   *
   * @param {string} fiscalType - The type of fiscal identifier to validate (e.g. 'cuit', 'curp', 'rfc', 'ruc').
   * @returns {ValidatorFn} A validator function that returns null if the fiscal ID is valid or an error object if invalid.
   */
  private validator(fiscalType: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fiscalId: string = control.value;

      // If either fiscalType or fiscalId is not provided, skip validation.
      if (!fiscalType || !fiscalId) return null;

      const validateFn = this.validators[fiscalType];

      // If there is no validator for the given fiscal type, do not validate.
      if (!validateFn) return null;

      // Return null if valid; otherwise, return an error object.
      return validateFn(fiscalId) ? null : { validFiscalId: false };
    };
  }
}
