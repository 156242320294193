<form id="bulkInvitationForm" *ngIf="activities || countries; else loading" #bulkInvitationForm="ngForm"
(ngSubmit)="bulkInvitationForm.valid && submit(bulkInvitationForm)">
<table class="input-grid">
  <thead>
    <tr>
      <th>{{ company.market.configuration.company.fiscal_id.label }}</th>
      <th>{{ 'REGISTER_COMPANY.NAME'|translate }}</th>
      <th>{{ 'GLOBAL.MAIN_ACTIVITY'|translate }}</th>
      <th>{{ 'COUNTERPARTIES.NETWORK.MODAL.EMAIL'|translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rowsNames; let i = index;" ngModelGroup="{{ row }}">
      <td>
        <div class="form-group has-button" hasError hasErrorOn="dirty">
          <input type="text" class="form-control input-grid" name="fiscal_id" autocomplete="off" ngModel
            [disabled]="processing" mask="{{ company.market.configuration.company.fiscal_id.mask }}"
            placeholder="{{ company.market.configuration.company.fiscal_id.mask || '' }}"
            [validFiscalId]="company.market.configuration.company.fiscal_id.type"
            (input)="onFiscalIdInputChange(row, bulkInvitationForm.controls[row])">
          <span *ngIf="!processing" (click)="resetFormRow(i, bulkInvitationForm.controls[row])"
            class="glyphicon glyphicon-remove-sign form-control-button" aria-hidden="true"></span>
        </div>
      </td>
      <td>

        <ng-container *ngIf=" companiesCollectionByBulkRowOnBulk &&
                              companiesCollectionByBulkRowOnBulk.hasOwnProperty(row) &&
                              companiesCollectionByBulkRowOnBulk[row].length > 0 else companyInputRow">
          <div class="form-group" hasError>
            <select id="form_ni" class="form-control input-lg" name="name" ngModel
              [required]="bulkInvitationForm.controls[row]?.controls.fiscal_id.valid && bulkInvitationForm.controls[row]?.value.fiscal_id?.length > 0"
              [disabled]="processing || (bulkInvitationForm.controls[row]?.value.fiscal_id?.length === 0 || !bulkInvitationForm.controls[row]?.controls.fiscal_id.valid)"
              (input)="onSelectCompany($event.target.value, row, bulkInvitationForm.controls[row])">
              <option value="" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
              <option *ngFor="let company of companiesCollectionByBulkRowOnBulk[row]"
                value="{{ company.id }}">
                {{ company.name }}
              </option>
            </select>
          </div>
        </ng-container>

        <ng-template #companyInputRow>
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group" hasError>
                <input id="form_ne" type="text" class="form-control input-lg" maxlength="256" name="name"
                  ngModel [disabled]="processing"
                  [required]="bulkInvitationForm.controls[row]?.controls.fiscal_id.valid && bulkInvitationForm.controls[row]?.value.fiscal_id?.length > 0">
                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <error-list></error-list>
              </div>
            </div>
          </div>
        </ng-template>

      </td>

      <td>
        <div class="form-group" hasError>
          <select class="form-control" name="activity" ngModel
            [required]="bulkInvitationForm.controls[row]?.controls.fiscal_id.valid && bulkInvitationForm.controls[row]?.value.fiscal_id?.length > 0"
            [class.readonly]="processing ||
          (bulkInvitationForm.controls[row]?.value.fiscal_id?.length === 0 || !bulkInvitationForm.controls[row]?.controls.fiscal_id.valid) ||
          (new_companies[row]?.id && new_companies[row]?.active)">
            <option value="" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
            <option *ngFor="let act of activities" [ngValue]="act.id">{{ act.name }}</option>
          </select>
        </div>
      </td>
      <td class="text-center">
        <div class="form-group" hasError *ngIf="(bulkInvitationForm.controls[row]?.value.fiscal_id?.length > 0 && bulkInvitationForm.controls[row]?.controls.fiscal_id.valid) &&
        (!new_companies[row]?.id || !new_companies[row]?.active); else none">
          <input type="email" class="form-control" name="email" autocomplete="off" ngModel required
            [disabled]="processing">
        </div>
      </td>
    </tr>
  </tbody>
</table>
<button (click)="addCompanies(10)" type="button" class="hidden-xs btn btn-link btn-xs"
  [disabled]="processing">{{ 'COUNTERPARTIES.NETWORK.MODAL.ADD_MORE'|translate:{quantity:10} }}</button>
<div class="visible-xs">{{ 'COUNTERPARTIES.NETWORK.MODAL.MODE_NOT_AVAILABLE'|translate }}</div>
<!-- BULK INVITE END -->
</form>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
