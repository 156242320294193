<ng-template #modal>
  <!-- MODAL ADD TO MY NETWORK -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'COMMERCIAL_ZONES.MANAGE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <form #taxidForm="ngForm">
          <div class="form-group" hasError hasErrorOn="dirty">
            <a class="pull-right no-select"
            (click)="fiscal_id_mode = !fiscal_id_mode"
            *ngIf="!editingCompany"
            [class.disabled]="processing"><span class="material-symbols-rounded">{{ fiscal_id_mode ? 'search' : 'keyboard_alt' }}</span></a>
            <label class="control-label">
              {{ 'GLOBAL.COMPANY'|translate }}
            </label>
            <ng-container *ngIf="!editingCompany; else selectedCpmpany"
            [ngSwitch]="fiscal_id_mode">
              <ng-container *ngSwitchCase="true">
                <input id="tax_id" type="text" class="form-control input-lg"
                name="tax_id" [(ngModel)]="fiscal_id"
                required
                [disabled]="processing"
                #taxidModel="ngModel"
                [mask]="company.market?.configuration?.company.fiscal_id ? company.market.configuration?.company.fiscal_id.mask : company.address.country?.fiscalIds[0]?.mask"
                [placeholder]="company.market?.configuration?.company.fiscal_id ? (company.market.configuration?.company.fiscal_id.mask ? company.market.configuration?.company.fiscal_id.mask : '') : (company.address.country?.fiscalIds[0]?.mask ? company.address.country?.fiscalIds[0]?.mask : '')"
                [validFiscalId]="company.market?.configuration.company.fiscal_id?.type || company.address.country?.fiscalIds[0]?.type"
                (ngModelChange)="onChangeFiscalId(taxidModel)"
                [modalFocus]="true">    
                <error-list></error-list>
              </ng-container>
  
              <ng-container *ngSwitchDefault>
                <selectize required [maxItems]="1" [searchURL]="companyService.getCompanies(company.id, {
                  all: true
                })" [(ngModel)]="editingCompany" name="applicant"
                (ngModelChange)="setCompany(editingCompany)"
                placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}" [disabled]="processing"></selectize>
                <div class="flex-center-distributed spaced small">
                  <a (click)="fiscal_id_mode = !fiscal_id_mode">{{ 'COMPANY.NOT_LISTED'|translate }}</a>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #selectedCpmpany>
              <div class="editable-pill">
                <button [disabled]="processing" type="button" class="close"
                  (click)="reset()"><span aria-hidden="true">&times;</span></button>
                <company-info [company]="editingCompany"></company-info>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
    </div>

    <div class="row" *ngIf="editingCompany && commercialZones">
      <div class="col-xs-12">
        <div class="panel panel-default no-margin">
          <div class="panel-body"
          AgMultiselection [(ngModel)]="editingCompany.commercial_zones"
          [ngModelOptions]="{standalone: true}">
            <p class="micro text-muted">{{ 'COMMERCIAL_ZONES.BIND_TO'|translate }}</p>

            <ng-container *ngIf="commercialZones?.length > 0">
              <div class="checkbox" *ngFor="let zone of commercialZones">
                <label>
                  <input [AgCheckbox]="zone"
                  [disabled]="processing"
                  (change)="dirty = true"
                  name="chk-{{ zone.id }}"
                  type="checkbox"> {{ zone.name }}
                </label>
              </div>
            </ng-container>

            <div class="text-right">
              <ag-commercial-zones-manager
              #commercialZonesManager
              (submitted)="getCommercialZones(true)"
              [company]="company"></ag-commercial-zones-manager>
              <a [class.disabled]="processing"
              class="small"
              (click)="commercialZonesManager.create()">{{ 'COMMERCIAL_ZONES.CREATE'|translate }}</a>    
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No data -->
    <div class="no-data" *ngIf="!processing && editingCompany === null">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'GLOBAL.NO_DATA'|translate }}
    </div>
  </div>
  <div class="modal-footer" *ngIf="editingCompany && commercialZones">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button (click)="applyChanges()" type="button" class="btn btn-agree" [disabled]="processing || !dirty">
      {{ 'QUALITY_INPUT.ACTION'|translate }}
    </button>
  </div>
</ng-template>