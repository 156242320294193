export default {
  GLOBAL: {
    ACCEPT: 'Aceitar',
    ACTIONS: "Ações",
    ADD_COMPANY: 'Adicionar Empresa',
    ADD: 'Adicionar',
    ADVANCED_OPTIONS: 'Opções avançadas...',
    APP_ERROR_401: '<b>Sua sessão expirou...</b><br>Você precisa se autenticar novamente.',
    APP_ERROR_403: '<b>Você não tem permissão para essa ação...</b><br>Clique aqui para retornar às <b>suas Empresas</b>. Se isso acontecer novamente, entre em contato com nossa equipe de suporte.',
    APP_ERROR_404: '<b>Um erro ocorreu...</b><br>Clique aqui para <b>atualizar a página</b>. Se isso acontecer novamente, entre em contato com nossa equipe de suporte.',
    APP_ERROR_DEFAULT: '<b>Um erro ocorreu...</b><br>Clique aqui para entrar em contato com nossa equipe de suporte.',
    APPROVE: 'Aprovar',
    APPROVED: 'Aprovados',
    ARE_YOU_SURE: 'Você tem certeza?',
    AUCTION: 'Leilão',
    BACK: 'Retornar',
    BARTER: 'Barter',
    BID: 'Compra',
    BROKER: 'Corretor',
    CANCEL: 'Cancelar',
    CANCELED: 'Cancelado',
    CANNOT_UNDONE: 'Esta operação não poderá ser desfeita.',
    CERTIFICATE_DEPOSIT: 'Certificado de Depósito',
    CERTIFICATES: 'Certificados',
    CLEAR_SELECTION: 'Limpar seleção',
    CLEAR: 'Limpar',
    COLLECTION: 'Coleção <span class="currency">[{{length}} elemento(s)]</span>',
    COMMERCIAL_MANAGER_HELP: 'Atribua produtos e defina limites de operações..',
    COMMERCIAL_MANAGER: 'Administração de comerciais',
    COMMISSION: 'Comissão',
    COMPANY_ADMIN: 'Administrar Empresas',
    COMPANY: 'Empresa',
    CONFIG_ASSISTANT: 'Configurar Assistente',
    CONFIRM: 'Confirmar',
    CONTRACT: 'Contrato',
    CONTRACTS: 'Contratos',
    COPIED_CLIPBOARD: 'Copiado para a área de transferência!',
    COPY_CLIPBOARD: 'Copiar para área de transferência',
    COUNTERORDER: 'Contra-ordem',
    COUNTERPART: 'Contraparte',
    COUNTERPARTS: 'Contrapartes',
    COUNTRY_PORT: 'País / Porta',
    CREATE: 'Criar',
    CROP_YEAR_PLACEHOLDER: 'Selecione ano',
    CROP_YEAR: 'Safra',
    CUSTOMER: 'Cliente',
    DATA_ERROR_AVAILABLE: 'Ocorreu um erro ao carregar as informações.<br>As informações podem ainda não estar disponíveis, tente novamente em alguns minutos.',
    DATA_ERROR_PERMISSION: 'Ocorreu um erro ao carregar as informações.<br>Você pode não ter permissão para visualizar estes dados.',
    DATE: 'Data',
    DATE_FORMAT: {
      XS: 'd MMM',
      SM: 'dd/MM/yy',
      RG: 'dd/MM/yyyy',
      MD: 'dd/MMM/yy',
      LG: 'dd/MMM/yyyy'
    },
    DATE_LONG: 'EEEE, d \'de\' MMMM \'de\' yyyy',
    DATE_PLACEHOLDER: 'dd/mm/aaaa',
    DELETE_ALL: 'Deletar tudo',
    DELIVERY_PERIOD: 'Tempo de entrega',
    DELIVERY_PLACES: 'Locais de Entrega',
    DELIVERY_TYPE_ABBR: 'Entrega',
    DELIVERY_TYPE: 'Tipo de entrega',
    DISAPPROVED: 'Desaprovados',
    DISTRIBUTOR: 'Distribuidor',
    DOCUMENTS_LANGUAGE: 'Idioma dos documentos',
    DOWNLOAD: 'Baixar',
    DUE_DATE_ABBR: 'Venc.',
    DUE_DATE: 'Vencimento',
    EDIT_ADDRESS: 'Editar endereço legal de <b>{{company}}</b>',
    EDIT_COMPANY: 'Editar Empresa',
    EDIT: 'Editar',
    ENTER_COMPANY: 'Digite uma Empresa...',
    ERROR_API: '<b>Ops!</b> Algo deu errado. Por favor, tente novamente mais tarde.',
    EXPORT_XLS_SUCCESS: 'A solicitação de exportação foi bem-sucedida, mas a coleta de dados pode levar algum tempo, portanto, você receberá um e-mail com o arquivo Excel para download em breve. Obrigado por sua paciência!',
    EXPORT_XLS: 'Exportar para XLS',
    EXTERNAL_REFERENCE_ABBR: 'Ref. Ext.',
    EXTERNAL_REFERENCE: 'Referência Externa',
    FEES: 'Taxas',
    FILES: 'Arquivos',
    FIXING_CONDITIONS: 'Condições de fixação',
    FIXING_PERIOD: 'Período de fixação',
    FORM: 'Formulário',
    GENERATE_EXPORT: 'Gerar exportação',
    GENERATE_REPORT: 'Gerar relatório',
    GENERATE: 'Gerar',
    HAVE_ACCOUNT_ALREADY: 'Já tem uma conta?',
    IDENTITY_VALIDATION: 'Administração de validações de identidade',
    INTEGRATIONS: 'Integraciones',
    INVOICE_NUMBER: 'Número da fatura',
    ISSUED_BY: 'Emissor',
    ISSUED: 'Emissão',
    KEY_NAME: "Identificador",
    LOADING: 'Carregando...',
    MAIN_ACTIVITY: 'Atividade Principal',
    MARKET: 'Mercado',
    MESSENGER_CENTER: 'Centro de mensagens',
    MIRROR_CONTRACT: 'Espelho',
    MY_COMPANIES: 'Minhas Empresas',
    NAME: 'Nome',
    NEED_HELP: 'Precisa de ajuda?',
    NEGOTIATED_VOLUME: 'Volume negociado',
    NO_DATA: 'Sem registros encontrados.',
    NOT_AVAILABLE_ABBR: 'N/D',
    NOTES: 'Dados da empresa',
    OBSERVATIONS_ABBR: 'Obs.',
    OBSERVATIONS: 'Observações',
    OPERATION_ALERTS: 'Alertas de operações',
    OPTIONAL: 'Opcional',
    ORDER: 'Ordem',
    ORDERS_ADMIN: 'Ordens Trabalhando',
    ORIGIN: 'Origem',
    PAYMENT_CONDITION: 'Condição de pagamento',
    PAYMENT_METHOD: 'Forma de pagamento',
    PENDING: 'Pendentes',
    PERCENTAGE: 'Percentagem',
    PERIOD: 'Período',
    PERMISSION_ADMIN: 'Administração de Permissões',
    PRICE: 'Preço',
    PRIVACY_POLICY: 'Política de Privacidade',
    PROCESSING: 'Processando...',
    PRODUCT: 'Produto',
    QUALITY: 'Qualidade',
    QUANTITY_ABBR: 'Qtd.',
    QUANTITY: 'Quantidade',
    RANGE_PLACEHOLDER: 'dd/mm/aa - dd/mm/aa',
    RECEIVER: 'Destinatário',
    REFRESH_VIEW: 'Este conteúdo mudou, clique aqui para <b>atualizar os dados</b>.',
    REJECT: 'Rejeitar',
    REPRESENTATIVE: 'Representante',
    RETRY: 'Tentar novamente',
    REVIEWING: 'Em revisão',
    SAVE_CHANGES: 'Salvar mudanças',
    SAVE: 'Salvar',
    SELECT_PRODUCT: 'Selecione um produto...',
    SELECT: 'Selecione...',
    SELL: 'Venda',
    SELLER_ADMIN: 'Administração de Contraparte',
    SEND: 'Enviar',
    SHARED_FILES: 'Arquivos compartilhados',
    SOURCE: 'Fonte',
    SPECIFICATIONS: 'Especificações',
    STATE: 'Estado',
    STATUS: 'Estado',
    SUPPLIER: 'Provedor de suprimentos/serviços',
    TABLE: 'Tabela <span class="currency">[{{length}} linha(s)]</span>',
    TEAM: 'Usuários',
    TEMPLATES_ADMIN_NOTIFICATION_ERROR_MSG: 'JSON incompleto ou inválido, favor verificar.',
    TEMPLATES_ADMIN_NOTIFICATION_HEADER: 'Gerenciar os modelos de notificação dentro da plataforma.',
    TEMPLATES_ADMIN_NOTIFICATION_NEW: 'Nova notificação',
    TEMPLATES_ADMIN: 'Administração de modelos',
    TEMPLATES: 'Modelos',
    TERMS_AND_CONDITIONS: 'Termos e Condições',
    TOGGLE_HIDE: '<span class="hidden-sm hidden-xs">Ocultar </span><i class="material-symbols-rounded">expand_less</i>',
    TOGGLE_SHOW: '<span class="hidden-sm hidden-xs">Mostrar </span><i class="material-symbols-rounded">expand_more</i>',
    TOTAL: 'Total',
    TRADE: 'Negócio',
    TYPE: 'Tipo',
    UNDO: 'Desfazer',
    USER_MANUAL: 'Manual do Usuário',
    USER_PROFILE: 'Meu Perfil',
    VERSION: 'Versão',
    VIEW_MORE: 'Ver mais',
    WALKTHROUGH: 'Guia de Boas Vindas',
    WELCOME: '<h2>Food Smart Trade</h2><h3>Bem vindo á plataforma B2B de negociação para comprar e vender alimentos nos mercados globais e doméstico</h3><p>Nós integramos e conectamos as cadeias de suprimento de uma forma fácil, transparente e segura</p>',
    WITHDRAW: 'Retirar',
    YES_ACCEPT: 'Sim, aceitar',
    YES_APPROVE: 'Sim, aprovar',
    YES_CONFIRM: 'Sim confirme',
    YES_DELETE: 'Sim, excluir',
    YES_REJECT: 'Sim, rejeitar',
    YES_REMOVE: 'Sim, remover',
    YES_SEND: 'Sim, enviar',
    YES_WITHDRAW: 'Sim, retirar'
  },
  EMAIL: {
    EMAIL_SEND_ERROR: "Erro ao enviar email",
    EMAIL_SEND_SUCCESS: "Email enviado com sucesso",
    RESEND: "Reenviar email de verificação",
  },
  WALKTHROUGH: {
    GENERAL: {
      INTRO_1: '<h5>Bem vindo a <b>Agrology</b>!</h5><p>Antes de começar, gostaríamos de te mostrar os principais elementos da nossa plataforma.</p><p class="text-right">Vamos começar...</p>',
      INTRO_2: '<h5>Menu do Usuário</h5><p>Contém opções relevantes para sua conta.</p><p>Aqui você pode editar o seu perfil ou selecionar com quais Empresas você vai operar.</p>',
      INTRO_3: '<h5>Menú da Empresa</h5><p>Configure seus usuários, administre suas contrapartes, gere alertas de operações, configure locais de entrega e selecione os modelos prévios para criar uma ordem em segundos.</p>',
      INTRO_4: '<h5>Notificações</h5><p>Cada vez que houver alguma ação pendente, você receberá uma mensagem. Também receberá um email.</p>',
      INTRO_5: '<h5>Barra de navegação principal</h5><p>Te permite acessar as distintas seções do Agrology.</p>',
      INTRO_6: '<h5>Botão de criação</h5><p>Por último, o botão mais importante!</p><p><b>Crie suas Ordens</b> de compra ou venda usando este botão.</p>',
      INTRO_7: '<h5>Isso é tudo!</h5><p>De vez em quando, vamos nos comunicar e deixar você a par de novidades no produto, como novas funcionalidades e melhorias.</p><p>Se tiver alguma dúvida, pode nos escrever no <a href="mailto:contacto@agrology.com">contacto@agrology.com</a></p>'
    },
    MARKET: {
      INTRO_1: '<h5>Níveis de mercado</h5><p>Verifique os preços atuais do mercado, os <span class="buy">lances</span> mais altos, as <span class="sell">ofertas</span> mais baixas e os últimos valores negociados.</p>',
      INTRO_2: '<h5>Filtrar as Ordens</h5><p>Ao selecionar o preço, você poderá ver apenas os lances ou ofertas para essa posição.</p>',
      INTRO_3: '<h5>Filtrar locais de entrega</h5><p>Você pode ver o resumo de locais específicos.</p>',
      INTRO_4: '<h5>Spot e diferido</h5><p>A primeira coluna mostra a posição disponível (spot), os seguintes são os meses que correspondem às posições diferidas.</p>'
    },
    WORKING_ORDERS: {
      INTRO_1: '<h5>Ordens trabalhando</h5><p>Identifique a Ordem que lhe interessa, selecione-a para negociar ou <span class="buy">comprar</span> e <span class="sell">vender</span>.</p>'
    },
    MY_OPERATIONS: {
      INTRO_1: '<h5>Minhas operações</h5><p>Este é o seu painel de controle onde você pode gerenciar seus Ordens: você pode ver suas ações pendentes e o status de cada uma de suas negociações.</p>',
      INTRO_2: '<h5>Documentos del negocio</h5><p>Uma vez encerrada a negociação, você poderá <b>baixar o histórico</b> que inclui todas as ações realizadas, incluindo o responsável e a data/hora.</p><p>E se a Ordem foi negociada, você pode fazer o <b>download da Confirmação ou Contrato do negócio</b> e verificar os termos acordados entre as partes.</p>'
    },
    TRADED_ORDERS: {
      INTRO_1: '<h5>Ordens negociadas no mercado</h5><p>Veja as <b>Ordens negociadas no canal público</b> em tempo real, sem divulgação das contrapartes.</p><p>As encomendas reservadas no canal privado não estão listadas.</p>'
    },
    COMPANY_TRADED_ORDERS: {
      INTRO_1: '<h5>Ordens negociadas da Empresa</h5><p>Aqui, você encontrará todas as Ordens negociadas por sua Empresa, com acesso ao <b>Contrato ou Confirmação do negocio</b> e à <b>Histórico</b>.</p>'
    }
  },
  HEADER: {
    BASIC_ACTIVATION: '<b>Sua empresa ainda não pode operar.</b> Podemos precisar validar algumas coisas. Entre em contato com <a href="mailto:contacto@agrology.com">nossa equipe de suporte</a>.',
    CREATE_AUCTION: 'Criar Leilão',
    CREATE_ORDER: 'Criar Ordem',
    INVALID_PASSWORD_TOKEN: '<b>O link é inválido ou expirou.</b>',
    LOGOUT: 'Sair',
    NOTIFICATIONS: 'Notificações',
    PASSWORD_RESET_SENT: '<b>Instruções de mudança de senha foram enviadas</b> para sua conta de email.',
    PENDING_REQUESTS: 'Solicitações pendentes',
    SELECT_LANGUAGE: 'Idioma',
    SUPER_ADMIN_HEADER: 'Super Administrador',
    VERIFICATION_PENDING: '<b>Verificação Pendente!</b>\
      Um email de verificação foi enviado para seu email de email.\
      Por favor, confirme seu endereço para continuar.',
    VERIFICATION_SUCCESSFUL: '<b>Verificação Concluída!</b>\
      Seu email foi verificado, você agora pode operar.',
    VERIFICATION_FAILED: '<b>Não foi possível verificar seu e-mail!</b> Por favor, entre em contato com <a href="mailto:contacto@agrology.com">nossa equipe de suporte</a>.',
    PASSWORD_UPDATED: '<b>Senha atualizada!</b>\
      Você pode avançar e entrar na plataforma.'
  },
  LOGIN: {
    DONT_HAVE_ACCOUNT: '<span class="hidden-xs">Não tem uma conta?&nbsp;&nbsp;</span><b>Cadastre-se Gratuitamente</b>',
    EMAIL: 'Endereço de Email',
    ERROR: 'O e-mail e a senha que você digitou <b>estão incorretos</b>. <a href="/reset-password">Você esqueceu sua senha?</a>',
    FORGOT_PASSWORD: 'Esqueceu a sua senha?',
    GOOGLE: 'Iniciar sessão com Google',
    HEADING: 'Entre seu <b>email</b> e <b>senha</b>',
    LOGIN: 'Entrar',
    OTHER_OPTIONS: 'Outras opções',
    PASSWORD: 'Senha'
  },
  SIGNUP: {
    CREATE_ACCOUNT: 'Crie uma Conta',
    DISCLAIMER: '<b>Importante!</b> Registrando-se, você aceita as <a href="/links/terms">Termos e Condições</a> e as <a href="/links/privacy">Políticas de privacidade</a>.',
    EMAIL_EXISTS_ERROR: 'O email informado já existe.',
    EMAIL: 'Endereço de Email',
    HAVE_ACCOUNT_ALREADY: '<span class="hidden-xs">Já tem uma conta?&nbsp;&nbsp</span><b>Conecte-se</b>',
    HEADING: 'Cadastre-se <b>gratuitamente</b>',
    HELP_BLOCK: 'Complete sua informação, <b>registrar-se é grátishas no costo</b>.<br><small>Todos os campos são requeridos.</small>',
    INVALID_FORM_ERROR: 'Corrija suas informações e tente novamente',
    LAST_NAME: 'Último nome',
    LAST_NAMES: 'Sobrenomes',
    PASSWORD_CONFIRM: 'Confirme senha',
    PASSWORD_HELP: '<b>Deve conter pelo menos</b> 8 caracteres, 1 letra maiúscula, 1 letra minúscula e 1 número.',
    PASSWORD: 'Senha',
    UNKNOWN_ERROR: 'Um erro ocorreu, tente mais tarde.'
  },
  PASSWORD_RESET: {
    HEADING: 'Mudar senha',
    HELP_BLOCK: 'Entre seu email e em alguns minutos você receberá um link para criar uma nova senha.',
    EMAIL: 'Endereço de Email',
    EMAIL_VERIFICATION_ERROR_0: 'Seu email não ainda não foi verificado!',
    EMAIL_VERIFICATION_ERROR_1: 'Click',
    EMAIL_VERIFICATION_ERROR_2: 'aqui',
    EMAIL_VERIFICATION_ERROR_3: 'para enviar um novo email de verificação.'
  },
  UPDATE_PASSWORD: {
    EXPIRED_TOKEN: 'O token é inválido ou expirou.',
    HELP: 'Digite sua nova senha.',
    LABEL_CONFIRM: 'Digite sua nova senha novamente',
    LABEL_NEW: 'Nova senha',
    PLACEHOLDER_CONFIRM: 'Digite sua nova senha novamente',
    PLACEHOLDER_NEW: 'Nova senha',
    TITLE: 'Atualizar senha',
    UPDATE: 'Atualizar'
  },
  COMPANIES: {
    NO_COMPANY: 'Você ainda não pertence a nenhuma Empresa. Você tem duas maneiras de ingressar em uma:',
    NO_COMPANY_1: 'Se sua Empresa ainda <b class="text-danger">não está registrada</b>, crie-a clicando em <b>Adicionar...</b>',
    NO_COMPANY_2: 'Se sua Empresa <b class="text-success">já está registrada</b>, peça ao administrador para se <b>juntar à equipe</b>',
    ADD_COMPANY: 'Cadastre uma nova Empresa',
    ADD_COMPANY_HELP: 'Se você tiver dúvidas, entre em contato com <a href=\'mailto:contacto@agrology.com\'>nossa equipe de suporte</a>.',
    VERIFY_EMAIL: 'Lembre-se de verificar sua pasta de spam. Se você não recebeu nenhum e-mail, tente reenviá-lo novamente.',
    ACTIVATION_HELP: 'Muito Obrigado por registrar sua Empresa. Nos próximos dias, entraremos em contato com você para informá-lo sobre os passos a seguir.',
    SELECT_HELP: 'Selecione a Empresa para operar.',
    BASIC: 'Pre-Ativação',
    WELCOME: 'Bem-vindo, <b class="text-capitalize">{{name}}</b>!'
  },
  SELECT_MARKET: {
    HEADING: 'Selecione o Mercado onde sua Empresa irá operar.',
    HEADING_TYPE: 'Selecione o tipo de Mercado onde sua Empresa irá operar.'
  },
  REGISTER_COMPANY: {
    AUTHORIZATION: 'Poder dos signatários',
    CITY: 'Cidade',
    COUNTRY: 'País',
    ERROR_MESSAGE: 'Ocorreu um erro, tente novamente mais tarde.',
    FILE_DESCRIPTION: 'Faça o Upload de uma imagem legível ou arquivo PDF.',
    FISCAL_IDENTIFICATION: 'Identificação Fiscal',
    IDENTIFICATION: 'Cópia do {{documento}} do autorizante',
    LEGAL_ADDRESS: 'Endereço Legal',
    LOGO_DESCRIPTION: 'Opcionalmente, esta imagem será usada como logo da Empresa. Faça o Upload de uma imagem quadrada de 140px ou maior.',
    LOGO: 'Logo da Empresa',
    MY_POSITION: 'Meu cargo na Empresa',
    NAME_PLACEHOLDER: 'Nome da Empresa',
    NAME: 'Nome da Empresa',
    NAMES: 'Nomes',
    FIRST_SURNAME: 'Sobrenome paterno',
    SECOND_SURNAME: 'Sobrenome da mãe',
    POSITION_DESCRIPTION: 'O registo da entidade jurídica deve ser apresentado por um representante legal ou por qualquer outra pessoa com capacidade jurídica.',
    POSITION: 'Cargo',
    POSTAL_CODE: 'C.E.P.',
    STATE: 'State / Province',
    STATUTE: 'Contrato Social da Empresa',
    STREET: 'Rua e número, escritório',
    TAX_CERTIFICATE_HELP: 'Carregar PDF emitido pelo SAT.',
    TAX_CERTIFICATE: 'Comprovante de Situação Fiscal',
    TELEPHONE: 'Telefone'
  },
  FILE_EXTRACT: {
    AND_MORE: '...e mais.',
    DESCRIPTION: 'Extraia dados de seus documentos com inteligência artificial.',
    DISCLAIMER: 'É importante validar os dados obtidos. O modelo pode identificar mais de um valor para cada campo.',
    EXTRACT: 'Extrair dados',
    EXTRACTING: 'Extraindo...',
    FIELDS: 'Campos para extrair:',
    SUGGESTIONS: 'Sugestões:'
  },
  FILE_INPUT: {
    AVAILABLE: 'Arquivos disponíveis',
    DROP_HERE: 'Arraste e solte arquivos aqui...',
    GENERATING: 'Gerando arquivos...',
    MULIPLE_SELECTED: 'Arquivos selecionados',
    OPEN_FILE: 'Abrir arquivo...',
    PREVIEW: 'Visualizar...',
    REJECTED: 'Los siguientes archivos no tienen el formato o peso admitidos: <b>{{files}}</b>.',
    SELECT_FILE: 'Selecionar arquivo',
    SELECT_FILES: 'Selecionar arquivo(s)',
    SELECTED: 'Arquivo selecionado',
    UPLOAD_FILE: 'Enviar um arquivo...',
    UPLOAD_FILES: 'Enviar arquivos...',
    UPLOADING: 'Enviando...',
    DELETE_CONFIRM: {
      TITLE: 'Deletar',
      MODAL_TITLE: 'Tem certeza de que quer deletar este arquivo?'
    }
  },
  COMPANY: {
    BARTERS: 'Barters',
    COMPANY_TRADED_ORDERS: 'Ordens Negociadas da Empresa',
    EXECUTION: 'Execução',
    FINTECH: 'Financiamento',
    IMPORTED_DATA: 'Dados importados',
    MARKET_PRICES: 'Preços de mercado',
    MARKET_SUMMARY: 'Níveis de Mercado',
    MY_OPERATIONS: 'Minhas Operações',
    NOT_LISTED: 'Não consegue encontrar a Empresa?',
    PREORDERS: 'Pré-ordens',
    SIGNATURES: 'Assinaturas',
    SLOTS: 'Slots',
    STATISTICS: 'Estatísticas',
    TRADED_ORDERS: 'Ordens Negociadas no Mercado',
    WORKING_ORDERS: 'Ordens Trabalhando'
  },
  COMPANY_SETUP: {
    CERTIFICATES_HELP: 'Gerenciar os Certificados de gerenciamento e produto da sua Empresa.',
    COMPANY_LOCATIONS_HELP: 'Defina os locais de entrega de sua Empresa.',
    HEADING: 'Configurar Assistente',
    INTEGRATIONS_HELP: 'Conecte-se via API.',
    NOTES_HELP: 'Gerenciar informações de outras empresas',
    SELECT_HELP: 'Selecione alguma das opções abaixo.',
    SELLER_ADMIN_HELP: 'Selecione as contrapartes com as quais você deseja negociar.', // Aprove com quais Empresas você deseja negociar.'
    TEAM_HELP: 'Crie Usuários(as) para sua Empresa e atribua a eles(as) papéis diferentes.',
    WELCOME_1: '<b>Bem Vindo!</b> Antes de começar é necessário configurar alguns parâmetros de sua Empresa.',
    WELCOME_2: 'Você será sempre capaz de voltar e modificar estas opções. é importante manter estas informações sempre atualizadas.'
  },
  ORDER: {
    CREATE_CERTIFICATE: 'Criar Certificado',
    DEPOSIT_LOCATION: 'Local de depósito',
    NETWORK_ONLY: 'Somente para a Rede',
    NEW_CERTIFICATE: 'Novo Certificado de Depósito',
    NO_RECORDS: 'Ordens ainda não registradas.', // 'O sistema ainda não registrou nenhum movimento.',
    OWN_PRODUCED: 'É produção própria (primária)',
    PRIVATE: 'Ordem Privativa',
    PRODUCTION: 'Produção'
  },
  AUCTION_FORM: {
    HEADING: 'Novo Leilão',
    AUTHORIZED_BUYERS: {
      HELP: 'Os compradores que você selecionar são os únicos que poderão se inscrever neste Leilão.'
    },
    TYPE: {
      HELP_0: 'De acordo com sua Atividade, você pode apenas realizar',
      HELP_1: ' Leilão.',
      BUY_HELP: '<b>Leilão Reverso</b>, vendedores competem fazendo ofertas com preço cada vez menor.'
    },
    SCOPE: {
      MY_NETWORK_HELP: 'Os Leilões publicados em <b>Minha Rede</b> são visíveis apenas para Empresas que integram <a href="/company/{{companyId}}/network?order_by=-verification_date">a Rede de sua Empresa</a>.',
      OPEN_HELP: 'Os Leilões <b>públicos</b> são visíveis para todos, mas apenas contrapartes autorizadas serão capazes de participar.<br> Uma vez que a transação seja concretizada, ela vai aparecer na seção <b>Ordens Negociadas no Mercado</b>, sem que as contrapartes sejam publicadas.',
      PRIVATE_HELP: 'Os Leilões <b>privados</b> são invisíveis para o mercado, sendo visíveis apenas para as contrapartes convidadas por você.'
    },
    DATE: {
      DATE: 'Data do Leilão',
      DURATION: 'Duração',
      DURATION_HELP: 'Uma vez iniciado e havendo o primeiro lance sido realizado, <b>o Leilão não pode ser cancelado até que seu tempo se esgote</b>. Os Leilões devem ser encerrados antes do encerramento do dia comercial.',
      DURATION_PLACEHOLDER: 'Selecione uma duração',
      EXTEND: 'Prolongue a duração em pelo menos um minuto desde a última oferta.',
      EXTEND_HELP: 'Esta extensão é <b>repetida até que nenhuma oferta de último minuto seja registrada</b>.',
      MINUTES: 'minutos',
      TIME: 'Hora do Início',
      TIME_HELP: 'Hora do mercado interno {{market}}. Os interessados podem se registrar até o horário especificado.'
    },
    PRICE: {
      MAX: 'Preço Teto',
      MIN: 'Preço Piso'
    },
    STEP: {
      LABEL_BUY: 'Decréscimo de Lances',
      LABEL_SELL: 'Incremento de Lances',
      BUY_HELP: 'Mínimo valor de diminuição entre lances.',
      SELL_HELP: 'Mínimo valor de aumento entre lances.'
    }
  },
  ORDER_FORM: {
    EDITING: 'Editando',
    HEADING: 'Nova Ordem',
    NO_COMPANY_LOCATIONS: 'Sua empresa <b>não tem locais de entrega definidos</b>. <a class="alert-link" href="/company/{{companyId}}/locations">Clique aqui</a> para adicionar um lugar.',
    PREVIEW: 'Visualizar',
    PUBLISHING: 'Publicando...',
    COMPANY_REPRESENTED_BY: "Empresas representadas pelo corretor",
    TYPE: {
      BOOK_HELP_1: 'Qualquer uma das contrapartes autorizadas definidos por você será apto para realizar a transação diretamente.',
      BOOK_HELP_2: 'Qualquer Empresa será apto para realizar a transação diretamente.',
      BOOK_HELP: 'Ordens de <span class="{{class}}">{{type}}</span> <b>são firmes</b>.',
      HELP_0: 'De acordo com sua atividade, você pode apenas realizar Ordens de',
      HELP_1: '.',
      PREBOOK_HELP: 'Em Ordens de <span class="{{class}}">{{type}}</span>, contrapartes podem apenas <b>requisitar um pré-fechamento</b>, sujeito a sua aceitação para confirmação definitiva do fechamento da transação.'
    },
    DELIVERY_TYPE: {
      PLACEHOLDER: 'Selecione um tipo de entrega'
    },
    BROKER: {
      HELP: 'O Corretor receberá uma <b>Pré-ordem</b> and poderá publicá-la, fundi-la com outras pré-ordens ou rejeitá-la.',
      LABEL: 'Há um Corretor envolvido?',
      PLACEHOLDER: 'Não'
    },
    SCOPE: {
      LABEL: 'Que canal você deseja usar?',
      MY_NETWORK_HELP: 'As Ordens publicadas em <b>Minha Rede</b> são visíveis apenas para Empresas que integram <a href="/company/{{companyId}}/network?order_by=-verification_date">a Rede de sua Empresa</a>.',
      MY_NETWORK: 'Minha Rede',
      NO_OPEN_HELP: 'Sua empresa <b>ainda não pode operar no mercado Público</b>. Podemos precisar validar algumas coisas. Se você está interessado, entre em contato conosco pelo <a href="mailto:contacto@agrology.com">contacto@agrology.com</a>.',
      OFFLINE_HELP: 'Uma <b>transação off-line</b> é declarativa, reflete um negócio que foi confirmado fora da plataforma e <b>não exigirá confirmação</b> da contraparte.',
      OFFLINE: 'Off-line',
      OPEN_HELP: 'Ordens no <b>canal Público</b> são visíveis para todos, mas apenas contrapartes autorizadas poderão ver o nome de sua Empresa e interagir com ela.<br>Um vez negociadas, as Ordens aparecerão na seção <b>Negócios Feitos</b>, sem que as contrapartes sejam publicadas.',
      OPEN: 'Publico',
      PRIVATE_HELP: 'Ordens no <b>canal Privativo</b> sãi invisíveis para o mercado, sendo apenas visíveis para as contrapartes escolhidas por você.',
      PRIVATE: 'Privativo'
    },
    PRODUCT: {
      NOT_LISTED: 'Não encontrou seu produto?',
      // NOT_SUSTAINABLE: 'Não sustentável',
      SUSTAINABLE: 'Sustentável'
    },
    IMAGES: {
      LABEL: 'Imagens',
      HELP: 'Selecione até 20 imagens.'
    },
    PRICE: {
      MAX_HELP: '<b>Maximo</b> valor que você deseja pagar.',
      MAX: 'Preço Máximo',
      MIN_HELP: 'Preço Base. <b>Minimo</b> valor que você está disposto a aceitar.',
      MIN: 'Preço Mínimo'
    },
    QUANTITY: {
      FIXED_HELP: 'Para <b>Fixa</b> Quantidade, a Ordem será negociada com o primeiro lance vencedor.',
      HELP_0: '',
      HELP_1: ' Ordens pode ser <b>Fixas</b> ou <b>Máxima</b> quantidade.<br>',
      MAX_HELP: 'Para <b>Máximum</b> Quantidade, múltiplos vendedores podem oferecer lances com diferentes quantidades para a mesma Ordem.'
    },
    PERIOD: {
      EXPIRATION_HELP_0: 'Se a Ordem ainda estiver ativa após',
      EXPIRATION_HELP_1: 'a plataforma transferirá automaticamente o período, respeitando sua duração.'
    },
    DELIVERY_LOCATION: {
      LABEL: 'Local(is) de entrega',
      LOADING: 'Procurando locais...',
      TITLE: 'Adicionar local de entrega'
    },
    AUTHORIZED_BUYERS: {
      ERROR_EMPTY_LOCATION: '<b>Não há compradores registrados nos locais selecionados!</b> Para poder publicar a Ordem, pelo menos um comprador autorizado é necessário.',
      ERROR_EMPTY_SELECTION: 'Selecione pelo menos uma Empresa como comprador autorizado.',
      HELP: 'Os compradores selecionados são os únicos que podem ver todos os detalhes de sua Ordem, negociá-la, ou enviar uma contra-oferta.',
      LABEL: 'Compradores autorizados',
      LOADING: 'Procurando compradores...'
    },
    LOADING_RATE: {
      LABEL: 'Taxa de carregamento',
      PLACEHOLDER: 'Taxa de carregamento'
    },
    ALTERNATIVE_LOADING_RATE: {
      LABEL: 'Taxa de carregamento alternativa'
    },
    PORT: {
      LABEL: 'Porta',
      PLACEHOLDER: 'Selecione uma porta'
    },
    BERTH: {
      LABEL: 'Ancoradouro',
      PLACEHOLDER: 'Selecione um berço'
    },
    ALTERNATIVE_BERTH: {
      LABEL: 'Ancoradouro alternativo',
      PLACEHOLDER: 'Ancoradouro alternativo'
    },
    ALTERNATIVE_CONDITION: {
      LABEL: 'Condição Alternativa'
    },
    ARBITRATION_CHAMBER: {
      LABEL: 'Câmara de Arbitragem',
      PLACEHOLDER: 'Selecione uma Câmara'
    },
    EXPIRATION_DATE: {
      LABEL: 'Data de validade',
      HELP: 'Data em que a publicação será automaticamente retirada pelo sistema.'
    },
    CONTRACT: {
      HELP: 'O Contrato a seguir foi gerado com base nos dados informados. Você pode <b>editar</b>, <b>deletar</b> ou <b>adicionar</b> novas cláusulas.',
      LANGUAGE_HELP: 'Você também pode selecionar o <b>idioma</b> em que a documentação relacionada será gerada..'
    },
    OBSERVATIONS: {
      HELP: 'Use este campo para informações adicionais como slots, comissões, taxas de câmbioou outras.'
    },
    CANCEL_CONFIRM: {
      BODY: 'Ao cabcelar, a Ordem estará disponivel para operar outra vez.',
      CONFIRM_BUTTON: 'Sim, descartar mudanças.',
      CONTINUE_BUTTON: 'Continue a editar',
      TITLE: 'Deseja cancelar e descartar as mudanças?'
    }
  },
  PRIVATE_COMPANIES_SELECTOR: {
    HELP_BUY: 'Pesquise o <b>Comprador</b> com quem você deseja operar. Lembre-se de que você pode adicionar quantas empresas desejar.',
    HELP_SELL: 'Pesquise o <b>Vendedor</b> com quem você deseja operar. Lembre-se de que você pode adicionar quantas empresas desejar.'
  },
  ORDER_PREVIEW: {
    HELP: '<b>Pré-visualização.</b> Defina quais variáveis você deseja definir como "não-negociável".',
    WARNING: 'Esta Ordem será retirada automaticamente no final do dia (23:59 horas GMT {{offset}}).',
    EXPIRATION: 'Esta Ordem será retirada automaticamente em: {{day}}, {{date}} de {{month}} de {{year}} (GMT {{offset}}).',
    BACK: {
      LABEL_0: 'Voltar',
      LABEL_1: 'ao modo de edição',
      TITLE: 'Voltar ao modo de edição'
    },
    PUBLISH: {
      LABEL: 'Publicar',
    },
    EDIT_CONFIRM: {
      TITLE: 'Você tem certea de que quer salvar as mudanças?',
      BODY: 'Se existentes, todas as Contra-Ordens em aberto desta Ordem serão automaticamente canceladas..',
      CONFIRM_BUTTON: 'Sim, salvar'
    }
  },
  NEGOTIABLE_GROUP: {
    NOT_NEGOTIABLE: 'Não negociável',
    DISCARD: 'Descartar mudanças',
    PREVIOUS_VALUE: 'Valor anterior: ',
    CURRENT_VALUE: 'Valor atual: '
  },
  PROPOSAL: {
    ADJUSTED_FIELDS: {
      DELIVERYDATE: '<b>Atenção!</b> A data de início estava no passado e, portanto, foi movida para hoje.'
    },
    PRICE: {
      PLACEHOLDER: '0,00',
      LABEL_MIN: 'Preço Mínimo',
      LABEL_MAX: 'Preço Máximo',
      OFFER_MIN: 'Lance mínimo',
      OFFER_MAX: 'Lance máximo'
    },
    DELIVERY_LOCATIONS: {
      BUYER_OPTION: 'Opção do Comprador'
    },
    QUALITY: {
      PLACEHOLDER: 'Não especificada'
    },
    ARBITRATION_CHAMBER: {
      LABEL: 'Câmara de Arbitragem',
      PLACEHOLDER: 'Selecione uma Câmara'
    },
    CONTRACT: {
      DEVIATIONS: 'Existem diferenças em relação ao contrato padrão',
      CLAUSE: {
        PLACEHOLDER: 'Escreva a cláusula...',
        ADD: 'Adicionar cláusula',
        ADD_ABBR: 'Adicionar',
        TITLE: 'Título',
        EDIT: 'Editar cláusula',
        EMPTY: 'A cláusula não pode estar vazia!',
        DELETE: {
          LABEL: 'Deleter cláusula',
          TITLE: 'Tem certeza de que quer deletar esta cláusula?'
        }
      },
      TO_BE_COMPLETED: 'A ser completado pela plataforma'
    },
    LOADING_RATE: {
      LABEL: 'Taxa de carregamento',
      PLACEHOLDER: 'Taxa de carregamento'
    },
    PORT: {
      LABEL: 'Porta',
      PLACEHOLDER: 'Selecione uma porta'
    },
    BERTH: {
      LABEL: 'Ancoradouro',
      PLACEHOLDER: 'Selecione um berço'
    }
  },
  ORDER_SUMMARY: {
    BROKER_BUYER: 'Corretor do Comprador',
    BROKER_SELLER: 'Corretor do Vendedor',
    BUYER: 'Comprador',
    DISCLOSE: 'Editar contrapartes...',
    DISCLOSE_CONFIRM: 'Divulgar',
    DISCLOSE_HELP: 'Mediante a divulgação das Empresas representadas, a contraparte será notificada e novas versões dos documentos associados serão geradas para contemplar essas informações.',
    DISCLOSURE_BUYERS: 'Divulgação de Compradores',
    DISCLOSURE_SELLERS: 'Divulgação de Vendedores',
    NO_DISCLOSURE: 'Não divulgado',
    PAUSED: 'Pausado',
    SELLER: 'Vendedor'
  },
  AUCTION_VIEW: {
    PREAUCTION: 'Pré-Leilão',
    EDIT_CONFIRM: {
      TITLE: 'Tem certeza de que quer editar este Leilão?',
      BODY_AUCTION: 'Enquanto estiver editando, o Leilão ficará pausado e fora da visão das outras Empresas.',
      BODY_PREAUCTION: 'Enquanto estiver editando, o Leilão ficará pausado e o corretor não poderá processá-lo.',
      CONFIRM_BUTTON: 'Sim, editar'
    },
    CANCEL_CONFIRM: {
      TITLE: 'Cancelar Leilão',
      TITLE_AUCTION: 'Tem certeza de que quer cancelar este Leilão?',
      TITLE_PREAUCTION: 'Tem certeza de que quer remover este Pré-leilão?',
      BODY_AUCTION: 'Todas as Empresas participantes serão notificadas.',
      BODY_PREAUCTION: ''
    },
    MODULE: {
      BID: 'Dar Lance de Compra a',
      BIDS: 'Lance(s) de compra',
      BIDS_HISTORY: 'Histórico de Lances de Compra',
      CLOSING: 'Finalizando...',
      COMPANY_ENROLLED: 'Sua Empresa foi inscrita neste Leilão.',
      COMPANY_ENROLLED_HELP: 'Você será notificado quando o Leilão começar.',
      CURRENT_BID: 'Lance de Compra Atual',
      CURRENT_OFFER: 'Lance de Venda Atual',
      ENDED: 'Leilão terminado.',
      ENROLLED: 'Empresas Inscritas:',
      ENROLL_BUTTON: 'Inscrever-se no Leilão',
      ENROLL_HELP: 'Você pode se inscrever até antes do início do Leilão. A partir deste momento, este Leilão será visível apenas aos participantes.',
      EXTENDED: 'A duração foi prorrogada em {{seconds}} segundos devido a uma oferta de última hora.',
      LAST_BID: 'Último lance de Compra',
      LAST_OFFER: 'Último lance de venda',
      LIVE: 'Leilão está Ao Vivo!',
      MAXIMUM_BID: 'Você também pode entrar com seu lance de compra máximo, que será seu lance máximo:',
      MAXIMUM_BID_SET: 'Você definiu seu lance máximo em:',
      MINIMUM_OFFER: 'Você também pode entrar com seu lance de venda mínimo, que será seu lance limite:',
      MINIMUM_OFFER_SET: 'Você definiu sua oferta mínima em:',
      NONE_ENROLLED: 'Ainda não há empresas inscritas.',
      OFFER: 'Dar Lance de Venda a',
      OFFERS: 'Lance(s) de venda',
      OFFERS_HISTORY: 'Histórico de Lances de Venda',
      PLACE_BID: 'Definir lance máximo',
      PLACE_BID_HELP_0: 'A plataforma irá automaticamente aumentar seu lance de compra em',
      PLACE_BID_HELP_1: 'contra outros lances até que o máximo seja alcançado.',
      PLACE_OFFER: 'Definir lance limite',
      PLACE_OFFER_HELP_0: 'A plataforma irá automaticamente diminuir seu lance de venda em',
      PLACE_OFFER_HELP_1: 'contra outros lances até que o mínimo seja alcançado.',
      STARTING: 'Iniciando...',
      STEP_HELP_0: 'Este Leilão aceita diferença entre lances de',
      STEP_HELP_1: '.',
      TIME_LEFT: 'Tempo restante:',
      TIME_TO_START: 'Tempo para iniciar:',
      WINNING_BID: 'Seu lance é o melhor por enquanto!',
      WINNING_BID_HELP: 'Você será notificado se um lance mais competitivo for dado.',
      WINNING_OFFER: 'Seu lance é o melhor por enquanto!',
      WINNING_OFFER_HELP: 'Você será notificado se um lance mais competitivo for dado.',
      WITHDRAWN: 'Leilão retirado',
      WON_AUCTION: 'Você ganhou este Leilão!',
      ENROLLED_MODAL: {
        TIME: 'Data e hora da inscrição',
        INVITATION_SENT: 'Convite enviado'
      },
      HISTORY: {
        CURRENT: 'Atual',
        WINNER: 'Vencedor',
        TIME: 'Data e hora',
        OFFER: 'Lance de Venda',
        BID: 'Lance de Compra',
        YOUR_OFFER: 'Seu Lance de Venda',
        YOUR_BID: 'Seu lance de Compra',
        OFFER_OMITTED: 'Omitida. Houve uma oferta anterior definida automaticamente.',
        BID_OMITTED: 'Omitido. Houve um lance anterior definido automaticamente.'
      }
    },
    EDIT: 'Editar Leilão',
    REPUBLISH: 'Re-publicar Leilão'
  },
  ORDER_VIEW: {
    PREORDER: 'Pré-Ordem',
    RELATED_PRODUCTS: 'Produtos relacionados',
    EXPIRATION: 'Esta Ordem será retirada em: {{day}}, {{date}} de {{month}} de {{year}} (GMT {{offset}}).',
    BOOKED_ALERT: {
      TEXT_0: 'Importante!',
      TEXT_1: '',
      TEXT_2: 'das',
      TEXT_3: 'definidas para esta Ordem já foram aceitadas.',
      WITHDRAW: 'Você quer retirá-la?'
    },
    EDIT_CONFIRM: {
      TITLE: 'Você tem certeza de que quer editar esta Ordem?',
      BODY_ORDER: 'Enquanto estiver sendo editado, a Ordem permanecerá pausada e fora da visão de outras Empresas.',
      BODY_PREORDER: 'Enquanto estiver sendo editado, a Ordem permanecerá pausada e o Corretor não poderá processá-la.',
      CONFIRM_BUTTON: 'Sim, editar'
    },
    CANCEL_CONFIRM: {
      TITLE: 'Cancelar Ordem',
      TITLE_ORDER: 'Tem certeza de que quer cancelar esta Ordem?',
      TITLE_PREORDER: 'Tem certeza de quer remover esta Pré-ordem?',
      BODY_ORDER: 'Se existentes, todas as Contra-Ordens associadas a esta Ordem serão canceladas.',
      BODY_PREORDER: ''
    },
    REJECT_CONFIRM: {
      TITLE: 'Rejeitar Ordem',
      TITLE_ORDER: 'Tem certeza de que quer rejeitar esta Ordem?',
      TITLE_PREORDER: 'Tem certeza de que quer rejeitar esta Pré-ordem?'
    },
    STATUS: {
      REVIEW_PENDING_BROKER_ORDER: '<b>Pendente de Revisão.</b> O Corretor ainda não processou esta Ordem.',
      REVIEW_PENDING_BROKER_PREORDER: '<b>Pendente de Revisão.</b> O Corretor ainda não processou esta Pré-Ordem.',
      REVIEW_PENDING_ORDER: '<b>Pendente de Revisão.</b> Você ainda não processou esta Ordem.',
      REVIEW_PENDING_PREORDER: '<b>Pendente de Revisão.</b> Você ainda não processou esta Pré-Ordem.',
      REJECTED_ORDER: '<b>Rejeitada.</b> O Corretor decidiu não publicar esta Ordem.',
      REJECTED_PREORDER: '<b>Rejeitada.</b> O Corretor decidiu não publicar esta Pré-Ordem.',
      CANCELED_ORDER: '<b>Cancelada.</b> Esta Ordem foi cancelada.',
      CANCELED_PREORDER: '<b>Cancelada.</b> Esta Pré-Ordem foi cancelada.',
      PUBLISHED_ORDER: '<b>Publicada.</b> O Corretor decidiu publicar esta Ordem.',
      PUBLISHED_PREORDER: '<b>Publicada.</b> O Corretor postou esta Pré-Ordem.',
      EXPIRED_ORDER: '<b>Cancelada.</b> Esta Ordem foi cancelada automaticamente pela plataforma, no fim do dia.',
      EXPIRED_PREORDER: '<b>Cancelada.</b> Esta Pré-Ordem foi cancelada automaticamente pela plataforma, no fim do dia.',
    },
    PREBOOK_MODAL: {
      TITLE: 'Faça uma Ordem ',
      BODY: 'Insira a quantidade em ton a ser oferecida.',
      CONFIRM_BUTTON: 'Pré-aceitar'
    },
    UNAVAILABLE_MODAL: {
      TITLE: 'Ordem não disponível',
      BODY: 'Esta Ordem não está mais disponível para operação'
    },
    EDIT: 'Editar a Ordem',
    REPUBLISH: 'Re-publicar a Ordem',
    BOOK: 'Aceitar',
    PREBOOK: 'Pré-aceitar',
    REVIEW: 'Revisar...',
    NEGOTIATION_REQUEST: 'Pedido de negociação'
  },
  COUNTERORDER_VIEW: {
    BOOK: 'Aceitar',
    BOOKING_DATE_FORMAT: 'EEEE, d \'de\' MMMM \'de\' yyyy \'às\' HH:mm',
    NEW: 'Nova Contra-ordem',
    LINKED: 'Negociações vinculadas',
    AMENDMENT: {
      ALERT: '<b>Importante!</b> As condições do acordo firmado em: <b>{{date}}</b> estão sendo revisadas. As condições originais permanecerão em vigor até que as emendas sejam aceitas, momento em que novas versões da documentação relacionada serão geradas.',
      SEND: 'Propor alterações',
      ACCEPT: {
        TITLE: 'Aceitar as alterações',
        BODY: 'Todas as emendas sugeridas estão para ser aceitas. Um novo documento será gerado refletindo as novas condições. Tem certeza de que aceita todas as alterações?',
        BUTTON: 'Aceitar as alterações'
      },
      REJECT: {
        TITLE: 'Rejeitar todas as alterações',
        BODY: 'Todas as emendas sugeridas estão prestes a ser ignoradas, as condições originalmente negociadas serão restauradas. Tem certeza de que rejeita todas as alterações?'
      }
    },
    CANCELLATION: {
      ALERT: '<b>Importante!</b> O cancelamento do acordo firmado em: <b>{{date}}</b> está sendo avaliado. As condições originais permanecerão em vigor até até que o cancelamento seja confirmado.',
      ACCEPT: {
        TITLE: 'Cancelar Negócio',
        BODY: 'Você está prestes a aceitar a proposta de cancelamento deste Negócio. Tem certeza?<br><br>Esta operação não poderá ser desfeita.',
        BUTTON: 'Aceitar e cancelar'
      }
    },
    NEGOTIATION_REQUEST_CONFIRM: {
      TITLE: 'Você tem certeza de aceitar o pedido de negociação?',
      BODY: 'A Contra-ordem será firme, a contraparte poderá aceitar diretamente.'
    },
    NEGOTIATION_REQUEST_REJECT: {
      TITLE: 'Tem certeza de rejeitar a solicitação de negociação?'
    },
    STATUS: {
      PAUSED: '<b>Ordem pausada.</b> Não é possível utilizar essa Contra-ordem neste momento.',
      SENT: '<b>Contra-ordem enviada.</b> Esperando a contraparte responder.',
      RECEIVED: '<b>Contra-ordem recebida.</b> Você tem que responder.',
      CANCELED: '<b>Contra-ordem cancelada.</b>',
      REJECTED: '<b>Contra-ordem rejeitada.</b>',
      BOOKED: '<b>Aceitado.</b>',
      AMENDMENT: {
        IN_PROGRESS: '<b>Alterações estão sendo negociadas.</b> Esperando as contrapartes.',
        SENT: '<b>Você sugeriu alterações ao acordo original</b>. Esperando contraparte responder.',
        RECEIVED: 'A contraparte propõe <b>emendas ao acordo original</b>. Você tem que responder.'
      },
      CANCELLATION: {
        IN_PROGRESS: '<b>Alterações estão sendo negociadas.</b> Esperando as contrapartes.',
        SENT: '<b>Você sugeriu alterações ao acordo original</b>. Esperando contraparte responder.',
        RECEIVED: 'A contraparte propõe <b>emendas ao acordo original</b>. Você tem que responder.'
      },
      PREBOOK: {
        RECEIVED: '<b>Pré-aceite recebido.</b> Você tem que responder.',
        REQUESTED: '<b>Pré-aceite enviado.</b> Esperando contraparte responder.',
        REJECTED: '<b>Pré-aceite rejeitado.</b>'
      },
      DISCLOSURE: {
        SENT: '<b>Pedido de abertura ao mercado enviado.</b> Esperando resposta da contraparte.',
        RECEIVED: '<b>Pedido de abertura ao mercado recebido.</b> Você tem que responder.',
        ANSWERED: '<b>Pedido de abertura ao mercado respondido.</b> Esperando resposta da contraparte.'
      },
      NEGOTIATION_REQUEST: {
        SENT: '<b>Pedido de negociação.</b>',
        RECEIVED: '<b>Pedido de negociação recebido.</b> Você deve responder.',
        ACCEPTED: '<b>Pedido de negociação aceitado.</b>',
        REJECTED: '<b>Pedido de negociação refeitado.</b>'
      }
    },
    UNAVAILABLE_MODAL: {
      TITLE: 'Contra-ordem não disponível',
      BODY: 'Esta Contra-ordem não está mais disponível para operação.'
    }
  },
  MAX_QUANTITY: {
    DESCRIPTION: 'Aceitar esta negociação implicaria exceder a quantidade máxima definida.',
    MAX_QUANTITY: 'Quantidade máxima',
    BOOKED: 'Fechado',
    THIS_NEGOTIATION: 'Negociação atual',
    TOTAL: 'Total',
    CONTINUE: 'Sim, continuar',
    TO_BE_OFFERED: 'Quantidade Livre'
  },
  MY_OPERATIONS_VIEW: {
    NO_RECORDS: 'A Empresa ainda não registrou movimentos.',
    SUMMARIZE: 'Um total de <span class="quantity">{{quantityOrder}}</span> Ordem(s)',
    TOOLTIPS: {
      REPUBLISH: 'Clonar Ordem',
      REPUBLISH_AUCTION: 'Clonar Leilão'
    },
    BULK_REPUBLISH: {
      TITLE: 'Clonagem massiva',
      MODAL_TITLE: 'Você deseja clonar as Ordens?',
      ACCEPT_BUTTON: 'Clonar Ordens selecionadas',
      MODAL_DESC_PRE: 'Em',
      MODAL_DESC_POST: 'o sistema cancelou automaticamente as seguintes Ordens. Selecione as que serão clonadas.',
      TOOLTIP_DATE: 'Esta data foi automaticamente movida para hoje, respeitando a duração do período.',
      EDIT_PRICES: 'Editar preços',
      PUBLISHING: 'Publicando...'
    }
  },
  NEGOTIATION_LIST: {
    UNDER_NEGOTIATION: 'Em negociação',
    COUNTERORDERS: 'Contra-ordem(s)',
    BOOKED: 'Fechado',
    OF: 'de',
    AMENDMENT_SENT: 'Alteração enviada',
    AMENDMENT_RECEIVED: 'Alteração recebida',
    COUNTERORDER_SENT: 'Contra-ordem enviada',
    COUNTERORDER_RECEIVED: 'Contra-ordem recebida',
    PREBOOK_REQUESTED: 'Pré-aceite requisitado',
    PREBOOK_RECEIVED: 'Pré-aceite recebido'
  },
  TRADED_VIEW: {
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> negócio(s)',
    SUMARIZE_TONS: ' para um total de <span class="quantity">{{quantity}}</span> {{unit}}.',
    SUMARIZE_PRIVATE_TONS: 'Negociações privadas para <span class="quantity">{{quantity}}</span> {{unit}}.',
    PLACEHOLDER: 'A plataforma ainda não registra movimentos.'
  },
  NEGOTIATION_ACTIONS: {
    AMENDMENTS: 'Propor alterações',
    ASSIGN_SLOTS: 'Atribuir Slots',
    CHAT: 'Envie uma mensagem',
    DELEGATE_TRADE_HELP: '<b>Atenção!</b> A gestão deixará de estar a cargo da sua Empresa.',
    DELEGATE_TRADE: 'Delegar Negócio a um Corretor',
    DELEGATE: 'Delegar Negócio',
    DOWNLOAD_BUSINESS_CONFIRMATION: 'Download confirmação de Negócio',
    DOWNLOAD_CONTRACT: 'Download Contrato',
    DOWNLOAD_TRACK_RECORD: 'Download registro de negócios',
    EDIT_BOOKING_DATE_HELP: '<b>Atenção!</b> A modificação da data de um negócio ficará refletida no histórico e todas as partes envolvidas serão notificadas.',
    EDIT_BOOKING_DATE: 'Editar data de confirmação',
    EXTERNAL_ID: 'Gerenciar ID externo',
    LINK_NEGOTIATIONS: 'Vincule Negociações',
    REQUEST_CANCELLATION_BODY: 'Sua contraparte receberá uma solicitação de cancelamento que deve aceitar para cancelar efetivamente este Negócio.',
    REQUEST_CANCELLATION: 'Requisitar cancelamento',
    TURN_BARTER: 'Transformar em um Barter'
  },
  COMPANY_TRADED_VIEW: {
    BOUGHT: 'Comprado',
    LIQUIDATED: 'Liquidado',
    BONUS: 'Bonus',
    NO_RECORDS: 'O sistema ainda não gravou alterações.',
    SOLD: 'Vendido',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> negócio(s)',
    SUMARIZE_VOLUME: ' para um total de <span class="quantity">{{quantity}}</span> {{unit}}.',
    UNLOADED: 'Aplicado',
    VIEWMODE: {
      GROUPED: 'Agrupado por Produtos',
      UNGROUPED: 'Desagrupado'
    }
  },
  PREORDERS_VIEW: {
    NO_RECORDS: 'A Empresa ainda não registrou pré-ordem.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Pre-ordem(s)',
    SELECTED: '<span class="quantity">{{quantity}}</span> Pre-ordem(s) selecionada(s)',
    DESCRIPTION_0: 'Essas são as Ordens selecionadas pelos seus clientes. Você pode revisar, editar ou mesclá-las com de outras Empresas e gerar uma única oferta de maior volume. Seus cliente podem visualizar se as Ordens selecionadas foram revisadas e publicadas ou rejeitadas pela sua Empresa.',
    DESCRIPTION_1: 'Caso tenha designado um corretor para trabalhar a sua Ordem, nesta tela você poderá saber se sua Ordem foi revisada e publicada ou rejeitada pelo corretor.',
    TABLE_HEADER: {
      BROKER: 'Corretor designado'
    },
    REVIEW: 'Revisar...',
    CANCEL_CONFIRM: {
      MODAL_TITLE: 'Cancelar Pré-ordens',
      TITLE_0: 'Você tem certeza que deseja cancelar essas Pré-ordens?',
      TITLE_1: 'Você tem certeza que deseja cancelar essa Pré-ordem?',
      BODY_0: 'Pré-ordens: {{ id }}',
      BODY_1: 'Pré-ordem: {{ id }}'
    },
    REJECT_CONFIRM: {
      MODAL_TITLE: 'Rejeitar Pré-ordens',
      TITLE_0: 'Você tem certeza que deseja rejeitar essas Pré-ordens?',
      TITLE_1: 'Você tem certeza que deseja rejeitar essa Pré-ordem?',
      BODY_0: 'Pré-ordens: {{ id }}',
      BODY_1: 'Pré-ordem: {{ id }}'
    }
  },
  TEAM_VIEW: {
    ACTIVE: 'Ativo',
    ADD_USER: 'Adicionar Usuário',
    EMAIL: 'Email',
    HEADING: 'Crie usuários para a sua companhia e determine diferentes funções.',
    IDENTITY_NUMER: 'Número do documento',
    INVITATION_SENT: 'Convite enviado',
    NAME_LASTNAME: 'Nome e sobrenome',
    NAME: 'Usuários do {{company}}',
    RESTRICT_VISIBILITY: 'Restrinja a visibilidade às suas zonas',
    ROLE: 'Posição',
    USERS: 'Usuários',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Deletar usuário',
      TITLE: 'Você tem certeza que deseja remover esse usuário da equipe?',
      BODY: 'Se desejar, você poderá adicionar este usário novamente.'
    },
    INVITE_MODAL: {
      ADD_USER_BUTTON: 'Adicionar Usuário',
      ALREADY_EXISTS: 'O Usuário já faz parte da Empresa.',
      BODY: 'Insira um e-mail válido e uma posição. Enviaremos um e-mail ao Usuário para orientá-lo no processo de cadastro.',
      EMAIL: 'Email',
      ROLE: 'Função',
      SELECT_ROLE: 'Selecione uma função',
      TITLE: 'Adcionar Usuário a {{company}}'
    }
  },
  MANAGERS: {
    MANAGERS: 'Representantes',
    MANAGER: 'Representante',
    APPLY_TO_BE_MANAGER: 'Candidate-se para se tornar um representante',
    REPRESENTS: 'Represente',
    NO_MANAGERS: 'Sua empresa não tem representantes carregados',
    REQUEST: {
      REQUEST: 'Aplicação',
      SEND: 'Enviar solicitação'
    },
    STATUS: {
      ACTIVE: 'Ativo',
      PENDING: 'Pendente',
      ACCEPT: 'Aceitar aplicação',
      ACCEPTED: 'Ativo',
    },
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Representante desvinculado',
      TITLE: 'Você tem certeza de que quer desvincular este representante de sua empresa?',
      BODY: 'Este usuário perderá todas as permissões e acesso à sua empresa.'
    }
  },
  COUNTERPARTIES: {
    APPROVED: 'Aprovada',
    HEADING: 'Aprove nesta seção as contrapartes que sua companhia pode fazer negócio.',
    MY_NETWORK: 'Minha Rede',
    NEW: 'Nova!',
    NO_COUNTERPARTS: 'Nenhuma contraparte registrada.',
    VERIFICATION_DATE: 'Data de verificação',
    STATUS: {
      REMOVE_TITLE: 'Tem certeza de que está bloqueando esta empresa?',
      REMOVE_BODY: 'Atualmente esta empresa pertence à sua rede. Ao bloqueá-lo, você também removerá essa relação.',
      REMOVE_TOOLTIP: 'Bloquear Empresa',
      YES_REMOVE: 'Sim, bloquear'
    },
    NETWORK: {
      ACCEPT: 'Aceitar pedido',
      ADD: 'Adicionar à minha Rede',
      CANCEL: 'Cancelar pedido',
      PENDING: 'Pendente',
      REMOVE_BODY: 'Você pode adicioná-lo novamente mais tarde, mas pode exigir uma aceitação da outra Empresa.',
      REMOVE_TITLE: 'Você tem certeza de remover esta Empresa da sua Rede?',
      REMOVE_TOOLTIP: 'Remover da minha Rede',
      WITHDRAW_BODY: 'Você pode reenviar uma nova solicitação mais tarde.',
      WITHDRAW_TITLE: 'Quer retirar este pedido?',
      MODAL: {
        ADD_MORE: 'Adicione {{quantity}} linhas...',
        BULK_LOAD: 'Carga maciça',
        DESCRIPTION: 'As Empresas que você adicionar e não pertencer ao Mercado aberto serão visíveis apenas para sua Empresa.',
        EMAIL: 'E-mail do Administrador',
        EMAILS_ERROR: '<p><b>Um dos e-mails inseridos é seu.</b></p>Lembre-se de que de acordo com nossos <a href="/links/terms">Termos e Condições</a>, apenas representantes da Empresa podem se registrar como Administradores. Se você está realmente autorizado, preencha o <a href="/select-market">formulário de registro</a>.',
        EMAILS_HELP: 'Digite o e-mail dos Usuários da Empresa que você está adicionando. Enviaremos a eles um e-mail para se inscreverem.',
        EMAILS: 'E-mail do Administrador',
        FISCAL_ID_HELP: 'Pesquise a Empresa pelo seu ID fiscal.',
        MODE_NOT_AVAILABLE: 'Este modo não está disponível em telas pequenas.',
        SINGLE_LOAD: 'Carga individual',
        TITLE: 'Convide uma empresa'
      }
    }
  },
  COMPANY_LOCATIONS_VIEW: {
    TITLE: '{{company}} locais de entrega',
    ADD_LOCATION_BUTTON: 'Adicionar local',
    HEADING: 'Gerencie nesta seção os locais de entrega da sua Empresa.',
    INFO: '<b>Importante!</b> Lembre-se de completar dos locais que sua Empresa pode comprar. Você não poderá visualizar vendedores e nem fazer negócios em locais não especificados.',
    LOCATION_NOT_DEFINED: 'Você ainda não definiu nenhum local de entrega.',
    ZONE: 'Zona',
    LOCATION: 'Localização',
    BUYER_OPTION: 'Opção do comprador',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Excluir local',
      TITLE: 'Você tem certeza que deseja excluir este local?'
    },
    ADD_LOCATION_TITLE: 'Adicionar um local de entrega a {{company}}'
  },
  CERTIFICATES: {
    TITLE: 'Certificados da empresa',
    ADD_CERTIFICATE: 'Adicionar Certificado',
    HEADING: 'Gerenciar os Certificados de gerenciamento e produto da sua Empresa. Os documentos atuais estarão visíveis para os seus colegas e você poderá vinculá-los aos seus Ordens para fins de rastreabilidade.',
    NO_DATA: 'Nenhum Certificado foi adicionado ainda.',
    TABLE: {
      PRODUCT_CATEGORY: 'Categoria',
      NUMBER: 'Nro.',
      DATES: 'Validade',
      SCOPE: 'Escopo',
      VERIFIED: 'Verificado'
    },
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Excluir Certificado',
      TITLE: 'Você tem certeza que deseja excluir este Certificado?'
    },
    MODAL: {
      TITLE: 'Adicionar certificado',
      BODY: 'Os certificados e documentos que você enviar serão publicados na plataforma. Esses documentos podem ser enviados à autoridade externa para verificação.',
      HOLDER: 'Titular do Certificado',
      HOLDER_PLACEHOLDER: 'Titular...',
      HOLDER_HELP: 'Deve ser consistente com o titular/nome do requerente impresso no certificado.',
      TYPE_PLACEHOLDER: 'Selecione...',
      PRODUCT_CATEGORY: 'Categoria de produto',
      PRODUCT_CATEGORY_PLACEHOLDER: 'Digite a categoria...',
      NUMBER: 'Nro.',
      NUMBER_PLACEHOLDER: 'Digite o número/referência...',
      NAME_PLACEHOLDER: 'Selecione...',
      OTHER_NAME_PLACEHOLDER: 'Digite o nome...',
      ISSUED_BY_PLACEHOLDER: 'Selecione...',
      OTHER_ISSUED_BY_PLACEHOLDER: 'Digite o nome...',
      OPTION_OTHER: 'Outro...',
      DATE_FROM: 'Válido de',
      DATE_TO: 'Data de validade',
      SCOPE: 'Escopo',
      SCOPE_PLACEHOLDER: 'Escopo do Certificado (de preferência em inglês)...',
      SCOPE_HELP: 'Insira os produtos e as informações de auditoria relevantes cobertas pelo Certificado (e aquele que exclui), por exemplo o certificado verifica a embalagem de alimentos frescos para transporte e conservação.',
      IMAGE: 'Imagem do certificado',
      IMAGE_HELP: 'Carregue uma captura legível do Certificado. Formatos suportados: JPG, PNG, GIF ou SVG.'
    },
    BUTTON: {
      TITLE: 'Ver Certificados da Empresa',
      MODAL: 'Certificados atuais do {{company}}'
    }
  },
  LOCATION_PICKER: {
    ADD_LOCATION_BUTTON: 'Adicionar local',
    TITLE: 'Adicionar local',
    BODY_0: 'Você tanto pode definir um <b>Zona</b> ou uma <b>Cidade</b>.',
    BODY_1: 'Lembre-se, você pode adicionar a quantidade de locais que desejar.',
    SELECT_ZONE: 'Selecionar uma zona',
    INCLUDE_LOCATIONS: 'Cidades incluídas:',
    PLACEHOLDER: 'Digite uma cidade...',
    CONFIRM_BUTTON: 'Adicionar local'
  },
  TEMPLATE_NAME_PICKER: {
    SAVE_AS_TEMPLATE_BUTTON: 'Salvar como Modelo...',
    SAVE_AS_TEMPLATE_BUTTON_ABBR: 'Modelo...',
    TITLE: 'Entre com o nome do modelo',
    CONFIRM_BUTTON: 'Salvar modelo',
    DESCRIPTION: 'Modelos permitem a criação de Ordens de maneira mais fácil e podem ser reutilizados. Dê um nome que torne a indentificação bem fácil.'
  },
  REFERENCE_NUMBER_PICKER: {
    TITLE: 'Gerenciar ID externo',
    DESCRIPTION: 'Você pode associar um identificador externo a esta transação, por exemplo, um identificador gerado por um sistema interna da Empresa. Esta ação gerará uma nova versão dos doscumentos associados (histórico, Contrato, confirmação de negócio, etc.) refletindo esta informação.',
    PLACEHOLDER: 'ID externo...'
  },
  TRACK_RECORD: {
    ADD_COMMENT: 'Adicionar um comentário',
    COMMENT: 'Comente...',
    COMMENT_PLACEHOLDER: 'Digite um comentário...',
    FIELD: 'Campo',
    NEW_VALUE: 'Novo valor',
    ORIGINAL_VALUE: 'Valor original',
    PRIVATE: 'Visível apenas para minha Empresa',
    PRIVATE_HELP: 'Comentários privados não serão incluídos na documentação gerada.',
    TIE_BREAKER: 'Omitido. Houve um lance anterior definido automaticamente.',
    TITLE: 'Histórico',
    USER_AND_ACTION: 'Usuário e ação',
    FIELDS: {
      PRICE_UNIT: 'Moeda',
      PRICE_YEAR: 'Ano do prêmio',
      PRICE_MONTH: 'Mês do prêmio',
      ARBITRATION_CHAMBER: 'Câmara de arbitragem',
      DATE_FROM: 'A partir da data',
      DATE_TO: 'Até a data',
      CLAUSES: 'Cláusula',
      REFERENCE: 'Referância externa'
    },
    EVENTS: {
      ATTACHMENT: {
        CREATED: 'anexou o arquivo',
        DELETED: 'excluiu o arquivo',
        DOWNLOADED: 'baixou o arquivo',
      },
      AUCTION: {
        BIDDED: 'fez uma oferta com esses dados:',
        CREATED: 'criou o Leilão.',
        FINISHED: 'Leilão terminado.'
      },
      BARTER: {
        ACCEPTED: 'aceitou os termos do Barter.'
      },
      CERTIFICATE_OF_DEPOSIT: {
        CREATED: 'criou o Certificado de depósito.'
      },
      CHAT: {
        FILE: {
          SENT: ''
        },
        MESSAGE: {
          SENT: ''
        }
      },
      COMMENT: {
        CREATED: 'comentou:'
      },
      COMPANY: {
        DISABLED: 'Empresa desativada.',
      },
      CREDIT: {
        CREATED: 'iniciou o processo de Solicitação.',
        GRANTED: 'indicou a Solicitação como concedida.',
        REJECTED: 'rejeitou a Solicitação.',
        RESTORED: 'restaurou a Solicitação para uma versão anterior.',
        UPDATED: 'fez alterações na Solicitação.',
        WITHDRAWN: 'retirou a Solicitação.'
      },
      DOA: {
        APPROVED: 'A delegação de autoridade foi aprovada.',
        CREATED: 'criou uma delegação de autoridade.',
        REJECTED: 'A delegação de autoridade foi rejeitada.',
        WITHDRAWN: 'A delegação de autoridade foi retirada.',
        VOTE: {
          APPROVED: 'deu seu voto: Aprovado.',
          REJECTED: 'deu seu voto: Rejeitado.'
        }
      },
      ENVELOPE: {
        CANCELED: 'cancelou o Envelope.',
        COMPLETED: 'Todos os signatários assinaram.',
        CREATED: 'criou o Envelope.',
        SIGNERS: {
          DEFINED: 'definiu os seguintes signatários:',
          NOTIFIED: 'Os signatários foram notificados.',
          SIGNED: 'Um dos signatários assinou'
        }
      },
      NEGOTIATION: {
        BOOKED: 'fechado.',
        ORIGINAL_BOOKING_DATE_UPDATED: 'modificou a data do Negócio.',
        PRE_BOOKED: 'solicitou um pre-fechamento.',
        REJECTED: 'rejeitado.',
        UPDATED: 'enviar uma Contra-ordem com essas alterações:',
        WITHDRAWN: 'cancelar a Contra-ordem.',
        AMENDMENT: {
          ACCEPTED: 'aceitou as alterações.',
          REJECTED: 'rejeitou as alterações.',
          UPDATED: 'propôs as seguintes alterações:'
        },
        BROKER: {
          UPDATED: 'delegou a gestão do Negócio a outra Empresa.'
        },
        CANCEL: {
          ACCEPTED: 'concordou em cancelar o Negócio.',
          REJECTED: 'recusou-se a cancelar o Negócio.',
          REQUESTED: 'solicitou o cancelamento do Negócio.',
          WITHDRAWN: 'retirou o pedido de anulação.'
        },
        EXTERNAL_REFERENCE: {
          UPDATED: 'realizou uma modificação nesses campos:'
        },
        REPRESENTED: {
          UPDATED: 'realizou uma modificação nas contrapartes:'
        },
        REQUEST: {
          ACCEPTED: 'aceitou o pedido de negociação.',
          REJECTED: 'rejeitou o pedido de negociação.',
          SENT: 'enviou um pedido de negociação.'
        }
      },
      ORDER: {
        CREATED: 'criou a Ordem.',
        BROKER: {
          UPDATED: 'delegou a gestão do Negócio a outra Empresa.'
        }
      },
      FIXATION_REQUEST: {
        UPDATED: 'Atualizado o preço e/ou volume da solicitação.',
        CREATED: 'Criou uma nova solicitação de fixação.'
      },
      APPLICATION: {
        APPROVED_AMOUNT_UPDATED: 'modificou o valor aprovado.',
        APPROVED: 'aprovou uma etapa da Solicitação.',
        CREATED: 'iniciou o processo de Solicitação.',
        FORM: 'atualizou as informações da Solicitação.',
        GRANTED: 'concedeu o Solicitação.',
        PRODUCT_UPDATED: 'modificou as propriedades do Produto Solicitado.',
        REJECTED: 'rejeitou a Solicitação.',
        REVISION: 'criou uma revisão da Solicitação.',
        UNDO: 'voltou a uma etapa anterior da Solicitação.',
        UPDATED: 'fez alterações na Solicitação.',
        WITHDRAWN: 'retirou a Solicitação.'
      }
    }
  },
  MARKET_SUMMARY: {
    ZONES: 'Zonas',
    LOCATIONS: 'Cidades',
    DELIVERY_LOCATION: 'Local de entrega',
    PURCHASE: 'Compra',
    SALE: 'Venda',
    LAST_NEGOTIATED: 'Último Negócio',
    LAST_NEGOTIATED_ABBR: 'Últ. Neg.',
    OFFERED_VOLUME: 'Volume oferecido (Ofertas)',
    OFFERED_VOLUME_ABBR: 'Vol.',
    DEFERRED: 'Diferido'
  },
  WORKING_ORDERS: {
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Ordem(s)',
    SELECTED: '<span class="quantity">{{quantity}}</span> Ordem(s) selecionada',
    BOOK: 'Fechado',
    SELECT_ALL: 'Selecionar todos',
    UNAUTHORIZED: 'Não autorizado',
    CONSIGNMENT_CONDITIONS: 'Condições de consignação',
    ARBITRATION_CHAMBER: 'Câmara de arbitragem',
    CONTRACT_DEVIATIONS: 'Alterações do contrato padrão',
    AUCTION_DATE: 'Data do Leilão',
    AUCTION_DURATION: 'Duração do Leilão',
    AUCTION_DATE_FORMAT: 'dd/MM/yy - HH:mm',
    MINUTES: 'minutos',
    BUYER_OPTION: 'Opção do Comprador',
    CHAT: 'Bate-papo',
    VIEWMODE: {
      TABLE: 'Tabela',
      EXPANDED: 'Expandida',
      GALLERY: 'Galeria',
      MAP: 'Mapa'
    }
  },
  TEMPLATES_VIEW: {
    TITLE: 'Modelos',
    NO_RECORDS: 'A Empresa ainda não possui nenhum modelo.',
    DESCRIPTION: 'Modelos permitem a criação de Ordens de maneira mais fácil e podem ser reutilizados.',
    TABLE_HEADER: {
      DATE: 'Criado em'
    },
    VIEW_TEMPLATE: 'Visualizar modelo',
    CONSIGNMENT_CONDITIONS: 'Condições de consignação',
    ARBITRATION_CHAMBER: 'Cãmara de arbitragem',
    CONTRACT_DEVIATIONS: 'Alterações do contrato padrão',
    DELETE_CONFIRM: {
      MODAL_TITLE: 'Excluir modelo',
      TITLE: 'Você tem certeza que deseja excluir esse modelo?'
    },
    DELETE: 'Deletar',
    AUCTION_DATE: 'Data do Leilão',
    AUCTION_DURATION: 'Duração do Leilão',
    AUCTION_DATE_FORMAT: 'dd/MM/yy (HH:mm)',
    MINUTES: 'minutos'
  },
  FILTERS: {
    ACTIVE: 'Ativo',
    CASH: 'Pagamento contra entrega',
    CLEAR: 'Remover filtro',
    CLOSED_BARTER: 'Fechada',
    CLOSED: 'Fechada',
    COMMERCIAL_ZONE: 'Zona comercial',
    DELIVERY_RANGE: 'Intervalo de entrega',
    FIXATION_PERIOD: 'Período de fixação',
    DELIVERY_ZONE: 'Zona de entrega',
    ASSIGNMENT_RANGE: 'Faixa de atribuição',
    ENVELOPE_STATUS: 'Status',
    EXPIRED: 'Expirados',
    FILTER_CONDITIONS: 'Condições de filtro',
    FILTER: 'Filtrar',
    HIDE: 'Ocultar filtros',
    INACTIVE: 'Inativo',
    LABELS: 'Marcadores',
    ON_DATE: 'Na data',
    OPEN_BARTER: 'Aberta',
    OPEN: 'Aberta',
    OPERATION_TYPE: 'Tipo de operação',
    ORDER_TYPE: 'Tipo de publicação',
    PRICE: 'Preço',
    REMOVE_ALL: 'Remover todos',
    SEE_ALL: 'Ver tudo...',
    SELECT_PERIOD: 'Selecione um período',
    SELECT_RANGE: 'Selecione o escopo',
    SHOW: 'Mostrar filtros',
    TEXT_PLACEHOLDER: 'Texto...',
    VALIDITY: 'Validade',
    WITH_CONTRACT: 'Com Contrato',
    WITHOUT_CONTRACT: 'Sem Contrato',
    SEARCH_CONTRACT: 'Contrato de pesquisa',
    ARCHIVED: 'Arquivados',
    VALID: 'Em vigor',
    FINTECH: {
      ACTIVE: 'Ativas',
      GRANTED: 'Concedidas',
      REJECTED: 'Rejeitadas',
      WITHDRAWN: 'Retiradas',
      EXPIRED: 'Expiradas'
    },
    CONDITIONS: {
      AFTER: 'Depois de',
      BEFORE: 'Antes de',
      CONTAINS: 'Contém',
      ENDS: 'Termina com',
      EQUAL: 'É igual a',
      FALSE: 'Falso',
      GREATER_THAN: 'Maior que',
      IS: 'É exatamente',
      LAST_MONTH: 'Mês passado',
      LAST_WEEK: 'Semana Anterior',
      LOWER_THAN: 'Menor a',
      STARTS: 'Começa com',
      NONE: 'Nenhum',
      NOT: 'Não contém',
      TRUE: 'Verdadeiro',
      WHEN: 'Data exata',
      RANGE: 'Gama'
    }
  },
  DOCUMENTS: {
    ENTER: 'Entra',
    RELATED_DOCS: 'Documentos relacionados',
    SELECT_PRODUCT: 'Selecione um produto',
    SELECTED_MARKET: 'Mercado:'
  },
  INBOX: {
    DATE_FORMAT: 'dd/MM/yy, HH:mm',
    EMPTY_UNREAD: 'Você não possui notificações não lidas.',
    EMPTY: 'Você ainda não recebeu nenhuma mensagem.',
    MARK_ALL_AS_READ: 'Marcar tudo como lido',
    MARK_AS_READ: 'Marcar como lido',
    MARK_AS_UNREAD: 'Marcar como não lido',
    NOTIFICATIONS: 'Notificações',
    SEE_ALL: 'Visualizar tudo...',
    TO: 'para <b>{{company}}</b>'
  },
  SUPER_ADMIN: {
    ACTIVATE_COMPANY_MODAL: {
      TITLE: 'Ativação da Empresa {{company}}',
      BODY: 'Selecione o <b>nível</b> de ativação.',
      LEVEL: 'Nível',
      ACTIVATE_BUTTON: 'Modifique a ativação'
    }
  },
  USER_PROFILE: {
    LANGUAGE: 'Idioma',
    NOTIFICATIONS: 'Notificações',
    PHONE: 'Telefone celular',
    PROFILE_HELP: 'Mantenha sua informação pessoal atualizada.',
    PROFILE_TITLE: 'Informação pessoal',
    PROFILE_UPDATE: 'Atualização de perfil',
    PROFILE_UPDATED: '<b>Perfil atualizado!</b>',
    SECURITY_TITLE: 'Segurança',
    CHANNELS: {
      ENABLE: 'Especifique por quais canais você deseja receber notificações.',
      HELP: 'Controles para garantir que você receba as notificações de seu interesse.',
      TITLE: 'Canais'
    },
    PASSWORD: {
      ERROR: '<b>Senha errada</b>',
      HELP: 'Escolha uma senha única para proteger sua conta. Nós recomendamos mudar a senha periodicamente.',
      NEW: 'Nova senha',
      OLD: 'Senha atual',
      UPDATE: 'Mude a senha',
      UPDATED: '<b>Senha alterada!</b>'
    }
  },
  CELLPHONE_VERIFICATION: {
    MODIFY: 'Alterar número',
    ADD: 'Adicionar número',
    MODAL: {
      TITLE: 'Configuração do celular',
      COUNTRY_CODE: 'Código País',
      AREA_CODE: 'Código local',
      SELECT_CODE: 'Selecione o código do país',
      NUMBER_HELP: 'Código País + Código local + Nº. Exemplo: +55 (11) 9yyyy-xxxx',
      VERIFICATION_DESCRIPTION: 'Nós enviamos um código de segurança ao número informado. Caso não tenha recebido após alguns minutos,',
      SEND_AGAIN: 'clique aqui para enviar novamente',
      NEXT: 'Próximo',
      ERROR: 'O código de verificação está incorreto.',
      CODE_FROM_TEXT_MESSAGE: 'Código de verificação',
      VALIDATING: 'Validando código...',
      ALLOW_WHATSAPP: 'Eu concordo em receber notificações pelo <b>WhatsApp</b>',
      ALLOW_WHATSAPP_HELP: 'Caso o serviço esteja disponível, a plataforma notificará as novidades através da aplicação <b>WhatsApp</b>, o que inclui a possibilidade de realizar algumas ações sem ter que entrar na plataforma. Caso contrário, eles serão feitos via SMS.'
    }
  },
  PAGINATOR: {
    FIRST_PAGE: 'Primeira página',
    LAST_PAGE: 'Última página',
    NEXT_PAGE: 'Próxima página',
    OF: 'of',
    PREV_PAGE: 'Página anterior',
    SUMMARY: 'Página {{page}} de {{total}}'
  },
  COUNTDOWN: {
    DAY: 'Dia(s)',
    HOUR: 'Hora(s)',
    MINUTE: 'Minuto(s)',
    SECOND: 'Segundo(s)',
    DAY_ABBR: 'd',
    HOUR_ABBR: 'h',
    MINUTE_ABBR: 'm',
    SECOND_ABBR: 's',
    EXPIRED: 'Expirado'
  },
  PRICE_FORM: {
    FIXED: 'Fixo',
    PREMIUM: 'Basis',
    TO_BE_FIXED: {
      LABEL: 'A definir',
      CONDITIONS: 'Condições de fixação:',
      PLACEHOLDER: 'Insira as condições de fixação...'
    },
    CONSIGNMENT: {
      LABEL: 'Para Consignar',
      CONDITIONS: 'Condições de consignação:',
      PLACEHOLDER: 'Insira as condições de consignação...'
    }
  },
  QUALITY_INPUT: {
    TITLE: 'Editar parâmetros',
    ACTION: 'Aplicar mudanças'
  },
  ASSIGNEES_MANAGER: {
    ADD_ME_BODY: 'Quer receber notificações sempre que houver novidades? Você pode cancelar a assinatura a qualquer momento.',
    ADD_ME: 'Inscrever-me',
    CREATOR: 'Criador',
    RESPONSIBLE: 'Operador(es)',
    WATCHERS: 'Seguidor(es)',
    MODAL: {
      CLOSE: 'Fechar',
      PLACEHOLDER: 'Digite o nome do usuário ou email...',
      RESPONSIBLE_HELP: 'Usuários de sua Empresa responsáveis por esta entidade. Deve haver pelo menos um atribuído.',
      RESPONSIBLE: 'Operador(es)',
      TITLE: 'Opções de rastreamento',
      WATCHERS_HELP: 'Usuários de sua Empresa que serão notificados sobre os desenvolvimentos nesta entidade.',
      WATCHERS: 'Seguidor(es)'
    }
  },
  COMMERCIAL_MANAGER: {
    TITLE: 'Administração de Usuários comerciais',
    DESCRIPTION: 'Defina os produtos e os limites de volume de compra para cada Usuário comercial da sua Empresa. Verifique o desempenho diário, semanal e mensal.',
    BUY_TRADES: 'Operações de compra',
    SELL_TRADES: 'Operações de venda',
    NO_TEAM: 'Sua Empresa não possui Usuários comerciais.',
    TABLE: {
      NAME_LASTNAME: 'Nome e sobrenome',
      PRODUCTS: 'Produtos',
      DAILY_STOP: 'Limite diário',
      TODAY_BOUGHT: 'Compras de hoje',
      WEEKLY_STOP: 'Limite semanal',
      WEEK_BOUGHT: 'Compras da semana',
      MONTHLY_STOP: 'Limite mensal',
      MONTH_BOUGHT: 'Compras do mês',
      TOTAL_BOUGHT: 'Compras totais',
      EDIT_PRODUCTS: 'Editar produtos...',
      IN_TRADES_1: 'em',
      IN_TRADES_2: 'operações.'
    },
    MODAL: {
      TITLE: 'Selecione produtos para:',
      DESCRIPTION: 'Você pode definir um ou mais. Deixe este campo vazio para atribuir todos os produtos.',
      PLACEHOLDER: 'Digite o nome do produto...'
    }
  },
  MESSENGER: {
    LAUNCHER_TITLE: 'Inicie uma conversa...',
    UNREAD_BULLET: 'Você tem mensagens não lidas',
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    TYPING: 'Digitando...',
    TRACK_RECORD: 'As mensagens trocadas ficarão disponíveis como parte do histórico.',
    PLACEHOLDER: 'Digite uma mensagem e pressione Enter...',
    DROP_HERE: 'Solte seus arquivos aqui...',
    ATTACH: 'Anexar...',
    UPLOADING: 'Enviando...',
    TODAY: 'Hoje'
  },
  OPERATION_ALERTS: {
    TITLE: 'Alertas de operações',
    ADD: 'Adicionar alerta',
    DESCRIPTION: 'Definir os critérios dos alertas para os quais você deseja receber notificações.',
    NO_ALERTS: 'Nenhum alerta definido.',
    DELETE: {
      ACTION: 'Excluir alerta',
      TITLE: 'Tem certeza de que deseja excluir este alerta?'
    },
    TABLE: {
      ACTIVE: 'Ativa',
      PRICE_RANGE: 'Faixa de preço'
    },
    TYPE: {
      INDISTINCT: 'Indistinto'
    },
    MODAL: {
      TITLE: 'Adicionar alerta',
      DESCRIPTION: 'Você receberá uma notificação quando uma nova postagem atender a esses requisitos:',
      OPERATION_TYPE: 'Tipo de operação',
      DELIVERY_PLACEHOLDER: 'Digite um ou mais lugares...',
      PLACES_HELP: 'Deixe este campo vazio se estiver indistinto.',
      DATE_HELP: 'Deixe este campo vazio se estiver indistinto.'
    }
  },
  MESSENGER_CENTER: {
    DATE_FORMAT: 'd/M/yy',
    EMPTY: 'Você ainda não enviou ou recebeu mensagens',
    NO_RESULTS: 'Nenhum contato ou mensagem encontrado',
    SEARCH_PLACEHOLDER: 'Pesquisar...',
    SELECT_ROOM: 'Selecione uma sala para conversar',
    TITLE: 'Centro de mensagens'
  },
  ADD_PHONE: {
    MESSAGE: '<i class="material-symbols-rounded">smartphone</i> Configure seu celular para receber <b>notificações via SMS</b>!'
  },
  PAYMENT_CONDITION_INPUT: {
    TITLE: 'Editar condições de pagamento',
    SELECT_TERMS: 'Selecione uma condição',
    OTHER_TERM: 'Condição alternativa'
  },
  GOOGLE_PLACES: {
    PLACEHOLDER: 'Pesquisar locais...'
  },
  ERROR_LIST: {
    BSDATE: 'A data não é válida',
    COMPANY_EXISTS: 'Uma Empresa com este identificador fiscal já existe, peça ao administrador para se <b>juntar à equipe</b>',
    COMPANY_NOT_ACTIVE: 'A Empresa não está ativa',
    EMAIL_OT_FOUND: 'O e-mail informado não está cadastrado',
    EMAIL: 'E-mail inválido',
    FILE_MAX_SIZE: 'O arquivo não pode ter mais do {{max}}',
    FILE_TYPE: 'Apenas os seguintes tipos de arquivo são suportados:',
    FUTURE_TIME: 'O horário digitado não pode ser anterior ao horário atual',
    MATCHES: 'Senha e confirmação não coincidem',
    MAX_FILES: 'Número máximo de arquivos permitido: {{max}}',
    MAX_LENGTH: 'O número máximo de caracteres é {{max}}',
    MAX: 'Valor máximo permitido: {{max}}',
    MIN_LENGTH: 'O número mínimo de caracteres é {{min}}',
    MIN: 'Valor mínimo permitido: {{min}}',
    PATTERN: 'Não cumpre os requisitos',
    REQUIRED: 'Este campo é obrigatório',
    TIME_RANGE: 'O tempo selecionado deve estar no seguinte intervalo:',
    UNIQUE: 'E-mail já utilizado',
    VALID_CUIT: 'O identificador fiscal inserido não é válido',
    VALID_ID: 'O código de identidade inserido não é válido',
    WAYBILL_EXISTS: 'Este número já foi usado'
  },
  PRICE_DISPLAY: {
    TO_BE_FIXED: 'A definir',
    TO_BE_FIXED_ABBR: 'PAD',
    CONSIGNMENT: 'Para Consignar',
    CONSIGNMENT_ABBR: 'Consig.',
    CONSIGNMENT_CONDITIONS: 'Condições de consignação:'
  },
  SLOTS: {
    ACCEPT_AND_ASSIGN: 'Aceitar e atribuir tudo à minha Empresa...',
    ACCEPTED: 'Aceitos',
    ACTORS: 'Intervenientes',
    ADD: 'Adicionar Slots',
    ARRIVAL_DATE: 'Data de chegada',
    ASSIGN: 'Atribuir',
    ASSIGNED_BY_TO: '<b>{{allocator}}</b> atribuiu os seguintes Slots para <b>{{company}}</b>.',
    ASSIGNED_BY: '<b>{{allocator}}</b> atribuiu os seguintes Slots.',
    ASSIGNED_QUANTITY: '{{quantity}} atribuídas',
    ASSIGNED_TRADE: 'Este Negócio tem um total de <b>{{quantity}} Slot(s)</b> atribuídos.',
    ASSIGNED: 'Atribuído',
    AVAILABLE_BATCHES: 'Lotes de <b>{{product}}</b> com Slots pendentes de atribuição:',
    BATCH_DETAILS: 'Detalhes do lote',
    BATCH_ID: 'Lote de Slots #{{id}}',
    BATCH: 'Lote de Slots',
    CONDITIONAL: 'Condicional',
    CONFORMING: 'Conforme',
    CREATED: 'Criados',
    DELETE_BODY: 'Você está prestes a deletar o Slot <code>{{slot}}</code>. <span class="text-muted">Se já tiver sido atribuído, será retirado.</span><br><br>Essa ação não pode ser desfeita.',
    DOWNLOAD_PDF: 'Baixar PDF',
    EDIT_ACTORS: 'Editar participantes...',
    ESTABLISHMENT_HELP: 'No caso de possuir um armazém.',
    ESTABLISHMENT: 'Estabelecimento',
    ESTIMATED: 'Estimado',
    EXPORT_PERIOD: 'Você pode especificar um período de até 30 dias para incluir no relatório.',
    FREIGHT_PAYED: 'Frete pago',
    FREIGHT_PAYER: 'Pagador de frete',
    FREIGHT_TO_PAY: 'Frete a pagar',
    GRAINS: 'Grãos/Espécies',
    ID: 'Nro. de Slot',
    KM_TO_TRAVEL: 'Quilômetros para viajar',
    LOAD: 'Carga',
    LOADING_DATE: 'Data de carregamento',
    LOCALITY: 'Localidade',
    MANAGED_BY: 'Lote gerenciado por:',
    NO_RECORDS: 'Nenhum Slot registrado',
    ORIGIN: 'Origem do produto',
    OVERALLOCATION_WARNING_ASSIGNED: 'Este Negócio já tem <b>{{ assigned }}</b> Slot(s) atribuído(s).',
    OVERALLOCATION_WARNING: 'Aviso de possível superlotação, para um Negócio de <b>{{tons}}</b> toneladas.',
    PENDING: 'Esperando resposta',
    PROVINCE: 'Província',
    QUALITY_DECLARATION: 'Declaração de Qualidade',
    RECEIVED: 'Recebido pendente',
    REFRESH_STATUS_STOP: 'Atualizar status',
    REJECTED: 'Rejeitados',
    RENSPA: 'R.E.N.S.P.A.',
    RETURN_BODY: 'Você vai devolver o Slot <code>{{slot}}</code> para <b>{{allocator}}</b>, tem certeza?. Isso significa que você não o usará <span class="text-muted">(se o Slot já tiver sido atribuído a um terceiro, ela será retirada)</span>.<br><br>Esta ação não pode ser desfeita.',
    RETURN: 'Retornar Slot',
    RETURNED: 'Devolvidos',
    TAKE: 'Solicitar/Receber',
    TOGGLE_DASHBOARD: 'Alternar Painel',
    UNAVAILABLE_BATCHES: 'Nenhum lote de <b>{{product}}</b> com Slots pendentes de atribuição encontrado.',
    VEHICLE_DATA: 'Dados de transporte',
    WAYBILL_FILE_HELP: 'Somente arquivos PDF são aceitos (4 MB máx.).',
    WAYBILL_FILE: 'Carta de porte em branco',
    WAYBILL_GENERATE: 'Gerar Carta de Porte',
    WAYBILL_GENERATOR_DESCRIPTION: 'Faça a Carta de Porte para caminhões, preenchendo o seguinte formulário.',
    WAYBILL_GENERATOR: 'Gerador de Cartas de Porte',
    WAYBILL_INFO: 'Informação para Carta de Porte',
    WEIGHED_DESTINATION: 'A carga será pesada no destino',
    WITHDRAWN: 'Aposentados',
    YES_RETURN: 'Sim, retornar',
    RETURN_BATCH: 'Retornar todos os Slots',
    RETURN_BATCH_BODY: 'Tem certeza de que deseja devolver essos <b>{{ quantity }} Slot(s)</b> ao <b>{{ allocator }}</b>?. Isso significa que você não o usará <span class="text-muted">(se algum Slot já tiver sido atribuído a terceiros, ele será retirado)</span>.<br><br>Esta ação não pode ser desfeita.',
    RETURN_SELECTED: 'Retornar slots selecionados',
    DELETE_BATCH: 'Deletar todos os Slots',
    DELETE_BATCH_BODY: 'Tem certeza de que deseja deletar essos <b>{{ quantity }} Slot(s)</b>..<br><br>Favor observar que somente as slots não alocadas serão removidas.',
    DELETE_SELECTED: 'Deletar slots selecionados',
    // REFRESH_WAIT: 'Espere',
    ALREADY_EXISTS: 'Por favor, note! As seguintes cotas já existem dentro do sistema:',
    STOP: {
      DESCRIPTION: 'Quando disponível, a plataforma pode exibir informações do <a href="https://www.ssta.gob.ar/turnos/" target="_blank">sistema STOP</a>. Para isso, é necessário que você nos forneça a API KEY vinculada ao CPF da sua Empresa.',
      HELP: 'Não compartilharemos esta key com terceiros.',
      LABEL: 'STOP API key',
      LINK: 'Integrar com STOP',
      NOT_CONNECTED: 'Para importar informações é necessário fornecer uma API key.',
      TITLE: 'Sistema de Slots API KEY (STOP)'
    },
    ACTIONS: {
      ACCEPTED_BATCH_PARTIALLY: 'aceitou parcialmente o lote de Slots.',
      ACCEPTED_BATCH: 'aceitou o lote de Slots.',
      ASSIGNED_SLOT: 'atribui os Slots à sua Empresa.',
      CREATE_BATCH: 'gerou o lote de Slots.',
      REJECTED_BATCH: 'rejeitou o lote de Slots.',
      RETURNED_BATCH: 'devolveu o Slot.',
      SENT_BATCH: 'encaminhou o lote de Slots para <b>{{company}}</b>.',
      WITHDREW_BATCH_PARTIALLY: 'retiró parcialmente o lote de Slots.',
      WITHDREW_BATCH: 'retiró el lote de Cupos.'
    },
    TABLE_HEADER: {
      ASSIGNED_BY: 'Atribuído por',
      ASSIGNED_SLOTS: 'Atribuídas',
      ASSIGNED_TO: 'Atribuído a',
      BATCH_SLOTS: 'Slots',
      PENDING_SLOTS: 'Pendentes',
      PRODUCT_ABBR: 'Prod.',
      RECEIVER: 'Destinatário / Destino'
    },
    STATUS: {
      ACCEPTED: 'Aceitado',
      EXPIRED: 'Expirado',
      SENT: 'Enviado',
      RECEIVED: 'Recebido',
      REJECTED: 'Rejeitado',
      WITHDRAWN: 'Retirado',
      RETURNED: 'Devuelto',
      ASSIGNED: 'Atribuído',
      ASSIGNED_PARTIALLY: 'Atribuído parcialmente',
      PENDING: 'Pendente',
      STOP: {
        '1': 'Sem CTG',
        '2': 'Ativado',
        '3': 'Descarregado',
        '5': 'Chegado'
      }
    },
    ACCEPT_MODAL: {
      TITLE: 'Aceitação de Slots',
      DESTINATION: 'Destino',
      SLOTS_TO_ACCEPT: 'Número de Slots para aceitar:',
      REASONS: 'Motivos',
      REASONS_PLACEHOLDER: 'Digite os motivos para não aceitar o total (opcional)...',
      SIMPLER_QUESTION: 'Deseja aceitar todos os Slots e atribuí-los à sua Empresa?',
      SIMPLER_QUESTION_NO: 'Não, eu quero aceitar menos Slots...',
      SIMPLER_QUESTION_YES: 'Sim, <b>{{company}}</b> será o titular das Cartas de frete',
      ACCEPT: 'Aceitar {{quantity}} Slot(s)'
    },
    ASSIGN_MODAL: {
      AVAILABLE_SLOTS: 'Slots disponíveis neste lote: <b>{{quantity}}</b>',
      DIRECT: 'Direto',
      ENTER_ANOTHER: 'Digite outro...',
      NO_COMPANIES: 'Não há Empresas disponíveis.',
      QUANTITY: 'Número de Slots',
      TITLE: 'Atribuição de Slots',
      OBSERVATIONS_HELP: 'Os destinatários desses lotes receberão as mesmas observações.',
      OBSERVATIONS_PLACEHOLDER: 'Instruções, comentários...',
      PENDING_SLOTS: 'Slots pendentes: <b>{{quantity}}</b>',
      TAKE: 'Minha Empresa',
      TAKE_HELP: 'Ao atribuir essos Slots à sua Empresa significa que <b>{{company}}</b> será o titular das Cartas de frete.'
    },
    REQUEST_SLOT_MODAL: {
      AVAILABLE_SLOTS: 'Slots disponíveis neste lote: <b>{{quantity}}</b>',
      MAX_SLOTS: 'Número máximo de Slots: <b>{{quantity}}</b>',
      APPLIED_VOLUME: 'Volume aplicado',
      ENTER_ANOTHER: 'Digite outro...',
      NO_COMPANIES: 'Não há Empresas disponíveis.',
      QUANTITY: 'Número de Slots',
      TITLE: 'Solicitação de Slots',
      REQUESTED: 'Slots solicitados',
      APPLICANT: 'Solicitante',
      RECIPIENT: 'Destinatário',
      EXISTING_REQUEST_MESSAGE: 'Existem <strong>{{ quantidade }}</strong> solicitações associadas a esta negociação. Com um total de <strong>{{ total }}</strong> slots solicitadas.',
      EXISTING_REQUEST_MESSAGE_CONTRACT: 'Existem <strong>{{ quantidade }}</strong> solicitações associadas a esta contrato. Com um total de <strong>{{ total }}</strong> slots solicitadas.'
    },
    REJECT_MODAL: {
      TITLE: 'Rejeitar Slots',
      DESCRIPTION: 'Opcionalmente, você pode incluir os motivos da rejeição:',
      PLACEHOLDER: 'Digite os motivos...',
      REJECT: 'Rejeitar Slots'
    },
    WITHDRAW_MODAL: {
      TITLE: 'Deseja retirar este lote?',
      DESCRIPTION: 'Opcionalmente, você pode incluir os motivos dessa retirada. Para que <b>{{Company}}</b> possa vê-los:'
    },
    ADD_MODAL: {
      ADDRESS: 'Endereço',
      TITLE: 'Adicionar Slots',
      DESTINATION: 'Destino',
      DESTINATION_DETAIL: 'Planta / Porto / Terminal',
      DESTINATION_DETAIL_HELP: 'Inclua todos os dados necessários para identificar o destino.',
      DESTINATION_DETAIL_PLACEHOLDER: 'Digite os detalhes...',
      SLOT_IDS_HELP: 'Digite um código alfanumérico por linha',
      ENTER_DESTINATION: 'Digite um destino',
      DESTINATION_NOT_LISTED: 'Não consegue encontrar o destino?',
      RECEIVER_NOT_LISTED: 'Não consegue encontrar o Destinatário?'
    },
    DETAILS_MODAL: {
      TITLE: 'Detalhe do Slots',
      HOLDER: 'Titular C.P.',
      NUMBER: 'Número',
      CTG: 'Código de Rastreabilidade de Grãos',
      CTG_ABBR: 'C.R.G.',
      REQUEST_CTG: 'Solicitar C.R.G.',
      CHECK_STATUS: 'Verificar status',
      WITHDRAW_SLOTS: 'Opcionalmente, você pode compartilhar os motivos da retirada dessas <b>{{quantity}}</b> vagas:'
    },
    VIEWMODE: {
      COMPACT: 'Compacto',
      NORMAL: 'Normal'
    },
    REJECT_REQUEST_MODAL: {
      TITLE: 'Rejeitar solicitação de slots',
      DESCRIPTION: 'Tem certeza de que deseja rejeitar essa solicitação de slots?',
    },
    WITHDRAW_REQUEST_MODAL: {
      TITLE: 'Retirada da solicitação de slots',
      DESCRIPTION: 'Tem certeza de que deseja retirar essa solicitação de slots?',
      SUBMIT: '',
    },
    DELETE_REQUEST_MODAL: {
      TITLE: 'Tem certeza de que deseja excluir essa solicitação de cota?',
    },
    IMPORT: {
      BUTTON: 'Importar estados STOP',
      DESCRIPTION: 'Atualize o status STOP de suas cotas por meio de um arquivo Excel <code>(.xls, .xlsx)</code>, você pode fazer o download de um modelo de amostra clicando <a href="/assets/docs/format_slots.xlsx" target="_blank" download=""><u>aqui</u></a>.',
    }
  },
  BARTERS: {
    ADD_MANUAL: 'Carregamento manual',
    ADD_PRODUCT_HELP: '<b>Os campos abaixo são OPCIONAIS</b>. Basta preencher as informações que você considera relevantes.',
    ADD_PRODUCT: 'Adicionar produto',
    AMOUNT: 'Valor',
    BALANCE: 'Saldo',
    BARTER_PROPOSAL_ID: 'Proposta de Barter #{{id}}',
    BARTER_PROPOSAL: 'Proposta de Barter',
    BROKERS: 'Corretores',
    CREATE_AND_SEND: 'Criar e Enviar',
    CREATE: 'Criar Proposta',
    DUE_ABBR: 'Ven.',
    EDIT_PRODUCT: 'Editar produto',
    EXCLUDED_COMPANIES: 'As seguintes empresas são Excluídas por possuírem Propostas ativas: <b>{{companies}}</b>.',
    EXEMPT: 'Isento',
    INVOICE_NUMBER_HELP: 'Você pode especificar todas as faturas a serem canceladas com esta operação de barter, e ainda definir valores parciais.',
    MANAGERS: 'Gestores',
    MORE_DETAILS: 'Isso requer mais detalhes',
    NEW_PROPOSAL: 'Nova proposta de Barter',
    NO_CUSTOMER: '<b>Não há Clientes na sua Rede.</b> Para adicionar Empresas <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">clique aqui</a>.',
    NO_DISTRIBUTOR: '<b>Não há Distribuidores na sua Rede.</b> Para adicionar Empresas <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">clique aqui</a>.',
    NO_RECORDS: 'Nenhum Barter é registrada para esta Empresa.',
    NO_SUPPLIER: '<b>Não há Fornecedores na sua Rede.</b> Para adicionar Empresas <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">clique aqui</a>.',
    NO_TAX: 'Sem {{tax}}',
    NUMBER_PROPOSALS: '<b>{{quantity}}</b> proposta(s)',
    OFFLINE: 'Barter offline',
    OPTIONAL_PROPOSALS: 'Você pode adicionar um ou mais produtos à troca (especificando grão, qualidade, período de entrega, origem).',
    PARTIAL: 'Parcial',
    REPLICATE: 'Selecione uma Ordem de mercado para replicar suas condições:',
    SELF_MANAGED_HELP: 'Este Barter será gerenciado por <b>{{name}}</b>, sem intermediários.',
    SEND_NEW: 'Enviar nova proposta...',
    SEND: 'Enviar proposta',
    SUMMARY: 'Mostrando <span class="quantity">{{barters}}</span> Barter(s) e suas <span class="quantity">{{proposals}}</span> Proposta(s).',
    TO_BE_SETTLED: 'Quantidade a cancelar',
    TOTAL: 'Total faturado',
    ACCEPT_CONFIRM: {
      BODY: '<p>Ao aceitar os termos do Barter, a confirmação serão responsáveis por <b>{{company}}</b>.</p><div class="text-danger"><b>Importante:</b> Aceitação é um compromisso. Uma vez feita, NÃO PODE SER RETIRADA.</div>',
      TITLE: 'Você aceita os termos?'
    },
    REJECT_MODAL: {
      TITLE: 'Deseja rejeitar esta proposta de Barter?',
      BODY: 'Isso implica que você não está interessado em fazer o Barter. Esta ação não pode ser desfeita.'
    },
    REMOVE_MODAL: {
      TITLE: 'Deseja remover este produto da Proposta?',
      BODY: 'Se você quiser incluí-lo mais tarde, precisará adicioná-lo novamente.'
    },
    STATUS: {
      ACCEPTED_LONG: '<b>Proposta aceitada.</b> Os negócios estão confirmados.',
      ACCEPTED: 'Proposta aceitada',
      IN_PROGRESS_LONG: '<b>Proposta em negociação.</b> À espera das contrapartes.',
      IN_PROGRESS: 'Proposta em negociação',
      NEW_LONG: '<i class="material-symbols-rounded blinking">warning</i> <b>Nova proposta.</b> Revise e/ou complete as informações necessárias.',
      NEW: 'Nova proposta',
      RECEIVED_LONG: '<i class="material-symbols-rounded blinking">warning</i> <b>Proposta recebida.</b> Você tem que responder.',
      RECEIVED: 'Proposta recebida',
      REJECTED_LONG: '<b>Proposta rejeitada.</b>',
      REJECTED: 'Proposta rejeitada',
      SENT_LONG: '<b>Proposta enviada.</b> Esperando a contraparte responder.',
      SENT: 'Proposta enviada'
    },
    WITHDRAW_MODAL: {
      TITLE: 'Deseja retirar esta proposta de Barter?',
      BODY: 'Isso implica que você não está interessado em fazer o Barter. Esta ação não pode ser desfeita.'
    }
  },
  TRUCKS: {
    APPLIED: 'Aplicado',
    DESTINATION: 'Destino',
    DESTINATION_WEIGHT: 'Peso em destino',
    FEE: 'Taxa',
    FEE_REFERENCE: 'Taxa de referência',
    GROSS: 'Bruto',
    GROSS_KG: 'Bruto: {{quantity}} <small>Kgs.</small>',
    NET: 'Líquido',
    NET_KG: 'Líquido: {{quantity}} <small>Kgs.</small>',
    NO_DATA: 'Não há descargas.',
    ORIGIN_WEIGHT: 'Peso em origem',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Descarga(s)',
    TARE: 'Tara',
    TARE_KG: 'Tara: {{quantity}} <small>Kgs.</small>',
    TITLE: 'Descargas',
    TO_COVER: 'A cobrir',
    TRAILER: 'Trailer',
    TRANSPORT: 'Transporte',
    TRUCK: 'Caminhão',
    UNLOADED: 'Descarga',
    UNLOADS_NUMBER: 'em {{quantity}} descarga(s)',
    WASTAGE: 'Desperdício',
    WAYBILL: 'Carta de porte',
    ACTORS: {
      BUYING_BROKER: 'Corretor comprador',
      CARRIER: 'Transportadora',
      CARRIER_BROKER: 'Intermediário de frete',
      COMMERCIAL_SENDER: 'Remetente comercial',
      DESTINATION: 'Destino',
      DRIVER: 'Motorista',
      HOLDER: 'Titular',
      INTERMEDIARY: 'Intermediário',
      RECEIVER: 'Destinatário',
      REPRESENTATIVE: 'Representante/Entregar',
      SELLING_BROKER: 'Corretor vendedor',
      TERM_MARKET: 'Mercado a prazo'
    },
    CPE_ACTORS: {
      TITULAR_CARTA_DE_PORTE: 'Titular Carta De Frete Electrónica',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Produtor Remetente Comercial',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Venda primária ao remetente comercial',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Venda Secundária do Remetente Comercial',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Venda Secundária do Remetente Comercial 2',
      MERCADO_A_TERMINO: 'Mercado a prazo',
      CORREDOR_VENTA_PRIMARIA: 'Corretor Vendar Primária',
      CORREDOR_VENTA_SECUNDARIA: 'Corretor Venda Secundária',
      REPRESENTANTE_ENTREGADOR: 'Representante/Entregar',
      REPRESENTANTE_RECIBIDOR: 'Destinatário-Representante',
      DESTINATARIO: 'Destinatário',
      DESTINO: 'Destino',
      EMPRESA_TRANSPORTISTA: 'Transportadora',
      FLETE_PAGADOR: 'Pagador de frete',
      CHOFER: 'Motorista',
      INTERMEDIARIO_DE_FLETE: 'Intermediário de frete'
    },
    WASTAGE_ITEMS: {
      DAMAGED: 'Grãos danificados',
      FOREIGN_BODIES: 'Corpos estranhos',
      HUMIDITY: 'Umidade',
      OTHER: 'Outros',
      TOTAL: 'Desperdício totais',
      WEED: 'Ervas daninhas'
    }
  },
  INVOICES: {
    ADD_COVERAGE: 'Incorporar outros conceitos de cobertura:',
    BALANCE: 'Saldo',
    BILLING_IN: 'Faturamento em {{unit}}',
    CANCEL_BARTER: 'Cancelar com um Barter?...',
    CONCEPT_ABBR: 'Con.',
    CONCEPT: 'Conceito',
    COVER_BARTER: 'Cubra com um Barter',
    COVERAGE: 'Cobertura',
    COVERED_TOTAL: 'Coberto',
    COVERED: 'Coberta',
    CREATE: 'Carregar nova fatura',
    DESCRIPTION: 'Descrição',
    EDIT_COVERAGE: 'Editar cobertura',
    IMPORT: 'Importar faturas',
    INVOICES_IN: 'Faturas em {{unit}}',
    INVOICES_NUMBER: 'em {{quantity}} fatura(s)',
    ISSUED_TO: 'Emitido para',
    NET: 'Neto',
    NO_DATA: 'Nenhuma fatura é registrada.',
    NOT_LISTED: 'Não consegue encontrar a fatura?',
    PARTIALLY_COVERED: 'Parcialmente coberto',
    PERCENTAGE_ABBR: 'Per.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Fatura(s)',
    TAXED: 'Taxado',
    TITLE: 'Faturas',
    IMPORTED_BY: 'Importado por'
  },
  FIXATIONS: {
    DELETE: 'Tem certeza de que deseja remover esta fixação?',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Fixações',
    TITLE: 'Fixações',
    REQUEST_MODAL: {
      TITLE: 'Enviar solicitação de Fixação',
      MANAGE: 'Gerenciar solicitação',
      REQUEST_FIXATIONS: 'Solicitações de Fixação',
      REQUEST: 'Solicitar',
      REPLY: 'Responder',
      OBSERVATIONS_HELP: 'Os destinatários desta solicitação receberão essas mesmas observações.',
      LAST_OBSERBATION: 'Última observação:',
      ALERT_LIMIT_OF_VOLUME: 'Excede o número de toneladas que podem ser solicitadas para este contrato.'
    },
    REQUEST_STATUS: {
      WAITING_RECEIPT: 'Aguardando resposta',
      WAITING_COMPANY: 'Aguardando resposta',
      WAITING_COUNTERPART: 'Em espera',
      ACCEPTED: 'Aceito',
      REJECTED: 'Rejeitado',
    }
  },
  WAYBILLS: {
    NO_DATA: 'Não há Cartas de Porte para mostrar.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Carta(s) de porte',
    TITLE: 'Cartas de Porte',
    ACTORS: {
      CHOFER: 'Chofer',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      DESTINATARIO: 'Destinatario',
      DESTINO: 'Destino',
      EMPRESA_TRANSPORTISTA: 'Empresa Transportista',
      FLETE_PAGADOR: 'Flete pagador',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de flete',
      MERCADO_A_TERMINO: 'Mercado a Término',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Rte. Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Rte. Comercial Venta Secundaria 2',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Rte. Comercial Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Representante entregador',
      REPRESENTANTE_RECIBIDOR: 'Representante recibidor',
      TITULAR_CARTA_DE_PORTE: 'Titular Carta de Porte',
      OBSERVACIONES: 'Observações'
    }
  },
  CPE: {
    NO_DATA: 'Não há Cartas de Porte Electronica para mostrar.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Carta(s) de porte',
    TITLE: 'Cartas de Porte Electronica',
    ACTORS: {
      CHOFER: 'Chofer',
      CORREDOR_VENTA_PRIMARIA: 'Corredor Venta Primaria',
      CORREDOR_VENTA_SECUNDARIA: 'Corredor Venta Secundaria',
      DESTINATARIO: 'Destinatario',
      DESTINO: 'Destino',
      EMPRESA_TRANSPORTISTA: 'Empresa Transportista',
      FLETE_PAGADOR: 'Flete pagador',
      INTERMEDIARIO_DE_FLETE: 'Intermediario de flete',
      MERCADO_A_TERMINO: 'Mercado a Término',
      REMITENTE_COMERCIAL_PRODUCTOR: 'Remitente Comercial Productor',
      REMITENTE_COMERCIAL_VENTA_PRIMARIA: 'Rte. Comercial Venta Primaria',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2: 'Rte. Comercial Venta Secundaria 2',
      REMITENTE_COMERCIAL_VENTA_SECUNDARIA: 'Rte. Comercial Venta Secundaria',
      REPRESENTANTE_ENTREGADOR: 'Representante entregador',
      REPRESENTANTE_RECIBIDOR: 'Representante recibidor',
      TITULAR_CARTA_DE_PORTE: 'Titular Carta de Porte Electronica'
    },
    TRANSPORTE: {
      TITLE: 'Datos del Transporte',
      TRANSPORTISTA_CUIT: 'Transportista CUIT',
      CHOFER_CUIT: 'Chofer CUIT',
      DOMINIO: 'Dominio',
      FECHA_PARTIDA: 'Fecha de Partida',
      KMS_RECORRER: 'Kms a Recorrer',
      TARIFA_REF: 'Tarifa Referencia',
      TARIFA: 'Tarifa',
    },
    ORIGIN: {
      PLANTA: 'Planta'
    }
  },
  LANDING: {
    MAIN: 'Comece a trabalhar de forma ágil e segura, com rastreabilidade e sustentabilidade.',
    SECONDARY: 'Bem-vindo à plataforma que<br>facilita o acesso ao crédito.',
    TAGLINE: 'Juntos na <span class="brand-color">Evolução</span>',
    COMMUNITY: 'Comunidade',
    MANAGEMENT: 'Gestão',
    FUNDING: 'Financiamento',
    food: {
      HEADLINE: '<b>Bem-vindo</b><br>ao mercado atacadista digital de compra e venda de alimentos!',
      FEATURE_01: 'Alcance mais clientes',
      FEATURE_02: 'Opere quando e onde quiser',
      FEATURE_03: 'Fácil e prático'
    },
    ar: {
      HEADLINE: '<b>Bem-vindo</b><br>à plataforma que permite comercializar grãos e subprodutos.',
      FEATURE_01: 'Opere quando e onde quiser',
      FEATURE_02: 'Integramos a toda la cadena comercial',
      FEATURE_03: 'Fácil e segura'
    },
    br: {
      HEADLINE: '<b>Bem-vindo</b><br>à plataforma que permite comercializar grãos e subprodutos.',
      FEATURE_01: 'Opere quando e onde quiser',
      FEATURE_02: 'Integramos a toda la cadena comercial',
      FEATURE_03: 'Fácil e segura'
    },
    pt: {
      HEADLINE: '<b>Bem-vindo</b><br>à plataforma que permite comercializar grãos e subprodutos.',
      FEATURE_01: 'Opere quando e onde quiser',
      FEATURE_02: 'Integramos a toda la cadena comercial',
      FEATURE_03: 'Fácil e segura'
    }
  },
  IMPORTER: {
    ACCEPTED_FORMATS: 'Os formatos suportados são:',
    ADD_CONTRACT: 'Adicionar Contrato',
    CONTRACT_NUMBER_HELP: 'Identificador único de Contrato.',
    CONTRACT_NUMBER: 'Contrato Nº',
    DETAILS: 'Detalhes',
    DROP_HELP: 'Você também pode soltar arquivos nessa área para fazer upload de novo conteúdo.',
    EDITING_CONTRACT: 'Editando Contrato: {{id}}',
    ERROR_INDEX: '(Erro: {{index}})',
    ERROR: 'Erro',
    ERRORS_SUMMARY: 'Não foi possível importar o(s) arquivo(s)! <span class="quantity">{{files}}</span> erro(s) em <span class="quantity">{{errors}}</span> arquivo(s).',
    ERRORS: '({{errors}} erro(s))',
    HIDE_FILE_DETAILS: 'Ocultar detalhes do arquivo',
    INPUT: 'Entrada',
    INVALID_FILETYPE: "O tipo de arquivo enviado é inválido.",
    LINE: 'Linha',
    NO_COMPANIES: '<b>Não há Empresas em sua rede.</b> <a class="alert-link" href="/company/{{companyId}}/network?order_by=-verification_date">Clique aqui para adicionar</a>.',
    NOT_INPUT_AVAILABLE: "Não disponível",
    SELECT_FILES: 'Selecione os arquivos que você deseja importar:',
    SHOW_ALL_FIELDS: 'Mostrar todos os campos',
    SHOW_ERROR_FIELDS: 'Mostrar campos com erros',
    SHOW_FILE_DETAILS: 'Mostrar detalhes do arquivo',
    SHOW_MORE_ERRORS: 'Mostrar mais erros para este arquivo...',
    SOURCE_FILE: 'Arquivo fonte',
    STATUSES: 'Status das importações',
    TITLE: '<span class="material-symbols-rounded">cloud_upload</span> Importar...',
    TRY_AGAIN: 'Tente novamente...',
    UPDATED: 'Atualizado',
    VALUE: 'Valor',
    WARNING: {
      CONTRACT_APPLIED_VOLUME_GT_110: 'O volume aplicado é superior a 110% do total',
      CONTRACT_FIXED_VOLUME_GT_110: 'O volume fixado é superior a 110% do total',
      CONTRACT_INVOICED_VOLUME_GT_APPLIED: 'O volume liquidado é maior do que o aplicado',
      CONTRACT_INVOICED_VOLUME_GT_FIXED: 'O volume liquidado é maior que o volume fixo',
      CONTRACT_PRICE_LT_100_USD: 'O preço é inferior a USD 100',
      CONTRACT_PRICE_LT_10000_ARS: 'O preço é inferior a $ 10.000',
      CONTRACT_VOLUME_0: 'O volume é menor ou igual a 0',
      FIXATION_PRICE_LT_100_USD: 'Fixo por menos de USD 100',
      FIXATION_PRICE_LT_10000_ARS: 'Fixo por menos de  $ 10.000',
      FIXATION_VOLUME_LT_0T: 'O volume definido é menor ou igual a 0',
      NO: 'Sem inconsistências',
      TITLE: 'Potenciais inconsistências',
      UNLOAD_APPLIED_GT_NET: 'Volume aplicado maior que o peso líquido',
      YES: 'Com inconsistências'
    },
    SOURCE: {
      '1': 'API',
      '2': 'Arquivo',
      '3': 'Manual',
      '4': 'AFIP WS',
      '5': 'Arquivo'
    },
    STATUS: {
      '1': '',
      '2': 'Erro de formato',
      '3': 'Na fila para processamento',
      '4': 'Em processamento...',
      '5': '',
      '6': 'Tentando novamente...',
      '7': 'Error',
      '8': 'Formato validado'
    }
  },
  SIGNATURES: {
    ACTION: 'Açao',
    ADD_FILES: 'Adicionar documentos ao Envelope',
    ADD_MORE_SIGNERS: 'Você precisa adicionar Signatários?...',
    ADD_SIGNER: 'Adicionar Signatário',
    CREATE_ENVELOPE: 'Criar Envelope',
    DEFINE_SIGNERS: 'Definir signatários',
    DEFINE_SIGNERS_CONFIRM: 'Uma vez que os Signatários do Envelope tenham sido definidos, você não poderá modificá-los novamente.',
    DEFINE_SIGNERS_HELP: 'Selecione na lista de Signatários de sua Empresa quem deve assinar este Envelope.',
    DOCUMENTS: 'Documentos no Envelope',
    EDIT_SIGNER: 'Editar Signatário',
    ENVELOPE: 'Envelope #{{id}}',
    EXPIRATION_DATE: 'Data de validade',
    EXPIRES: 'Expira',
    HISTORY: 'Histórico',
    LAST_MODIFIED: 'Última alteração',
    LINKED_TO: 'Ligado a',
    MESSAGE: 'Mensagem',
    MESSAGE_PLACEHOLDER: 'Escreva uma mensagem...',
    NOT_SIGNED: 'Ainda não assinou',
    NO_DATA: 'Não há Envelopes para mostrar.',
    NO_SIGNERS: 'Sua Empresa não tem Signatários carregados.',
    PARTIALLY_SIGNED: 'Parcialmente assinado',
    RECIPIENTS_ADD_MY_COMPANY: 'Adicionar minha Empresa',
    RECIPIENTS_COMPANIES: 'Empresas que assinam o Envelope',
    RECIPIENTS_HELP: 'Cada Empresa irá definir quem está autorizado e competente para assinar este Envelope.',
    REQUIRED_SIGNERS: 'Quem deve assinar?',
    SEEN: 'Visto',
    SENDER: 'Remetente',
    SIGN: 'Assinar',
    SIGNED: 'Assinado',
    SIGNERS: 'Assinantes',
    SIGNERS_DESCRIPTION: 'Gerenciar as informações das pessoas autorizadas a assinar em nome da Empresa.',
    SIGNER_AUTHORIZATION: 'Poder do signatário',
    SIGNER_AUTHORIZATION_HELP: 'Documento que comprove o poder de assinatura desta pessoa em nome da Empresa. Este documento estará disponível para validação pelas contrapartes.',
    SIGNER_AUTHORIZATION_VIEW: 'Veja poder',
    SIGNS_PENDING: '{{quantity}} assinaturas pendentes.',
    SUBJECT: 'Assunto',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Envelope(s) de <span class="quantity">{{total}}</span>',
    TIMESTAMP: 'Hora',
    USER: 'Usuário',
    RELATED: {
      PLACEHOLDER: 'Crie um envelope com a documentação relacionada para assinar.',
      TITLE: 'Envelopes relacionados'
    },
    STATUS: {
      CANCELED: 'Cancelado',
      COMPLETED: 'Concluído',
      DEFINE_SIGNERS: 'Sua Empresa não definiu Signatários',
      NOT_SENT: 'Não enviado',
      PENDING: 'Assinaturas pendentes',
      SENT: 'Este Envelope já foi enviado. Os Signatários foram notificados por e-mail.',
      WAITING_SIGNERS: 'Esperando que os signatários sejam definidos'
    }
  },
  ATTACHMENTS: {
    ERROR: 'Este upload falhou.',
    HEADER: 'Anexos',
    PLACEHOLDER: 'Arraste e solte os arquivos aqui para anexá-los.',
    TITLE: 'Excluir arquivo',
    MODAL: {
      TITLE: 'Tem certeza de que deseja excluir este arquivo?'
    },
    PRIVATE: {
      TITLE: 'Quem deve ser capaz de ver esses arquivos?',
      PUBLIC: 'Visível para todos',
      PUBLIC_HELP: '<h5>Visível para todos</h5>Qualquer usuário com acesso a este conteúdo poderá acessar o arquivo.',
      PRIVATE: 'Só para minha Empresa',
      PRIVATE_HELP: '<h5>Só para minha Empresa</h5>O arquivo estará disponível apenas para <b>Usuários de sua Empresa</b>.'
    }
  },
  CONTRACTS: {
    APPLICATIONS: 'Aplicações',
    BUSINESS_DAYS: 'Em <b>{{days}}</b> dias úteis',
    CALENDAR_DAYS: 'Em <b>{{days}}</b> dias corridos',
    COMPANY_NOT_FOUND: '<b>Erro!</b> Sua Empresa deve intervir no Negócio a ser gerado.',
    CONFIRM_PARTICIPANTS: 'Confirme os participantes.',
    FIXATION_DEADLINE: 'Fixar até:',
    FIXATIONS_NUMBER: 'em {{quantity}} Fixações',
    FIXATIONS: 'Fixações',
    FIXED: 'Fixado',
    GENERATE_TRADE: 'Gerar um Negócio relacionado',
    INFORMANT: 'Informante',
    INVOICING: 'Faturamento',
    NO_DATA: 'Nenhum contrato é registrado.',
    NO_FIXATION_DATA: 'Nenhuma Fixação é registrada',
    NOT_AVAILABLE: 'O Contrato não existe ou você não tem permissão para visualizá-lo.',
    PARTIAL_PAYMENT: 'Pagamento parcial',
    PREPARING: 'Preparando Contrato...',
    SLATE_PRICE_HELP: 'Somente se for diferente do preço de fixação.',
    SLATE_PRICE: 'Preço do pizarra',
    SLATE: 'Pizarra',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Contrato(s)',
    TOLERANCE: 'Tolerância',
    WEIGHTED_AVERAGE: 'Média ponderada',
    DIFF: {
      'delivery.date_from': 'Período (De)',
      'delivery.date_to': 'Período (até)',
      'price.type': 'Tipo de preço',
      'price.unit': 'Moeda',
      'price.value': 'Preço',
      'quantity.type': 'Tipo de volume',
      'quantity.unit': 'Unidade de volume',
      'quantity.value': 'Volume',
      NO: 'Sem diferenças',
      TITLE: 'Diferenças Contrato/Negócio:',
      YES: 'Com diferenças'
    },
    IMPORT: {
      BUTTON: 'Importação contratos',
      DESCRIPTION: 'Faça o upload de seus contratos por meio de um arquivo do Excel <code>(.xls, .xlsx)</code>'
    }
  },
  TRACKER: {
    BATCH_ID: 'ID do Lote',
    EVENT: 'Evento',
    GALLERY: 'Galeria de mídia',
    INFORMATION: 'Informação',
    RELATED_EVENTS: 'Eventos relacionados',
    EVENTS: {
      APPLICATION: 'Aplicação agroquímica',
      HARVEST: 'Safra',
      IRRIGATION: 'Irrigação',
      LOAD: 'Carga',
      QUALITY_CONTROL: 'Controle de qualidade',
      SOWING: 'Semeadura',
      STORAGE: 'Armazenamento',
      SUPPLIES_SALE: 'Venda de suprimentos',
      TRADE: 'Operação de compra e venda',
      TRANSPORTATION: 'Transporte',
      UNLOAD: 'Descarga',
      WEED_CONTROL: 'Controle de ervas daninhas'
    }
  },
  FUTURES: {
    ADD: 'Adicionar operação',
    CONDITION: 'Condição',
    EXCHANGE_RATE: 'Taxa de câmbio',
    EXCHANGE_RATE_HELP: 'Taxa de câmbio (Banco Nación, vendedor) do dia anterior para relatar operações (<a href="http://biblioteca.afip.gob.ar/dcp/REAG01003421_2012_12_20" target="_blank">RG 3421</a>).',
    TITLE: 'Futuros e Opções',
    NO_DATA: 'Nenhuma operação registrada.',
    NO_DELIVERY: 'Sem entrega',
    OPERATION_DATE: 'Data de operação',
    OPERATOR: 'Operador',
    POSITION: 'Posição',
    POSITION_PLACEHOLDER: 'mm/aaaa',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> operações',
    VOLUME: 'Volume',
    TYPES: {
      1: 'Futuro',
      2: 'Opção'
    }
  },
  LINKER: {
    CONTRACTS: 'Contrato(s):',
    DESCRIPTION: 'Essa ferramenta permite vincular <b>Contratos</b> com seus respectivos <b>Negócios</b> e vice-versa.',
    DESCRIPTION_NEGOTIATION: 'Esta ferramenta permite que você conecte <b>Negócios</b> entre si.',
    DESCRIPTION_CONTRACT: 'Esta ferramenta permite que você conecte <b>Contratos</b> entre si.',
    DESCRIPTION_BATCH: 'Essa ferramenta permite vincular <b>Slots</b> com seus respectivos <b>Negócios</b> e vice-versa.',
    DESCRIPTION_BATCH_CONTRACT: 'Essa ferramenta permite vincular <b>Slots</b> com seus respectivos <b>Contrato</b>',
    LINK: 'Vincular Contrato e Negócio',
    LINK_NEGOTIATION: 'Vincular Negócios',
    LINK_CONTRACTS: 'Vincular Contratos',
    LINK_BATCH: 'Vincular Slots ao Negócio',
    LINK_BATCH_CONTRACT: 'Vincular Slots ao Contrato',
    LINK_BATCH_BUTTON: 'Vincular ao Negócio',
    LINK_BATCH_BUTTON_CONTRACT: 'Vincular ao Contrato',
    NO_CONTRACTS: 'Nenhum <b>Contrato</b> encontrado para vincular.',
    NO_TRADES: 'Nenhum <b>Negócio</b> encontrado para vincular.',
    SELECT_CONTRACT: 'Selecione o <b>Contrato</b> na lista que corresponde a este <b>Negócio</b>:',
    SELECT_MIRROR_CONTRACT: 'Selecione o <b>Contrato</b> espelho na lista que corresponde a este <b>Contrato</b>:',
    SELECT_TRADE: 'Selecione o <b>Negócio</b> na lista que corresponde a este <b>Contrato</b>:',
    SELECT_TRADE_NEGOTIATION: 'Selecione o <b>Negócio</b> na lista que deseja vincular:',
    SELECT_TRADE_BATCH: 'Selecione o <b>Negócio</b> na lista que corresponde a estos <b>Slots</b>.<br><small>Estes são Negócios relacionados a <b>{{counterpart}}</b>.</small>',
    SELECTED_CONTRACT: 'Contrato selecionado:',
    SELECTED_TRADE: 'Negócio selecionado:',
    TITLE: 'Assistente de vinculação',
    TRADES: 'Negócio(s):',
    UNLINK: 'Desvincular',
    UNLINK_TITLE: 'Tem certeza de que deseja desvinculá-los?',
    UNLINK_BODY: 'Negócio: <samp>#{{negotiation}}</samp> e Contrato: <samp>{{contract}}</samp> serão desvinculados. Você pode vinculá-los novamente mais tarde.',
    UNLINK_BODY_BATCH: 'O Negócio: <samp>#{{negotiation}}</samp> será desvinculado dessos Slots. Você pode vinculá-los novamente mais tarde.',
    UNLINK_BODY_BATCH_CONTRACT: 'O Contrato: <samp>#{{contrato}}</samp> será desvinculado dessos Slots. Você pode vinculá-los novamente mais tarde.',
    YES_UNLINK: 'Sim, desvincular'
  },
  FINTECH: {
    ADD_FUNDER: 'Adicionar Financiador',
    ADD_SIGNER: 'Adicionar signatário...',
    ADMIN: 'Administração',
    AFFIDAVIT: '<b>Importante:</b> Os dados, informações e/ou documentação carregados pelo usuário para os fins deste aplicativo constituem uma declaração juramentada, o requerente respondendo pela validade e exatidão de todos os seus termos.',
    AMOUNT_PENDING: 'A ser definido',
    AMOUNT_REQUESTED_ABBR: 'Linha solicitada',
    AMOUNT_REQUESTED: 'Linha de crédito solicitada',
    APPLICANT: 'Solicitante',
    APPLICATION_APPLICANT: 'Solicitação de crédito > {{applicant}}',
    APPLICATION_ID: 'Solicitação de crédito #{{id}}',
    APPLICATION_PRODUCT_HELP: 'Quaisquer alterações a esses valores afetarão apenas o Produto desta Solicitação específica.',
    APPLICATION_PRODUCT: 'Condições do Produto para esta Solicitação de crédito:',
    APPLICATION_START: 'Iniciar Solicitação(ões)',
    APPLICATIONS: 'Solicitações',
    APPROVED_LOC: 'Linha de crédito aprovada',
    ASSIGNMENT: 'Cessão',
    BUYER_HELP: 'Selecione o Comprador do Contrato.',
    CALCULATED_EXCHANGE: 'Taxa de câmbio calculada: {{rate}}',
    CALCULATION_AS: 'Cálculo a partir de {{date}}',
    COMMITTEE: 'Comitê',
    CONDITIONS: 'Condições',
    CONTRACT: 'Contrato forward',
    CREDIT_GRANTED: 'Crédito concedido',
    CREDIT_LINE_MAX: 'Avaliação geral',
    CREDIT_LINE: 'Linha de crédito',
    CREDIT_LINES_SCHEME_EDIT: 'Editar esquema...',
    CREDIT_LINES_SCHEME: 'Esquema de linha de crédito',
    CREDIT_LINES_UPLOAD: 'Para definir linhas de crédito, arraste um XLS com uma coluna para {{label}} seguida de outra com o valor da linha correspondente.',
    CREDIT_LINES: 'Linhas de crédito',
    DATE_FROM: 'De',
    DATE_TO: 'Até',
    DELIVERY_PERIOD_HELP: 'De acordo com o contrato forward apresentado em garantia.',
    DOA_APPROVERS_BUTTON: 'Notifique o primeiro aprovador',
    DOA_APPROVERS_HELP: 'Arraste os Usuários para definir os Aprovadores e sua ordem de acordo com a Delegação de autorização (DOA).',
    DOA_APPROVERS_NO_DATA: 'Não há usuários definidos na <a href="/company/{{companyId}}/team">equipe de sua Empresa</a>.',
    DOA_APPROVERS_NO_USERS: 'Usuários ausentes?',
    DOA_APPROVERS_PLACEHOLDER: 'Arraste e solte aqui...',
    DOA_APPROVERS: 'Aprovadores',
    DOA_INFO_HELP: 'Essas informações estarão visíveis apenas para Aprovadores.',
    DOA_INFO: 'Informação adicional',
    DOA_NEXT_APPROVER: 'Próximo Aprovador',
    DOA_ORDERLY_DELEGATION: 'Delegação ordenada',
    DOA_PENDING_APPROVER: 'Aprovador pendente',
    DOA_PREVIOUS_APPROVER: 'Aprovador anterior',
    DOA_REVIEW: 'Revisão',
    DOA_YOUR_VOTE: 'Seu voto',
    DOCUMENTATION: 'Documentação',
    DUPLICATES: 'Duplicatas ignoradas',
    ECHEQ: 'eCheq',
    EDIT_FUNDER: 'Editar Financiador',
    ENGINE_CREDIT: 'Motor de crédito:',
    ENGINE_SCORING: 'Scoring do motor: <b>{{scoring}}</b>',
    ESTIMATES_HELP: 'Esses valores são estimados. O cálculo final dos juros, impostos e despesas será feito com base na data em que os recursos forem efetivamente creditados para cancelamento do crédito.',
    ESTIMATES: 'Estimativas',
    EXPENSES: 'Despesas',
    EXPORT_APPLICATIONS: 'Exportar solicitações',
    EXPORT_BODY: 'A exportação dos aplicativos selecionados <b>levará alguns minutos</b>. Quando estiver pronta, a plataforma enviará um e-mail para você.',
    FUNDER: 'Financiador',
    FUNDERS: 'Financiadores',
    GRANT: 'Conceder',
    GRANTING: 'Concessão',
    INDICATOR: 'Indicador',
    INTEREST_RATE_DETAILS: 'Explicação da taxa',
    INTEREST_RATE_HELP: 'Taxa efetiva anual, incluindo juros nominais, despesas e comissões.',
    INTEREST_RATE_LABEL: 'Etiqueta de la tasa',
    INTEREST_RATE: 'Taxa de juros',
    INTEREST: 'Interesses',
    INVALIDS: 'Inválidos',
    INVOICE: 'Fatura de suprimento/serviço',
    LOANS: 'Créditos',
    MANAGED_BY: 'Gerenciado por:',
    MANAGER: 'Representante',
    MANAGMENT: 'Gestão Fintech',
    MANUAL_SCORING: 'Scoring manual',
    NEW_SCREENING: 'Nova Triagem',
    NO_DATA: 'Nenhuma solicitação é registrada.',
    NO_FUNDERS_HELP: 'Você está interessado em oferecer produtos financeiros?...',
    NO_FUNDERS: 'Sua empresa não tem Financiadores associados.',
    NOTICE_HELP: 'Somente quando o contrato não inclui uma cláusula de cessão.',
    NOTICE_LABEL: 'Solicitar comprovante de notificação de atribuição',
    NOTICE: 'Notificação de atribuição',
    OBSERVATIONS_HELP: 'Estas observações serão visíveis aos usuários.',
    OBSERVATIONS_PRIVATE: 'Estas não serão visíveis para outras Empresas.',
    ONLY_FUNDABLES: 'Apenas financiável',
    PAYMENT_FREQUENCY: 'Frequência de pagamento',
    // PENDING_PRODUCTS: '<b>{{quantity}}</b> produto(s) pendente(s)',
    PENDING_REVIEW: 'Revisão pendente',
    POLICY_INVOICE_HELP: 'Você pode fazer upload de mais de uma fatura, <b>lembre-se de fazer upload de todas</b> as que você está financiando.',
    POLICY_INVOICE: 'Nota fiscal de apólice',
    PRE_APPROVED: 'Pré-aprovado',
    PROCESSED_DATE: 'Processado: {{date}}',
    PROCESSED: 'Itens deste lote: {{quantity}}',
    PRODUCT_CURRENCY: 'Moeda do produto',
    PRODUCT_DETAILS: 'Detalhes do produto de {{company}}',
    PRODUCT_HELP: 'Você pode selecionar mais de um.',
    QUALIFICATION_ID: 'Qualificação #{{id}}',
    QUALIFICATION_START: 'Começar Qualificação',
    QUALIFICATION: 'Qualificação',
    QUALIFICATIONS: 'Qualificações',
    QUALIFIED: 'Qualificado',
    QUALIFY: 'Qualificar',
    REACTIVATE_BODY: 'A reativação desta Solicitação reinicia o processo criando uma Solicitação relacionada que herda todas as informações nela capturadas. Tem certeza?.',
    REACTIVATE_CHILD: '<b>Revisões</b>:',
    REACTIVATE_PARENT: 'Esta é uma <b>revisão</b> de',
    REACTIVATE: 'Reativar a solicitação',
    RECEIPT: 'Recibo de transferência bancária',
    REGISTERED: 'Registrado',
    REPORT_BODY: 'A geração do relatório para as Solicitações selecionadas <b>levará alguns minutos</b>. Quando estiver pronto, a plataforma enviará um e-mail para você.',
    REPORT_FUNDERS_ONLY: 'Esta funcionalidade está disponível apenas para Financiadores.',
    REQUESTED_BY: 'Requerido por:',
    REQUESTED: 'Solicitado',
    RETRY_FORCED_HELP: '<p>Será feita uma tentativa de reprocessar esta Qualificação.</p>Se for encontrado um motivo de rejeição, a Solicitação será forçada a <b>revisão manual</b>.',
    RETRY_HELP: 'Será feita uma tentativa de reprocessar esta Qualificação.',
    REVIEWED_BY: 'Revisado por:',
    SCORING_APPROVED: 'Qualificação aprovada',
    SCORING_COMMENTS: 'Comentários do Scoring',
    SCORING_DATA: 'Dados de Scoring',
    SCORING_IN_PROGESS: 'Em progresso',
    SCORING_REJECTED: 'Qualificação rejeitada',
    SCORING_WITHDRAWN: 'Qualificação retirada',
    SCORING: 'Scoring',
    SCREENING_BATCHES: 'Lotes',
    SCREENING_DESCRIPTION: 'Nosso processo de Screening nos permite <b>determinar se uma Empresa atende aos requisitos mínimos</b> para ser uma potencial candidata a produtos financeiros.',
    SCREENING_FISCAL_ID: 'Números de {{fiscal_id}}',
    SCREENING_HELP: '<b>O resultado é no momento</b> e o único dado necessário é o número {{fiscal_id}}. Você pode realizar um Screening de várias empresas simultaneamente.',
    SCREENING_INVALID: 'Insira pelo menos um {{fiscal_id}} válido.',
    SCREENING_MAX: 'Quantidade máxima permitida por lote: {{max}}',
    SCREENING_SELECT: 'Escolha um lote de Screening para ver os resultados...',
    SCREENING: 'Screening',
    SEARCH_ID: 'Pesquisar por {{id}}...',
    SIGNERS_HELP: 'Selecione os signatários autorizados de <b>{{company}}</b>.',
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> solicitações de <span class="quantity">{{total}}</span>',
    TAXES: 'Impostos',
    UNIQUES: 'Únicos para processar',
    VALID_UNTIL: 'Válido até:',
    VALIDATE: 'Validar',
    VAT: 'I.V.A.',
    VIEW_APPLICATION: 'Ver solicitação',
    WITHDRAWN_BY: 'Retirada por:',
    WITHDRAWN_QUALIFICATION: 'Após a retirada do Qualificação, ele não será mais visível para o Gestor. É possível restaurá-lo ao seu estado original mais tarde.',
    WITHDRAWN_REASON_HELP: 'Os motivos serão visíveis para todas as partes envolvidas.',
    WITHDRAWN_REASON: 'Motivos',
    ADDITIONALS: {
      HELP: 'Esta calculadora é uma simulação orientativa.',
      TITLE: 'Estimativa de adicionais'
    },
    SCORING_XLS_DATA: {
      CTA: 'Você deseja aplicar esses dados?',
      DESCRIPTION: 'Detectamos as seguintes informações em um arquivo anexado:',
      TITLE: 'Dados encontrados'
    },
    QUALIFICATION_STATUS: {
      0: 'Pendente',
      1: 'Em processamento',
      2: 'Aprovada',
      3: 'Erro',
      4: 'Em revisão',
      5: 'Rejeitada',
      7: 'Recuperando fontes'
    },
    STEPS: {
      '1': 'Etapa 1',
      '2': 'Etapa 2',
      '3': 'Etapa 3',
      '4': 'Etapa 4',
      '5': 'Etapa 5'
    },
    APPLY: {
      ADDITIONAL_ACTIVITIES_VOLUME: 'Receita líquida anual aproximada dessas atividades.',
      ADDITIONAL_ACTIVITIES: 'Atividades complementares',
      ALL_SET: 'Tudo pronto! Nenhuma informação adicional é necessária para continuar.',
      APPLICANT_RECIPIENT_TOGGLE: 'Especifique outra empresa.',
      APPLICANT_RECIPIENT: 'O destinatário dos fundos será o <b>Solicitante</b>.',
      AUTHORIZATION_REQUIRED: 'É necessária a <b>validação da identidade</b> do Requerente ou Representante Legal.',
      CIEC_REQUIRED: 'Para consultar os relatórios de crédito, é necessário ter um <b>chave CIEC válido</b> do Requerente.',
      CROPS_HELP: 'Insira as informações do seu plano produtivo para a Campanha atual.',
      CROPS: 'Cultivos',
      FILTERS: 'Filtros:',
      FISCAL_ID_HELP: '{{fiscal_id}} do Requerente (sujeito de crédito para se qualificar).',
      NEW: 'Solicite crédito',
      NO_PRODUCTS: 'Infelizmente, não temos produtos financeiros disponíveis para esta seleção no momento.',
      PREVIOUS_CROPS_LOADED: 'Um layout produtivo de <b class="text-uppercase">{{date}}</b> foi pré-carregado, você pode revisá-lo e editá-lo.',
      PREVIOUS_CROPS_OPTION: '<span class="material-symbols-rounded">agriculture</span>&nbsp;&nbsp;<span class="text-uppercase">{{date}}</span> <span class="text-muted">({{fields}} campos)</span>',
      PREVIOUS_CROPS: 'Layout produtivos anteriores foram encontrados',
      REQUEST_AUTHORIZATION: 'Solicitar validação',
      REQUEST_CIEC_HELP: 'A chave CIEC (chave de identificação eletrônica confidencial) deve ser fornecida pela <b>Pessoa física</b> ou <b>Representante</b> da Empresa.',
      REQUEST_CIEC_PROVIDER: 'Autorização e consentimento fornecidos por:',
      REQUEST_CIEC: 'Solicitar chave CIEC',
      SUPPLIER_HELP_TOGGLE: 'Clique aqui se o destinatário dos fundos for o próprio Solicitante.',
      SUPPLIER_HELP: 'Selecione a Empresa que fornece os suprimentos ou serviços que receberá os recursos.',
      TITLE: 'Solicitação de crédito'
    },
    FIELDS_TABLE: {
      ADD_CROP: 'Adicionar produto',
      ADD: 'Adicionar campo...',
      CROP: 'Produto',
      FIELDS: 'Campos',
      FILE_FISCALID_NOT_FOUND: 'O <b>CUIT</b> do Requerente não foi encontrado no documento carregado.',
      FILE_HELP: 'Para adicionar campos, <b>arraste e solte</b> alguns dos formatos de arquivo suportados aqui.',
      FILE_WARNING_BUTTON: 'Valide a localização e características de cada um',
      FILE_WARNING: 'Os seguintes dados foram identificados no arquivo fornecido.',
      HECTARES: 'Hectares',
      IMPORT_LINK: 'Importar de uma fonte externa',
      IMPORT_PRODUCTION_PLAN: 'Importar Plano Produtivo',
      IMPORT_SOURCES: 'Fontes disponíveis',
      IMPORT: 'Importar',
      LATITUDE: 'Lat.',
      LONGITUDE: 'Long.',
      MANUAL_HELP: '<b>Clique no mapa</b> para adicionar um campo.',
      MAP_HELP: 'Você pode arrastar o marcador para ajustar as coordenadas da posição.',
      season: 'Estação',
      technology: 'Tecnologia',
      TABS: {
        DIFF_HELP: 'Diferenças entre o que foi declarado e o que foi obtido do arquivo:',
        DIFF: 'Diferenças',
        SOURCE_HELP: 'Obtido do arquivo fornecido:',
        SOURCE: 'Fonte',
        SUBMITTED_HELP: 'Declarado pelo usuário:',
        SUBMITTED: 'Declarado'
      },
      TYPES: {
        LEASED: 'Campo arrendado',
        OWNED: 'Campo próprio'
      }
    },
    STATUS: {
      ERROR: 'Erro',
      EXPIRED: 'Expirada',
      GRANTED: 'Concedido!',
      POTENTIAL_DEFAULT: 'Potencial mora',
      REJECTED: 'Rejeitada',
      STEP_APPROVED: '<b>Approved!</b> Etapa {{index}} de {{total}}.',
      STEP_CURRENT: '<b>Em revisão/pendente.</b> Etapa {{index}} de {{total}}.',
      STEP_EXPIRED: 'Esta solicitação <b>expirou</b>.',
      STEP_REJECTED: 'Esta solicitação foi <b>rejeitada</b>.',
      STEP_TURN: '<i class="material-symbols-rounded blinking">warning</i> <b>Você deve revisar/completar esta etapa.</b> Etapa {{index}} de {{total}}.',
      STEP_WITHDRAWN: 'Esta solicitação foi <b>retirada</b>.',
      WITHDRAWN: 'Retirada',
      REPORT: {
        '1': 'Pendente',
        '2': 'Em processamento...',
        '3': 'Feito',
        '4': 'Erro'
      }
    },
    CREDIT: {
      FORMULA: 'Fórmula',
      FUNCTION: 'Função',
      RESULT: 'Resultado',
      SUBSTITUTE: 'Substitua os valores',
      VALUE: 'Valor',
      VARIABLE: 'Variável'
    }
  },
  DASHBOARDS: {
    DELIVERED: 'Entregue',
    DELIVERED_WITHOUT_INVOICED: 'Entregue sem facturado',
    EXPIRED_VOLUME: 'Entregas atrasadas',
    IN_CONTRACTS: 'em {{quantity}} Contratos.',
    PENDING: 'Pendente',
    PENDING_TO_EXPIRE: 'Prestes a expirar',
    TO_BE_FIXED: 'Fixação pendente',
    VOLUME_TBF: 'Volume a ser fixado',
    VOLUME_TO_EXPIRE: 'Entregas a vencer'
  },
  LABELS: {
    COLOR: 'Cor',
    CREATE: 'Criar novo',
    LABEL_AS: 'Marcar como:',
    MANAGE: 'Gerenciar marcadores',
    NEW: 'Novo marcador',
    SAMPLE: 'Amostra:',
    SELECTED: 'Itens selecionados: <span class="quantity">{{quantity}}</span>'
  },
  STATUS_LABEL: {
    CREATE: 'Criar novo',
    EDIT: 'Editar Status',
    NEW: 'Novo Status',
    UPDATE: 'Atualizar',
    WARNING: 'Todos os elementos que têm o Status definido: <b>{{status}}</b>, será identificado como <b>Sem status</b>.',
    NONE: 'Sem status'
  },
  COMMERCIAL_ZONES: {
    CREATE: 'Criar Zona comercial',
    BIND_TO: 'Link para:',
    EDIT: 'Editar Zona comercial',
    MANAGE: 'Gerenciar zonas comerciais',
    NEW: 'Nova Zona comercial',
    NO_UNIQUE: 'Já existe uma Zona comercial com esse nome.'
  },
  NOT_FOUND: {
    BUTTON: 'Voltar à página principal',
    DESCRIPTION: 'Se você foi redirecionado para esta página pela plataforma, por favor entre em contato com nossa <a href="mailto:contacto@agrology.com">equipe de suporte</a>.',
    SUBTITLE: 'Esta página não existe',
    TITLE: 'Algo deu errado... :('
  },
  PRESETS: {
    CREATE: 'Salvar novo...',
    NEW: 'Novo preset',
    NO_DATA: 'Ainda sem presets.',
    PRIVATE_HELP: 'Um preset privado, <b>não é visível</b> para o resto da Empresa.',
    TITLE: 'Presets',
    FILTERS_LABEL: 'Histórico do filtro',
    SAVE_FILTERS: 'Salvar filtros',
    SAVE_FILTERS_DESC: 'Ao salvar estas configurações de filtro, na próxima vez que você entrar na seção, elas serão aplicadas por padrão.',
    FILTERS_DELETE_TOOLTIP: 'Excluir configuração',
    FILTERS_DELETE_BODY: 'Você tem certeza de que deseja excluir esta configuração de filtro pré-definida?',
    FILTERS_ACTIVE_LABEL: 'Filtros pré-aplicados <span class="material-symbols-rounded">filter_alt</span>'
  },
  INTEGRATIONS: {
    API_DOCUMENTATION_HELP: 'Para acessar nossa documentação de API, entre em contato com nossa <a href="mailto:contacto@agrology.com">equipe de suporte</a>.',
    API_DOCUMENTATION: 'Documentação API',
    TITLE: 'Integrações de API',
    MONITOR: 'Monitor Integrações de API',
    WEBHOOK_MODEL: 'Modelo de payload do webhook',
    WEBHOOKS_HELP: 'Webhooks são chamadas HTTP POST feitas em eventos específicos. Você pode especificar até 5 URLs.',
    WEBHOOKS: 'Webhooks'
  },
  VOLUME: {
    ADDITIONAL_HELP: 'Comissões, frete, etc.',
    ADDITIONAL: 'Adicional',
    AMOUNT: 'Valor a cobrir',
    APPLY: 'Aplicar',
    CALCULATE: 'Calcular',
    CALCULATOR: 'Calculadora de volume',
    DAYS: 'Dias de duração',
    GRANTING_DATE: 'Data de concessão',
    MULTIPLE: 'Volume (múltiplo de {{multiple}})',
    NEEDED_WARRANTY: 'Volume necessário + garantia',
    NEEDED: 'Volume necessário',
    PRICE: 'Preço de referência',
    SUBTOTAL: 'Subtotal',
    TOTAL_AMOUNT: 'Total (valor + adicional)',
    VAT_INCLUDED: 'IVA incluído',
    WARRANTY: 'Garantia'
  },
  LIQUIDACIONES: {
    SUMMARIZE: 'Mostrando <span class="quantity">{{quantity}}</span> Liquidação(ões)',
    TITLE: 'Liquidações',
    TIPO: {
      '1': 'Primária',
      '2': 'Secundária',
      '3': 'Ajustamento'
    }
  },
  DOC_CUSTOM: {
    FOOTER: 'Rodapé',
    HEADER: 'Cabeçalho',
    HELP: 'Você pode incluir uma legenda no cabeçalho e rodapé dos documentos gerados pela plataforma onde sua Empresa está envolvida.',
    INFO: 'Quaisquer alterações só serão refletidas nos documentos gerados a partir de agora.',
    TITLE: 'Personalizar documentos'
  },
  WORKFLOW: {
    GROUPS: {
      account_opening: 'Abertura de conta',
      analysis: 'Análise',
      application_signing: 'Assinatura do aplicativo',
      approval: 'Aprovação',
      axc_contract: 'Contrato AxC',
      bail_signing: 'Assinatura de fiança',
      branch: 'Filial',
      buro_externo: 'Escritório externo',
      buro_interno: 'Escritório interno',
      cession: 'Cessão',
      collaterals: 'Colaterais',
      committee: 'Comitê',
      confirmation: 'Confirmação',
      credit_committee: 'Comitê de crédito',
      credit_contract: 'Contrato de crédito',
      doa: 'Revisão',
      documentation: 'Documentação',
      echeq: 'eCheq',
      endorsement: 'Endosso',
      granting: 'Concessão',
      invoice: 'Fatura',
      liquidation: 'Liquidação',
      portfolio: 'Análise do Cliente',
      promissory_note: 'Nota promissória',
      proof: 'Comprovante',
      signing: 'Assinaturas',
      site_review: 'Visita de inspeção',
      warranty_review: 'Revisão de Garantia'
    },
    FIELD: {
      account_opening_letter: 'Carta de Abertura de Conta',
      agriculturalForm: 'Forma agrícola',
      alyc: 'ALyC',
      amount: 'Linha de crédito solicitada',
      approved_portfolio: 'Clientes aprovados',
      approvers: 'Aprovadores',
      assembly_minute: 'Atas de Assembléia',
      assembly_minutes: 'Últimas Atas de Assembléia',
      assessment_certificate: 'Certificado de liberdade de avaliação',
      authorities_act: 'Ato de nomeação de autoridades',
      axc_contract: 'Contrato AxC',
      bail_signers_ids: 'DNI de los firmantes de la fianza',
      bail_signers: 'Signatários de fiança',
      bailor_address: 'Endereço do fiador',
      bailor: 'Fiador',
      balance_sheet_approval: 'Atas de aprovação do balanço',
      bank_financial_debt: 'Dívida bancária e financeira',
      bank_statements: 'Extratos bancários',
      broker: 'Corretor',
      business_review: 'Revisão de negócios',
      business_supplier_review: 'Reseña del negocio e insumera',
      buyer: 'Comprador',
      buyerRFC: 'RFC do Comprador',
      certified_assets_manifestation: 'Manifestação de Ativos Certificados',
      cession_notice: 'Notificação de atribuição',
      charges_act: 'Ato de distribuição de encargos',
      client_concept: 'Conceito de cliente',
      collateral_amount: 'Valor da garantia',
      commercial_appraisal: 'Avaliação comercial',
      commercialDebt: 'Dívida comercial',
      company_acts: 'Atas da Empresa',
      constitutive_act: 'Ata constitutiva',
      contract: 'Contrato',
      corporate_composition: 'Composição societária',
      credit_amount: 'Valor creditado',
      credit_analysis: 'Análise de crédito',
      credit_application_form: 'Formulário de Solicitação de Crédito',
      credit_bureau_consultation: 'Consulta ao Bureau de Crédito',
      current_account_ars: 'Conta Corrente AR$',
      current_account_usd: 'Conta Corrente USD',
      ddjjBienes: 'Declaração de Bens Pessoais',
      ddjjGanancias: 'Declaração de ganhos',
      ddjjIVA: 'Declaração de IVA',
      debit_note: 'Nota de débito',
      delivery_range: 'Intervalo de entrega',
      due_date: 'Data de vencimento',
      echeq_amount: 'Valor do eCheck',
      echeq: 'eCheq',
      electronic_invoices: 'Fatura(s) eletrônica(s)',
      FILES: 'Arquivo(s)',
      financeableCrop: 'Colheita financiável',
      financial_statements: 'Estados financeiros',
      financialDebt: 'Dívida financeira de longo prazo',
      fiscal_proof: 'Comprovante de situação fiscal',
      fwd_contract: 'Contrato forward',
      granting_date: 'Data de liquidação',
      guaranteed_amount: 'Valor garantido',
      has_alyc_account: 'Tem uma conta em um ALyC',
      has_rosental_account: 'Tem uma conta na Rosental',
      id_certified_copy: 'Cópia autenticada do documento de identidade',
      IDBack: 'Verso do documento',
      IDFront: 'Frente do documento',
      iibb: 'Prova de registro IIBB',
      insurance_policy: 'Apólice de seguro',
      investment_project: 'Projeto/orçamento de investimento',
      invoices: 'Fatura(s) de suprimento/serviço',
      IOU: 'Nota promissória',
      issue_date: 'Data de emissão',
      iva: 'Prova de registro IVA',
      last_three_balances: 'Últimos três balanços',
      lastBalance: 'Último balanço',
      lastTwoBalances: 'Últimos dois balanços',
      locality_and_province: 'Cidade e província',
      observations: 'Observações',
      openingForm: 'Formulário de abertura',
      personalPropertyDeclaration: 'Manifestação de propriedade pessoal',
      policy_invoices: 'Nota fiscal de apólice',
      promissory_note_amount: 'Valor da nota promissória',
      proof_of_address: 'Comprovante de residencia',
      property_lease: 'Título de Propriedade/Contrato de Arrendamento',
      real_estate_insurance: 'Seguro imobiliário',
      reforms_statutes: 'Reformas nos estatutos',
      representative_ids: 'Cópias de identidade de representantes e procuradores',
      representative_ine: 'Identificação oficial (INE) dos representantes legais',
      representative_powers: 'Poderes dos representantes legais',
      requires_cession_notice: 'Solicitar comprovante de notificação de atribuição',
      RPC_enrollment: 'Inscrição RPC',
      sale_deed: 'Escritura',
      sales_detail: 'Detalhe de vendas',
      settle_amount: 'Valor a liquidar',
      settlement_receipt: 'Recibo de liquidação',
      sgr: 'S.G.R.',
      shareholders_assets_declaration: 'Declaração de bens dos sócios',
      shareholders_book: 'Livro de acionistas',
      shareholders_credit_bureau_consultation: 'Consulta ao Bureau de Crédito do Acionista',
      shareholders_ine: 'Identificação oficial (INE) dos accionistas',
      shareholders_list: 'Lista de parceiros',
      shareholders_proof_of_address: 'Comprovante de endereço dos acionistas',
      shareholding_structure: 'Estrutura societária',
      signers: 'Assinantes',
      sme_certificate: 'Certificado PME',
      stamp_tax_exemption: 'Certificado de Isenção de Imposto do Selo',
      statute_modifications: 'Alterações ao estatuto',
      statute: 'Contrato Social da Empresa',
      supplier_quote: 'Cotação(ões) do fornecedor',
      tax_jurisdiction: 'Jurisdição para Impostos',
      term: 'Prazo',
      transfer_amount: 'Valor do crédito concedido',
      transfer_receipt: 'Recibo de transferência bancária',
      visit_report: 'Relatório de Visita de Inspeção',
      warranty_certificate: 'Certificado de garantia',
      warranty: 'Garantia',
      water_use_concession: 'Concessão de uso de água'
    },
    POSSIBILITY: {
      approve: 'Aprovar',
      confirm: 'Confirmar',
      continue: 'Continuar',
      error: 'Erro',
      grant: 'Conceder',
      reject: 'Rejeitar',
      return: 'Voltar para a etapa anterior',
      send: 'Enviar'
    },
    TYPE: {
      0: "WAIT",
      1: "END",
      2: "START",
      3: "TRANSITION",
      4: "FORM",
      5: "REVIEW",
      6: "CHOICE"
    }
  },
  METADATA: {
    IMPORTANT_ONLY: 'Apenas importantes',
    TITLE: 'Dados adicionais'
  },
  DOA: {
    APPROVAL: 'Aprovação',
    LINKED_TO: 'Ligada a',
    TITLE: 'Aprovações',
    WAITING: 'Aguardando decisão...',
    STATUS: {
      APPROVED: 'Aprovada',
      DISAPPROVED: 'Reprovada',
      PENDING: 'Pendente',
      RETIRED: 'Retirada'
    }
  },
  FORM_REUSE: {
    HELP: 'Selecione os campos que deseja reutilizar:',
    REUSE: 'Reutilizar',
    TITLE: 'Reutilizar dados...'
  },
  AUTHORIZATIONS: {
    ADD_MORE: 'Definir novo Representante...',
    CREATE: 'Validação de identidade',
    DUE_DATE: 'Válido até',
    FISCAL_PROOF_HELP: 'Carregue o arquivo emitido pelo SAT. Formato suportado: PDF.',
    FISCAL_PROOF: 'Comprovante de situação fiscal',
    MESSAGE_PLACEHOLDER: 'Autorizo/aceito as seguintes condições...',
    MESSAGE: 'Mensagem',
    PROVIDER: 'Validação de identidade realizada em:',
    REPRESENTATIVES: 'Representantes',
    REQUESTED: 'Solicitada',
    ENTITY_HELP: {
      DEFAULT: 'Para solicitar a autorização, é necessário fazer o upload da <b>COMPROVANTE DE SITUAÇÃO FISCAL</b> da Empresa.',
      FISCAL_ID: 'Para solicitar autorização para o identificador fiscal: <samp>{{reference}}</samp>, é necessário fazer o upload da <b>COMPROVANTE DE SITUAÇÃO FISCAL</b> da Empresa.',
      FORM: 'Para solicitar autorização para o {{label}}: <samp>{{reference}}</samp>, é necessário preencher o seguinte formulário.'
    },
    STATUS: {
      AUTHORIZED: 'Autorizado',
      CANCELED: 'Cancelado',
      PENDING: 'Pendente'
    }
  },
  NOTES: {
    ADD_COLUMN: 'Adicionar coluna',
    ADD_COMPANY_HELP: 'Digite todos os <b>números {{fiscal_id}}</b> que você deseja adicionar.',
    FOR: 'Dados de {{ company }}',
    TYPE: 'Tipo'
  },
  PORTFOLIO: {
    ACCUMULATED_LINE: 'Linha acumulada',
    ADD_HELP: 'Digite o número de {{label}} de todos os clientes para analisar. Uma vez finalizada a análise, você terá a possibilidade de incluir mais.',
    ADD: 'Adicionar clientes',
    FINISH: 'Análise final',
    NO_DATA: 'Nenhum cliente de <b>{{company}}</b> foi informado para análise.',
    PROCESSED: 'Já processado'
  },
  ACCOUNT: {
    ROLES: {
      admin: 'Administrador',
      commercial_with_users: 'Comercial (com usuários)',
      commercial: "Comercial",
      counterpart_admin: 'Administrador de Contraparte',
      operations: 'Operações',
      logistics: 'Logística',
      business_officer: 'Oficial de Negócios',
      superadmin: 'Super Admin',
      superadmin_readonly: 'Super Admin (somente leitura)',
      superadmin_writeonly: 'Super Admin (somente gravação)'
    }
  },
  API_KEY: {
    DESCRIPTION: 'Para utilizar nossos serviços fora da plataforma, é necessário ter uma chave de API.',
    GENERATE: 'Gerar chave de API',
    NO_KEYS: 'Não existem chaves de API disponíveis para sua empresa.',
    SAMPLE: 'Autenticação usando chave API',
    TITLE: 'Chaves de API',
    STATUS: {
      ACTIVE: 'Ativa',
      INACTIVE: 'Inativa',
      DELETED: 'Excluída'
    }
  },
  CLIENT_CREDENTIALS: {
    TITLE: 'Credenciais do cliente',
    GENERATE: 'Gerar credenciais',
    NO_CREDENTIALS: 'Não há credenciais disponíveis para sua empresa',
    CREATED: 'Credenciais de cliente criadas',
    OAUTH: {
      TITLE: 'Autorize a conexão entre <b>{{company}}</b> e sua conta <b>Agrology</b>.',
      LABEL: 'Você concorda em dar a essa pessoa acesso à sua conta?',
      DESCRIPTION: 'Concorda em conceder a <b>{{company}}</b> acesso à sua conta? Permita que Agrology acesse sua conta do Agrology e desfrute de uma experiência integrada e aprimorada. Suas informações estarão seguras e usaremos apenas os dados de que precisamos.',
      REDIRECT_MESSAGE: 'O Authorise o redirecionará para {{url}}',
      AUTHORIZE: 'Autorizar',
      REDIRECT_TO: 'Se você autorizar, será redirecionado para <u>{{url}}</u>',
      REDIRECTING: 'Redirecionamento...'
    }
  },
  ADMIN_CIEC_REQUESTS: {
    HEADER_LINK: 'Gerenciador de solicitações do CIEC',
    TABLE_TITLE: 'Gerenciador de solicitações do CIEC',
    TABLE_DESCRIPTION: "Exibir status de solicitações",
    VALIDITY_COLUMN: 'Status do ICCS',
    RESEND_REQUEST: 'Reenviar solicitação',
    STATUS_COLUMN: 'Status da solicitação',
    MODAL: {
      BODY: 'Se a solicitação estiver pendente por um longo período de tempo, é recomendável reenviar o e-mail.'
    },
    STATUS: {
      UNKNOWN: "Desconhecido",
      PENDING: "Pendente",
      VALID: "Válido",
      INVALID: 'Inválido',
      ERROR: 'Erro',
      DEACTIVATED: 'Desativado',
      COMPLETED: 'Concluído'
    }
  },
  NIP: {
    ADMIN_TITLE: 'Gerenciador de solicitação de NIP',
    PROVIDER: 'Autorização e consentimento fornecidos com assinatura NIP por:',
    REQUEST: 'Solicitar código NIP',
    REQUEST_HELP: 'O <b>Requerente deverá fornecer autorização para consulta do relatório de crédito</b> através da sua assinatura NIP. Este código será enviado a você por e-mail.',
    REQUIRED: 'É necessária a <b>autorização através de NIP</b> do Requerente ou Representante Legal.'
  },
  ADMIN_TENANTS: {
    HEADER_LINK: 'Administrador de Tenants',
    TABLE_DESCRIPTION: 'Administra los multiples tenants de la plataforma',
  },
  EPYME: {
    STATUS: {
      canceled: 'Cancelada',
      completed: 'Preenchido',
      expired: 'Expirada',
      failed: 'Erro',
      issued: 'Publicado',
      pending: 'Pendente',
      rejected: 'Rejeitada'
    }
  }
};
