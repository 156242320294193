import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { instanceToInstance, plainToInstance } from "class-transformer";
import { BehaviorSubject, EMPTY, Observable, Observer, Subject, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';
import { CacheService } from '../../services/cache.service';
import { CurrentDateService } from '../../services/current-date.service';
import { PusherService } from '../../services/pusher.service';
import { TenantService } from '../../services/tenant.service';
import { FlashService } from '../../ui/services/flash.service';
import { Account } from '../models/account.model';
import { Login } from '../models/login.model';
import { Token } from '../models/token.model';
import { NotificationChannel, User } from '../models/user.model';

@Injectable()
export class LoginService {

  private loginUrl = '/:apiBase/login';
  private logoutUrl = '/:apiBase/logout';
  private usersUrl = '/:apiBase/users';
  private userByIdUrl = this.usersUrl + '/:userId';
  private userChannels = this.usersUrl + '/channels';
  private sessionUrl = '/:apiBase/verification-status';
  private accountsUrl = this.userByIdUrl + '/accounts';
  private requestPassword = this.usersUrl + '/passwords-request';
  private passwordVerificationUrl = this.usersUrl + '/password-verification/:token';
  private tokenVerificationUrl = '/:apiBase/validate-invitation-token/:token';
  private emailVerificationUrl = this.userByIdUrl + '/verification/:token';
  private changePasswordUrl = this.userByIdUrl + '/password/:token';
  // TODO: The path of this resource do not follow the conventions
  private updatePasswordUrl = this.userByIdUrl + '/update-password';
  private reverificationEmail = '/:apiBase/users/email/resend-verification';
  private verificationRemainingToRetry = '/:apiBase/users/email/verification-retry-time';
  private userPhoneUrl = this.userByIdUrl + '/phone';
  private verificatePhoneCodeUrl = this.userPhoneUrl + '/verification';
  private googleApiGetUserInfo = ` https://www.googleapis.com/oauth2/v2/userinfo?access_token=:accessToken`;

  private redirectUrl: string;
  private user = new BehaviorSubject<User>(null);
  private token = new BehaviorSubject<Token>(null);
  private logoutSubs: Subscription;
  private _collectionSubjects: { [eventKey: string]: BehaviorSubject<Account[]> } = {};
  private readonly CACHE_MINUTES = 60 * 24 * 365;

  constructor(
    private router: Router,
    private http: HttpClient,
    private pusherService: PusherService,
    private flashService: FlashService,
    private translateService: TranslateService,
    private currentDate: CurrentDateService,
    private cacheService: CacheService,
    private tenantService: TenantService
  ) {
    const login: Login = this.cacheService.get('login');

    if (login) {
      this.user.next(login.user);
      this.token.next(login.token_data);
    }

    this.logoutSubs = this.user.pipe(
      switchMap(user => user ?
        this.pusherService.listen('private_' + user.id, 'logout', 0) :
        EMPTY)
    ).subscribe(event => {
      this.logout(false, true);
    });
  }

  /**
   * Parses the login data, stores it in local storage, and updates the relevant observables.
   * 
   * @param {Login} login - The login data to parse.
   * @returns {Login} - The parsed login data.
   */
  private parseLogin(login: Login): Login {
    // Store login data in cache
    this.cacheService.set('login', {
      user: login.user,
      token_data: login.token_data || this.token.value
    }, this.CACHE_MINUTES);

    // Convert plain login object to class instance
    const loginInstance = plainToInstance(Login, login);

    // Update user and token observables
    if (loginInstance.user) this.user.next(loginInstance.user);
    if (loginInstance.token_data) this.token.next(loginInstance.token_data);

    // Set language preference if available
    if (loginInstance.user && loginInstance.user.language) {
      this.translateService.use(loginInstance.user.language);
    }

    return loginInstance;
  }

  public login(data: any): Observable<Login> {
    const stream = this.http.post<Login>(this.loginUrl, { ...data, tenant_id: this.tenantService.tenant.id });

    return stream.pipe(map(login => {
      return this.parseLogin(login);
    }));
  }

  public logout(from_link: boolean = false, reload: boolean = false): void {
    this.token.pipe(
      take(1),
    ).subscribe(token => {

      if (token) {
        this.http.post<any>(this.logoutUrl, {}).subscribe(() => { });
        this.user.next(null);
        this.token.next(null);
        this._session = undefined;
        this._collectionSubjects = {};
        this.cacheService.clear();
      }

      this.flashService.dismissAll();

      if (reload) {
        window.location.href = '/login';
      } else if (!from_link) {
        this.router.navigate(['/login']);
      }
    });
  }

  public customLogout(backToUrl: string): void {
    this.setRedirectUrl(backToUrl);

    this.token.pipe(
      take(1),
    ).subscribe(token => {
      if (token) {
        this.user.next(null);
        this.token.next(null);
        this._session = undefined;
        this._collectionSubjects = {};
        this.cacheService.clear();
      }

      this.flashService.dismissAll();
      this.router.navigate(['/login']);
    });
  }

  private _session: any;
  public checkMailVerification(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      if (this._session) {
        observer.next(this._session);
        observer.complete();
      } else {
        const stream = this.http.get<any>(this.sessionUrl);
        const actualLogin = this.cacheService.get('login');

        const done = (response) => {
          this._session = response;
          this.parseLogin(response);

          observer.next(this._session);
          observer.complete();
        };

        if (actualLogin.user?.mail_verified == 1) {
          done(actualLogin);

          return;
        }

        stream.subscribe(response => {
          done(response);
        });
      }
    });
  }

  public validateActivationToken(token: string): Observable<any> {
    const url = this.tokenVerificationUrl.replace(":token", token);
    return this.http.get<any>(url);
  }

  public updatePhone(user: User): Observable<Login> {
    const url = this.userPhoneUrl.replace(":userId", user.id.toString());
    return this.http.put<Login>(url, user);
  }

  public verificatePhoneCode(user: User): Observable<any> {
    const url = this.verificatePhoneCodeUrl.replace(":userId", user.id.toString());
    return this.http.put<any>(url, user);
  }

  public register(data: any): Observable<Login> {
    const observable = new Observable<Login>(subscriber => {
      // TODO: post /users should return a User, not a Login, use headers instead
      const stream = this.http.post<Login>(this.usersUrl, { ...data, tenant_id: this.tenantService.tenant.id });

      stream.subscribe({
        next: login => {
          subscriber.next(this.parseLogin(login));
        },
        error: error => {
          subscriber.error(error);
        }
      });
    });

    return observable;
  }

  public getCurrentUser(): BehaviorSubject<User> {
    return this.user;
  }

  public getCurrentToken(): BehaviorSubject<Token> {
    return this.token;
  }

  private getAccounts(): Observable<Account[]> {
    const url = this.accountsUrl.replace(":userId", (this.user.getValue().id).toString());
    const stream = this.http.get<Account[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      // La expresión regular es un workaround para salvar los casos donde el header Date viene con doble fecha
      // Ejemplo: Thu, 21 May 2020 16:20:57 GMT, Thu, 21 May 2020 16:20:57 GMT
      // Funciona tanto para los casos con doble fecha como para los de fecha simple
      this.currentDate.set(new Date(response.headers.get('date').match(/[^,]+,[^,]+/g)[0]));

      return plainToInstance(Account, response.body);
    }));
  }

  public getAccountsForCompany(companyId: Number): Observable<Account[]> {
    const url = this.accountsUrl
      .replace(":userId", (this.user.getValue().id).toString())
      + `?companyId=${companyId}`;

    const stream = this.http.get<Account[]>(url, { observe: 'response' });
    return stream.pipe(map(response => {
      // La expresión regular es un workaround para salvar los casos donde el header Date viene con doble fecha
      // Ejemplo: Thu, 21 May 2020 16:20:57 GMT, Thu, 21 May 2020 16:20:57 GMT
      // Funciona tanto para los casos con doble fecha como para los de fecha simple
      this.currentDate.set(new Date(response.headers.get('date').match(/[^,]+,[^,]+/g)[0]));

      return plainToInstance(Account, response.body);
    }));
  }

  public watchAccounts(): Observable<Account[]> {
    const eventKey = this.user.getValue().id;

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.getAccounts()
      },
      {
        channel: `private_${eventKey}`,
        event: 'account'
      }
    );
  }

  public setRedirectUrl(url: string): void {
    if (!this.redirectUrl) {
      this.redirectUrl = url;
    }
  }

  public clearRedirectUrl(): void {
    this.redirectUrl = undefined;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }

  public checkMailVerified(): void {
    this.getCurrentUser().subscribe(user => {
      if (user && !user.mail_verified) {
        this.flashService.report('verification_pending');
      }
    });
  }

  /**
   * Verifies the user's email using the provided userId and token.
   * 
   * @param {number} userId - The ID of the user.
   * @param {string} token - The verification token.
   * @returns {Observable<Login>} - An observable containing the login information.
   */
  public verify(userId: number, token: string): Observable<Login> {
    const loginSubject = new Subject<Login>();
    const url = this.emailVerificationUrl
      .replace(":userId", userId.toString())
      .replace(":token", token);

    const stream = this.http.get<User>(url);

    stream.subscribe({
      next: user => {
        this.parseLogin({ user: user });

        const actualLogin = this.cacheService.get('login');

        loginSubject.next(actualLogin);
        loginSubject.complete();
      },
      error: error => {
        loginSubject.error(error);
        loginSubject.complete();
      }
    });

    return loginSubject;
  }

  public getPasswordVerification(token: string): Observable<any> {
    const url = this.passwordVerificationUrl.replace(":token", token.toString());
    return this.http.get<any>(url);
  }

  public getRequestPassword(data: { email: string }): Observable<any> {
    const stream = this.http.post<any[]>(this.requestPassword, { ...data, tenant_id: this.tenantService.tenant.id });

    return stream;
  }

  public changePassword(data: {
    id: string;
    token: string;
    password: string;
    password_confirmation: string;
  }): Observable<any> {
    const url = this.changePasswordUrl.replace(":userId", data.id.toString()).replace(":token", data.token.toString());

    return this.http.put<any[]>(url, data);
  }

  // TODO: Data model do not follow the conventions
  public updatePassword(data: {
    username?: string;
    password?: string;
    newPassword?: string;
    id?: number;
  }): Observable<any> {
    const url = this.updatePasswordUrl.replace(":userId", data.id.toString());

    return this.http.put<any[]>(url, data);
  }

  public resendVerification(email: string): Observable<JSend<any>> {
    return this.http.post<JSend<any>>(this.reverificationEmail, {
      email,
      tenant_id: this.tenantService.tenant.id
    });
  }

  public getVerificationRemainingTimeToTry(email: string): Observable<JSend<any>> {
    return this.http.post<JSend<any>>(this.verificationRemainingToRetry, {
      email
      // tenant_id: this.tenantService.tenant.id
    });
  }

  public updateUser(user: User): Observable<User> {
    const tempUser = instanceToInstance(user);
    delete tempUser.accounts; // Remove unnecessary information

    const url = this.userByIdUrl.replace(":userId", user.id.toString());

    return this.http.put<User>(url, tempUser).pipe(map(response => plainToInstance(User, response)));
  }

  public changeLocalStorageUserLanguage(language: string): void {
    const actualLogin: Login = this.cacheService.get('login');


    if (actualLogin?.user && language) {
      actualLogin.user.language = language;

      this.cacheService.set('login', actualLogin, this.CACHE_MINUTES);
    }
  }

  public getChannels(): Observable<NotificationChannel[]> {
    return this.http.get<NotificationChannel[]>(this.userChannels).pipe(map(response => plainToInstance(NotificationChannel, response)));
  }

  public updateChannels(channels: NotificationChannel[]): Observable<NotificationChannel[]> {
    return this.http.put<NotificationChannel[]>(this.userChannels, channels).pipe(map(response => plainToInstance(NotificationChannel, response)));
  }

  public getGoogleAccountUserInfo(googleAccessToken: string): Observable<any> {
    const url = this.googleApiGetUserInfo.replace(":accessToken", googleAccessToken.toString());
    return this.http.get<any>(url);
  }

  public authenticateWithRedirectedToken(data: { token: string, user: User }): void {
    this.user.next(data.user);

    this.token.next({
      access_token: data.token,
      expires: null,
      refresh_token: null,
      token_type: null
    });

    this.cacheService.set('login', {
      user: this.user.value,
      token_data: this.token.value
    }, this.CACHE_MINUTES);
  }
}
