<ng-container *ngIf="company">
  <div>
    <b [class.truncate]="truncate"
    *ngIf="company.name; else none"
    [class.highlighted-text]="company.id === my_company?.id"
    [title]="company.name + (user?.admin ? ' [' + company.id + ']' : '')">{{ company.name }}</b>
    <certificates-button
    *ngIf="company.id && company.name && certificates"
    [company]="company"></certificates-button>

    <ng-container *ngIf="my_company && my_company.id != company.id">
      <ng-container *ngIf="commercial_zones_enabled">
        <ag-commercial-zones-modal #commercialZonesModal
        [company]="my_company"></ag-commercial-zones-modal>
        
        &nbsp;<a clickStop tooltip="{{ 'COMMERCIAL_ZONES.MANAGE'|translate }}"
        (click)="commercialZonesModal.open(company)"
        class="small hidden-print">
          <!-- <i class="glyphicon glyphicon-briefcase"></i> -->
          <span class="material-symbols-rounded">business_center</span>
        </a>
      </ng-container>
      <ng-container *ngIf="file_manager">
        <ag-file-manager-modal #fileManagerModal
        [company]="my_company"></ag-file-manager-modal>
        
        &nbsp;<a clickStop tooltip="{{ 'GLOBAL.SHARED_FILES'|translate }}"
        (click)="fileManagerModal.open(company)"
        class="small hidden-print">
          <span class="material-symbols-rounded">folder_open</span>
        </a>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="user?.admin">
      &nbsp;<a clickStop tooltip="{{ 'GLOBAL.COMPANY_ADMIN'|translate }}"
      [routerLink]="['/admin', 'companies']" [queryParams]="{ 'filters[id]': 'is:' + company.id }"
      target="_blank"
      class="small hidden-print">
        <span class="material-symbols-rounded">open_in_new</span>
      </a>
    </ng-container>
  </div>
  <div class="text-muted small" *ngIf="!compact">
    <div *ngIf="activity && company.activity?.name">{{ company.activity.label || company.activity.name }}</div>
    <fiscal-id *ngIf="validFiscalId && company.fiscal_id?.value; else noFiscalId"
    [value]="company.fiscal_id"
    [label]="true"></fiscal-id>
    <ng-template #noFiscalId *ngIf="company.address">
      {{ company.address.state }},
      <b>{{ company.address.country.name }} </b>
      <flag [country]="company.address.country"></flag>
    </ng-template>
  </div>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>