import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string): string {
    const regex = /(<br\s*\/?>|<\/p>)/gi;
    const output = value.replace(regex, '$1&nbsp;');

    let doc = new DOMParser().parseFromString(output, 'text/html');
    return doc.body.textContent || "";
  }
}
